import { CSSProperties, useState } from "react";
import { Box, Tooltip, useClipboard } from "@chakra-ui/react";
import { colors } from "src/theme";

type CopyProps = {
    iconStyle?: any;
    value: string;
    copyMessage?: string;
    label?: string;
    labelStyle?: CSSProperties;
    style?: CSSProperties;
};

export const Copy = ({
    value,
    label,
    labelStyle,
    copyMessage,
    style,
}: CopyProps) => {
    const [isOpen, setOpen] = useState(false);
    const clipboard = useClipboard(value);

    const copyCode = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(true);
        clipboard.onCopy();
        setTimeout(() => setOpen(false), 2500);
    };

    return (
        <Tooltip
            zIndex={1401}
            //@ts-ignore
            trigger="click"
            isOpen={isOpen}
            bg={colors.black}
            placement="bottom"
            borderRadius="0.25rem"
            label={copyMessage || "Copied!"}
            padding={{ base: "0.5rem 1rem" }}
        >
            <Box
                data-clipboard-text={value}
                onClick={copyCode}
                className="copy-value"
                _hover={{
                    backgroundColor: colors.stone150,
                }}
                style={{
                    position: "relative",
                    color: colors.gray20,
                    cursor: "pointer",
                    padding: "5px 10px",
                    display: "inline-block",
                    borderRadius: 5,
                    fontSize: 14,
                    fontWeight: "normal",
                    fontFamily: "sans-serif",
                    ...labelStyle,
                }}
            >
                {isOpen ? (
                    <span style={{ marginRight: 5 }}>Copied </span>
                ) : label ? (
                    <span style={{ marginRight: 5 }}>{label} </span>
                ) : (
                    ""
                )}
                <i className="fad fa-copy" />
            </Box>
        </Tooltip>
    );
};
