import { isNil } from "lodash/fp";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "src/utils/helpers";

export const NavigationLink = ({
  to,
  count,
  label,
  icon,
}: {
  to: string;
  count?: number;
  label: string;
  icon: any;
}) => {
  const location = useLocation();
  const isActive = to === location.pathname;

  return (
    <Link
      to={to}
      className={classNames(
        isActive
          ? "bg-stone-150 text-stone-900"
          : "text-stone-600 hover:bg-stone-150 hover:text-stone-900",
        "group flex items-center rounded-md px-1 py-2 text-sm font-medium mb-2"
      )}
    >
      <div
        className={classNames(
          isActive
            ? "text-primary"
            : "text-stone-800 group-hover:text-stone-900",
          "mr-3 flex-shrink-0 h-5 w-5 text-center"
        )}
        aria-hidden="true"
      >
        {icon}
      </div>

      <div className="flex-1 text-stone-800 text-sm">{label}</div>
      {!isNil(count) ? (
        <span
          className={classNames(
            "bg-stone-100 group-hover:bg-stone-200",
            "ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full"
          )}
        >
          {count}
        </span>
      ) : null}
    </Link>
  );
};
