import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { HStack, Text } from "@chakra-ui/react";
import { User, UserCredential } from "firebase/auth";
import React, { useCallback } from "react";
import { api } from "src/api";
import { BaseUserFields } from "src/api/fragments";
import {
    Mutation,
    MutationCreateUserArgs,
    Query,
} from "src/api/generated/types";
import { Button } from "src/components";
import {
    DefaultErrors,
    failure,
    FailureOrSuccess,
    Maybe,
    success,
    UnexpectedError,
} from "src/core";
import { useMe } from "src/hooks";
import { colors } from "src/theme";
import { ThirdPartyAuthentication } from "src/utils/thirdPartyAuthentication";

type Props = {
    label: "Sign in" | "Sign up";
    onSuccess: (
        user: BaseUserFields,
        firebaseUser: UserCredential,
        isNew: boolean
    ) => void;
    onError: ({ error, message }: any) => void;
};
export function GithubButton({ label, onSuccess, onError }: Props) {
    const [fetchMe] = useLazyQuery<Pick<Query, "me">>(api.users.me, {
        fetchPolicy: "no-cache",
    });
    const [createUser] = useMutation<{
        createUser: { user: BaseUserFields; token: string };
    }>(api.users.create);

    const _create = useCallback(
        async (
            firebaseUser: UserCredential
        ): Promise<FailureOrSuccess<DefaultErrors, BaseUserFields>> => {
            try {
                if (!firebaseUser.user.email) {
                    return failure(
                        new UnexpectedError(
                            "Sorry, this account doesn't have an email. You must sign in with a Google account with an email."
                        )
                    );
                }

                const params: MutationCreateUserArgs = {
                    email: firebaseUser.user.email,
                    name: firebaseUser.user.displayName || "",
                    phoneNumber: firebaseUser.user.phoneNumber,
                    password: null,
                };

                const userResponse = await createUser({
                    variables: params,
                });

                if (!userResponse.data?.createUser) {
                    return failure(new Error("No user returned."));
                }

                // at this point the user has to already be signed in with firebase (auth set in the create header)
                const user = userResponse.data.createUser;

                return success(user.user);
            } catch (err) {
                return failure(new UnexpectedError(err));
            }
        },
        [createUser]
    );

    const _fetchMe = async (): Promise<
        FailureOrSuccess<DefaultErrors, BaseUserFields>
    > => {
        try {
            const response = await fetchMe();
            return success(response.data?.me as BaseUserFields);
        } catch (err) {
            return failure(new UnexpectedError(err));
        }
    };

    const _onSuccess = async (firebaseUser: UserCredential) => {
        const refetchMeResponse = await _fetchMe();

        // if there is a user, return success
        if (refetchMeResponse.isSuccess() && refetchMeResponse.value) {
            return onSuccess(refetchMeResponse.value, firebaseUser, false);
        }

        // otherwise we need to create before we call on success
        const response = await _create(firebaseUser);

        if (response.isFailure()) {
            return onError({
                error: response.error,
                message: response.error.message,
            });
        }

        return onSuccess(response.value, firebaseUser, false);
    };

    return (
        <Button
            type="button"
            onClick={async () => {
                ThirdPartyAuthentication.github()
                    .then(_onSuccess)
                    .catch(onError);
            }}
            style={{
                width: "100%",
                position: "relative",
                backgroundColor: "#1B1F23",
                border: `1px solid ${colors.gray20}`,
                height: "auto",
            }}
            padding="1rem 2rem"
        >
            <React.Fragment>
                <img
                    alt="GitHub Logo"
                    style={{
                        position: "absolute",
                        left: 22,
                        top: 10,
                        height: 30,
                        width: "auto",
                    }}
                    src={require("src/assets/logos/github.png")}
                />
                <Text fontSize={14} color={colors.white}>
                    {label} with GitHub
                </Text>
            </React.Fragment>
        </Button>
    );
}
