import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { colors } from "src/theme";
import { Box, HStack, Spacer, Text } from "@chakra-ui/react";

import LogoSVG from "src/assets/logos/accrue/green.svg";
import TwitterIcon from "src/assets/logos/socials/twitter.png";
import DiscordIcon from "src/assets/logos/socials/discord.png";
import { isMobile } from "react-device-detect";
import { config } from "src/config";

export function Footer() {
    return (
        <HeaderContainer>
            <InnerContainer>
                <Logo />

                <Spacer />

                <RightContainer>
                    <Twitter />
                    <Discord />
                </RightContainer>
            </InnerContainer>
        </HeaderContainer>
    );
}

const Logo = () => (
    <Link to="/">
        <img
            alt="Accrue Logo"
            style={{
                width: 40,
                height: 40,
                objectFit: "contain",
            }}
            src={LogoSVG}
        />
    </Link>
);

const Twitter = () => (
    <Box
        display={{
            md: "flex",
        }}
    >
        <a
            href={config.twitterLink}
            rel="noreferrer"
            target={isMobile ? undefined : "_blank"}
        >
            <img
                src={TwitterIcon}
                alt="Twitter"
                style={{
                    height: 22,
                    margin: "5px 15px",
                    width: "auto",
                    objectFit: "contain",
                }}
            />
        </a>
    </Box>
);

const Discord = () => (
    <Box
        display={{
            md: "flex",
        }}
    >
        <a
            href={config.discordLink}
            rel="noreferrer"
            target={isMobile ? undefined : "_blank"}
        >
            <img
                src={DiscordIcon}
                alt="Discord"
                style={{
                    height: 22,
                    margin: "5px 15px",
                    width: "auto",
                    objectFit: "contain",
                }}
            />
        </a>
    </Box>
);

const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HeaderContainer = styled.nav`
    width: 100%;
    z-index: 100;
    padding: 15px;
    background-color: ${colors.black};
    position: sticky;
    top: 0;
    background-position-y: 0;
    transition: all 0.2s ease-in-out 0s;
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: ${colors.black};
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
    }
`;

const InnerContainer = styled.div`
    display: flex;
    max-width: 1100px;
    margin: auto;
    padding: 0 2rem;
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
        padding: 0 1rem;
    }
`;
