import { gql } from "@apollo/client";
import { Maybe } from "src/core";
import { Artifact, Database, Notebook, TeamMember, User } from "./generated/types";

export type BaseUserFields = Pick<
    User,
    | "__typename"
    | "id"
    | "email"
    | "name"
    | "createdAt"
    | "updatedAt"
    | "activeTeamId"
    | "activeDatabaseId"
>;

export const BaseUserFields = gql`
    fragment BaseUserFields on User {
        id
        email
        name
        createdAt
        updatedAt
        activeTeamId
        activeDatabaseId
    }
`;

export type BaseDatabaseFields = Pick<
    Database,
    | "__typename"
    | "id"
    | "createdAt"
    | "name"
    | "provider"
    | "isSSL"
    | "updatedAt"
>;

export const BaseDatabaseFields = gql`
    fragment BaseDatabaseFields on Database {
        id
        createdAt
        name
        provider
        isSSL
        updatedAt
    }
`;

export type BaseArtifactFields = Pick<
    Artifact,
    | "__typename"
    | "id"
    | "schema"
    | "createdAt"
    | "name"
    | "type"
    | "databaseId"
    | "emoji"
    | "primaryKey"
    | "normalizedData"
    | "rawData"
>;

export const BaseArtifactFields = gql`
    fragment BaseArtifactFields on Artifact {
        id
        schema
        createdAt
        name
        type
        databaseId
        emoji
        primaryKey
        normalizedData
        rawData
    }
`;

export type BaseNotebookFields = Pick<
    Notebook,
    | "__typename"
    | "id"
    | "title"
    | "notes"
    | "query"
    | "question"
    | "description"
    | "createdAt"
    | "databaseId"
    | "updatedAt"
>;

export const BaseNotebookFields = gql`
    fragment BaseNotebookFields on Notebook {
        id
        title
        notes
        query
        question
        description
        createdAt
        databaseId
        updatedAt
    }
`;


export type BaseTeamMemberFields = Pick<
    TeamMember,
    | "__typename"
    | "id"
    | "name"
    | "createdAt"
>;

export const BaseTeamMemberFields = gql`
    fragment BaseTeamMemberFields on TeamMember {
        id
        name
        createdAt
    }
`;