import get from "lodash/get";
import React, { Component, CSSProperties } from "react";
import { colors } from "src/theme";
import Helpers from "src/utils/helpers";

type Props = {
    style?: CSSProperties;
    user: any;
};

const COLORS = [
    colors.lightBlue70,
    colors.purple70,
    colors.green70,
    colors.blue70,
    colors.orange70,
    colors.pink70,
    colors.yellow70,
    colors.red70,
];

const UserProfileIcon = ({
    style,
    text,
    user,
}: {
    style?: CSSProperties;
    text?: string;
    user: any;
}) => {
    const _renderInitials = () => {
        if (text) {
            return text;
        }

        const nameParts = (get(user, "name", "") || "").split(" ");
        let initials = "";
        for (const p of nameParts) {
            initials += p.charAt(0).toUpperCase();
        }
        return initials;
    };

    const hash = Math.abs(Helpers.hashFromString(user?._id));
    const color = COLORS[hash % COLORS.length];
    const profileUrl = get(user, "profileUrl", null) || null;

    return (
        <div
            className="compyle__cover"
            style={{
                background: profileUrl
                    ? `url("${profileUrl}") center`
                    : undefined,
                textAlign: "center",
                color: "#fff",
                backgroundColor: color,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 900,
                width: 25,
                height: 25,
                cursor: "pointer",
                objectFit: "cover",
                borderRadius: 100,
                fontSize: 10,
                border: "1px solid #efefef",
                ...style,
            }}
        >
            {/* Only render initials if no profile url */}
            {profileUrl ? null : _renderInitials()}
        </div>
    );
};

export default UserProfileIcon;
