import {
    Button,
    Container,
    HStack,
    Switch,
    Text,
    Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { BaseDatabaseFields } from "src/api/fragments";
import { MutationUpdateDatabaseArgs, Query } from "src/api/generated/types";
import { Info, Input } from "../styled";

type Props = InjectedProps & {
    database?: BaseDatabaseFields;
};

function _DatabaseModal({ handleHide, show: isVisible, database }: Props) {
    const [getConnectionUri] = useLazyQuery<Pick<Query, "getConnectionUri">>(
        api.databases.getConnectionUri
    );
    const [connectionUri, setConnectionUri] = useState("");
    const [name, setName] = useState(database?.name);
    const [isSSL, setSSL] = useState(database?.isSSL ?? false);

    const [updateDatabase, { loading }] = useMutation(api.databases.update);
    const toast = useMyToast();

    const _onSubmit = async function () {
        if (!database) return;

        try {
            const params: MutationUpdateDatabaseArgs = {
                databaseId: database.id,
                name,
                connectionUri,
            };

            await updateDatabase({
                variables: params,
            });

            toast.show({
                message: "Database updated successfully.",
                status: "success",
            });
        } catch (err) {
            toast.show({
                message: (err as Error).message || "An error occurred.",
                status: "error",
            });
        }

        handleHide();
    };

    const _getConnectionUri = async () => {
        if (!database) return;

        const data = await getConnectionUri({
            variables: {
                databaseId: database.id,
            },
        });

        const uri = data.data?.getConnectionUri;

        if (uri) setConnectionUri(uri);
    };

    useEffect(() => {
        if (database) {
            setName(database.name);
            setSSL(database.isSSL);
        }
    }, [database]);

    useEffect(() => {
        void _getConnectionUri();
    }, [database]);

    return (
        <Modal
            title="Database"
            isVisible={isVisible}
            handleHide={handleHide}
            Footer={
                <Button
                    width="100%"
                    variant="primary"
                    isLoading={loading}
                    onClick={_onSubmit}
                >
                    Save Updates
                </Button>
            }
        >
            <Container padding="0px" marginTop="0px !important">
                <Input
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    // label="Client Full Name"
                    placeholder="Type here..."
                />
                <Input
                    label="Connection"
                    value={connectionUri}
                    onChange={(e) => setConnectionUri(e.target.value)}
                    // label="Client Full Name"
                    placeholder="Type here..."
                />
                <HStack>
                    <Switch
                        onChange={(e) => setSSL(e.target.checked)}
                        isChecked={isSSL}
                    />
                    <Text fontSize="sm">SSL connection</Text>
                </HStack>
            </Container>
        </Modal>
    );
}

export const DatabaseModal = connectModal({
    name: "DatabaseModal",
})(_DatabaseModal);
