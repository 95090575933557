import { Button, HStack, Link, Spinner, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CSSProperties, useCallback, useState } from "react";
import { colors } from "src/theme";
import { Info } from "../Info";

export type CommandProps = {
    label: string;
    iconName?: string;
    hasDivider?: boolean;
    iconColor?: string;
    infoMessage?: string;
    color?: string;
    iconImageSrc?: string;
    iconImageStyle?: CSSProperties;
    component?: JSX.Element;
    link?: string;
    onClick?: (x?: any) => void | Promise<void>;
};

export const Command = ({
    label,
    iconName,
    iconColor,
    color,
    hasDivider,
    iconImageSrc,
    iconImageStyle,
    onClick,
    link,
    infoMessage,
    component,
}: CommandProps) => {
    const [isLoading, setLoading] = useState(false);

    const _onClick = useCallback(async () => {
        try {
            setLoading(true);
            if (onClick) await onClick();
        } finally {
            setLoading(false);
        }
    }, [onClick]);

    if (component) {
        return component;
    }

    const Component = (
        <CommandContainer
            onClick={(e) => {
                e.stopPropagation();
                _onClick();
            }}
            height="auto"
            disabled={isLoading}
            textAlign="left"
            marginTop={hasDivider ? undefined : "0 !important"}
            margin={hasDivider ? "0.25rem 0 0 0 !important" : undefined}
            borderTop={hasDivider ? "1px solid #efefef" : undefined}
        >
            <HStack padding="0.5 0.5rem" width="100%" alignItems="center">
                <div style={{ textAlign: "center" }}>
                    {iconImageSrc ? (
                        <img
                            style={{
                                width: 18,
                                height: "auto",
                                color: iconColor || colors.gray20,
                                ...(iconImageStyle || {}),
                            }}
                            src={iconImageSrc}
                        />
                    ) : (
                        <i
                            style={{
                                width: 18,
                                color: iconColor || colors.gray20,
                                fontSize: 12,
                            }}
                            className={iconName}
                        />
                    )}
                </div>
                <Text
                    margin="0 0.75rem !important"
                    textAlign="left"
                    flex={1}
                    fontSize="xs"
                    fontWeight="normal"
                    color={color || colors.gray20}
                >
                    {label}{" "}
                    {infoMessage && (
                        <Info
                            style={{ color, fontSize: 14 }}
                            message={infoMessage}
                        />
                    )}
                </Text>
                {isLoading && <Spinner size="sm" color={color} />}
            </HStack>
        </CommandContainer>
    );

    return link ? (
        <Link target="_blank" href={link} w="100%" marginTop="0 !important">
            {Component}
        </Link>
    ) : (
        Component
    );
};

const CommandContainer = styled(Button)`
    margin: 0;
    padding: 0.35rem 0.5rem;
    width: 100%;
    border-radius: 0 !important;
    background-color: inherit;
    &:hover {
        background-color: ${colors.gray2};
    }
`;
