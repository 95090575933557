import {
    Button,
    Checkbox,
    Container,
    HStack,
    Text,
    useToast,
} from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import JSONInput from "react-json-editor-ajrm";
// @ts-ignore
import locale from "react-json-editor-ajrm/locale/en";
import { colors } from "src/theme";

type Props = InjectedProps & {
    json: any;
};

function _JSONModal({ handleHide, json, show: isVisible }: Props) {
    return (
        <Modal
            // title="Maximize"
            isVisible={isVisible}
            handleHide={handleHide}
            modalProps={{ padding: 0 }}
            hideClose
            modalContents={{
                style: {
                    border: "none",
                    borderRadius: 15,
                    overflow: "hidden",
                    maxHeight: 800,
                    minWidth: 600,
                    overflowY: "scroll",
                },
            }}
        >
            <Button
                onClick={handleHide}
                style={{
                    backgroundColor: colors.white,
                    position: "absolute",
                    padding: "0.25rem",
                    right: 15,
                    top: 15,
                    zIndex: 100,
                }}
            >
                <i className="fa-solid fa-minimize" />
            </Button>

            <div className="json-body-modal">
                <JSONInput
                    style={{
                        body: {
                            fontSize: 14,
                            width: "100%",
                            overflowY: "scroll",
                        },
                    }}
                    height="100%"
                    viewOnly
                    locale={locale}
                    placeholder={json}
                    id="json-editor"
                />
            </div>
        </Modal>
    );
}

export const JSONModal = connectModal({
    name: "JSONModal",
})(_JSONModal);
