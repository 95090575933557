export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: Date;
};

export type Artifact = {
  __typename?: 'Artifact';
  createdAt: Scalars['Date'];
  databaseId: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  normalizedData?: Maybe<Scalars['String']>;
  primaryKey?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  type: ArtifactTypeEnum;
  updatedAt: Scalars['Date'];
};

export enum ArtifactTypeEnum {
  Enum = 'Enum',
  Index = 'Index',
  Table = 'Table'
}

export type AuditLog = {
  __typename?: 'AuditLog';
  audit?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Database = {
  __typename?: 'Database';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isSSL: Scalars['Boolean'];
  name: Scalars['String'];
  provider: DatabaseProviderEnum;
  teamId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum DatabaseProviderEnum {
  Postgres = 'Postgres'
}

export type DatabaseRow = {
  __typename?: 'DatabaseRow';
  index: Scalars['Int'];
  values: Array<DatabaseRowValue>;
};

export type DatabaseRowDelete = {
  primaryKeyField: Scalars['String'];
  primaryKeyValue: Scalars['String'];
  table: Scalars['String'];
};

export type DatabaseRowInsert = {
  data: Scalars['String'];
  table: Scalars['String'];
};

export type DatabaseRowUpdate = {
  edits: Array<DatabaseRowUpdateField>;
  primaryKeyField: Scalars['String'];
  primaryKeyValue: Scalars['String'];
  table: Scalars['String'];
};

export type DatabaseRowUpdateField = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export type DatabaseRowValue = {
  __typename?: 'DatabaseRowValue';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type DeleteDatabaseRowsResponse = {
  __typename?: 'DeleteDatabaseRowsResponse';
  audit: Scalars['String'];
  message: Scalars['String'];
};

export type FixQueryResponse = {
  __typename?: 'FixQueryResponse';
  fixedQuery?: Maybe<Scalars['String']>;
};

export type GetAnalysisResponse = {
  __typename?: 'GetAnalysisResponse';
  analysis?: Maybe<Scalars['String']>;
};

export type GetTipsResponse = {
  __typename?: 'GetTipsResponse';
  tips?: Maybe<Scalars['String']>;
};

export type GetTotalCountResponse = {
  __typename?: 'GetTotalCountResponse';
  totalCount: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type InsertDatabaseRowsResponse = {
  __typename?: 'InsertDatabaseRowsResponse';
  audit: Scalars['String'];
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createDatabase: Database;
  createNotebook: Notebook;
  createTeam: Team;
  createUser: UserResponse;
  deleteDatabaseRows: DeleteDatabaseRowsResponse;
  insertDatabaseRows: InsertDatabaseRowsResponse;
  setActiveDatabase: Database;
  syncDatabase: Database;
  updateDatabase: Database;
  updateDatabaseRows: UpdateDatabaseRowsResponse;
  updateNotebook: Notebook;
};


export type MutationCreateDatabaseArgs = {
  connectionUri: Scalars['String'];
  isSSL?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  provider: DatabaseProviderEnum;
};


export type MutationCreateNotebookArgs = {
  databaseId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTeamArgs = {
  name: Scalars['String'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteDatabaseRowsArgs = {
  databaseId: Scalars['ID'];
  deletions: Array<DatabaseRowDelete>;
};


export type MutationInsertDatabaseRowsArgs = {
  databaseId: Scalars['ID'];
  insertions: Array<DatabaseRowInsert>;
};


export type MutationSetActiveDatabaseArgs = {
  databaseId: Scalars['ID'];
};


export type MutationSyncDatabaseArgs = {
  databaseId: Scalars['ID'];
};


export type MutationUpdateDatabaseArgs = {
  connectionUri?: InputMaybe<Scalars['String']>;
  databaseId: Scalars['ID'];
  isSSL?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDatabaseRowsArgs = {
  databaseId: Scalars['ID'];
  updates: Array<DatabaseRowUpdate>;
};


export type MutationUpdateNotebookArgs = {
  annotations?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notebookId: Scalars['ID'];
  query?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Notebook = {
  __typename?: 'Notebook';
  createdAt: Scalars['Date'];
  databaseId: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type PendingUser = {
  __typename?: 'PendingUser';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type Query = {
  __typename?: 'Query';
  fixQuery: FixQueryResponse;
  getActiveTeam?: Maybe<Team>;
  getActiveTeamMembers: Array<TeamMember>;
  getAnalysis: GetAnalysisResponse;
  getArtifact: Artifact;
  getConnectionUri: Scalars['String'];
  getDatabase: Database;
  getDatabaseArtifacts: Array<Artifact>;
  getDatabaseAuditLogs: Array<AuditLog>;
  getDatabaseNotebooks: Array<Notebook>;
  getNotebook: Notebook;
  getTeamDatabases: Array<Database>;
  getTips: GetTipsResponse;
  getTotalCount: GetTotalCountResponse;
  me?: Maybe<User>;
  queryDatabaseRows: QueryDatabaseResponse;
};


export type QueryFixQueryArgs = {
  databaseId: Scalars['ID'];
  problem: Scalars['String'];
  query: Scalars['String'];
};


export type QueryGetAnalysisArgs = {
  databaseId: Scalars['ID'];
  query: Scalars['String'];
};


export type QueryGetArtifactArgs = {
  artifactId: Scalars['ID'];
};


export type QueryGetConnectionUriArgs = {
  databaseId: Scalars['ID'];
};


export type QueryGetDatabaseArgs = {
  databaseId: Scalars['ID'];
};


export type QueryGetDatabaseArtifactsArgs = {
  databaseId: Scalars['ID'];
};


export type QueryGetDatabaseAuditLogsArgs = {
  databaseId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDatabaseNotebooksArgs = {
  databaseId: Scalars['ID'];
};


export type QueryGetNotebookArgs = {
  notebookId: Scalars['ID'];
};


export type QueryGetTipsArgs = {
  databaseId: Scalars['ID'];
  query: Scalars['String'];
};


export type QueryGetTotalCountArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  databaseId: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QueryQueryDatabaseRowsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  databaseId: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type QueryDatabaseResponse = {
  __typename?: 'QueryDatabaseResponse';
  count: Scalars['Float'];
  fixedQuery?: Maybe<Scalars['String']>;
  page: Scalars['Float'];
  rows: Array<DatabaseRow>;
};

export type Team = {
  __typename?: 'Team';
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type TeamMember = {
  __typename?: 'TeamMember';
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateDatabaseRowsResponse = {
  __typename?: 'UpdateDatabaseRowsResponse';
  audit: Scalars['String'];
  message: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  activeDatabaseId?: Maybe<Scalars['String']>;
  activeTeamId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  token: Scalars['String'];
  user: User;
};

export enum UserTypeEnum {
  Pending = 'Pending',
  Verified = 'Verified'
}
