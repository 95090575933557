import React, { createContext, useCallback, useEffect, useMemo } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
    Box,
    Center,
    Divider,
    Flex,
    HStack,
    Text,
    Textarea,
    VStack,
    calc,
} from "@chakra-ui/react";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useMe, useMyToast } from "src/hooks";
import { useLazyQuery } from "@apollo/client";
import { api } from "src/api";
import { BaseArtifactFields, BaseDatabaseFields } from "src/api/fragments";
import { useDatabases } from "src/hooks/useDatabases";
import { ActionSheet, Button } from "src/components";
import { DatabaseInput } from "../../components/database/DatabaseInput";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveQuery,
    setQuery,
    setQuestion,
    setRows,
    setRowsAndOtherInfo,
} from "src/redux/reducers/activeQuery";
import { colors } from "src/theme";
import { Profile } from "../Profile";
import UserProfileIcon from "src/components/UserProfileIcon";
import { Query } from "src/api/generated/types";
import { JSONModal } from "src/components/modals/JSONModal";
import moment from "moment";
import { useDatabase } from "src/components/database/useDatabase";
import { DatabaseTable } from "src/components/database/Table";
import { Tabs } from "src/components/Tabs";

export function Notebook() {
    const { me } = useMe();
    const { notebookId, databaseId } = useParams();
    const dispatch = useDispatch();
    const { queryDatabaseRows } = useDatabase();
    const { currentPage, pageSize } = useSelector(getActiveQuery);

    const [getNotebook] = useLazyQuery<Pick<Query, "getNotebook">>(
        api.notebooks.retrieve
    );

    const { updateNotebookDebounced } = useDatabase();

    const _syncInputWithNotebook = async () => {
        const data = await getNotebook({
            variables: {
                notebookId,
            },
        });

        const notebook = data?.data?.getNotebook;

        if (!notebook) return;

        dispatch(setQuestion(notebook.question || ""));
        dispatch(setQuery(notebook.query || ""));

        if (notebook.query) {
            await queryDatabaseRows(null, notebook.query, false, null);
        } else {
            dispatch(
                setRowsAndOtherInfo({
                    rows: [],
                    headers: [],
                    lastQueriedAt: new Date().toISOString(),
                })
            );
        }
    };

    const activeQuery = useSelector(getActiveQuery);
    const question = activeQuery.question;
    const query = activeQuery.query;

    useEffect(() => {
        // update the notebook debounced if there is one
        if (!notebookId) return;
        if (!databaseId) return;

        void updateNotebookDebounced({
            notebookId,
            question,
        });
    }, [question]);

    useEffect(() => {
        // update the notebook debounced if there is one
        if (!notebookId) return;
        if (!databaseId) return;

        void updateNotebookDebounced({
            notebookId,
            query,
        });
    }, [query]);

    useEffect(() => {
        void _syncInputWithNotebook();
    }, [notebookId]);

    return (
        <div>
            <JSONModal />

            <Tabs />

            <VStack padding="1rem 1rem" paddingBottom="1rem">
                <Box
                    display="flex"
                    flexDir="column"
                    width="100%"
                    margin="auto"
                    borderRadius="0.5rem"
                    bg={colors.white}
                    alignItems="flex-start"
                    className="border border-stone-200"
                >
                    <DatabaseInput />
                    <DatabaseTable />
                </Box>
            </VStack>

            {/* <Box
                margin="0 2rem"
                padding="0.5rem 0"
                backgroundColor={colors.lightBlue50}
                borderRadius={7}
            >
                <Text>Hello</Text>
            </Box> */}
        </div>
    );
}
