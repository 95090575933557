import {
    Box,
    Center,
    Divider,
    Flex,
    FormLabel,
    HStack,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    Spinner,
    Text,
    Textarea,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import { BaseDatabaseFields, BaseNotebookFields } from "src/api/fragments";
import {
    DatabaseProviderEnum,
    Mutation,
    MutationCreateNotebookArgs,
    Query,
} from "src/api/generated/types";
import { ActionSheet, Button } from "src/components";
import { Touchable } from "src/components/Touchable";
import StatusTag from "src/components/styled/StatusTag";
import { colors } from "src/theme";
import postgresLogo from "src/assets/logos/postgres.svg";
import { api } from "src/api";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "lodash/fp";
import { show } from "redux-modal";
import { useMyToast } from "src/hooks";
import {
    getActiveQuery,
    setAnalysis,
    setCurrentPage,
    setInputHeight,
    setPageSize,
    setQuery,
    setQuestion,
    setTips,
} from "src/redux/reducers/activeQuery";
import { useDatabases } from "src/hooks/useDatabases";
import { Analysis } from "./Analysis";
import autosize from "autosize";
import { Tips } from "./Tips";
import { useDatabase } from "./useDatabase";
import TextareaAutosize from "react-textarea-autosize";
import numbro from "numbro";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { FixQueryPopover } from "./Fix";
import { useNavigate, useParams } from "react-router-dom";
import { client } from "src/api/axios";
import { config } from "src/config";
import { getAuthToken } from "src/utils/firebase";
import moment from "moment";
import { Maybe } from "src/core";
// import AceEditor from "react-ace";

// import "ace-builds/src-noconflict/mode-mysql";
// import "ace-builds/src-noconflict/theme-monokai";
// import "ace-builds/src-noconflict/ext-language_tools";

enum TextareaMode {
    Question = "question",
    SQL = "sql",
}

export const DatabaseInput = () => {
    const { notebookId, databaseId } = useParams();
    const { activeDatabase, pageSize } = useSelector(getActiveQuery);

    const [mode, setMode] = useState<TextareaMode>(TextareaMode.Question);

    const { queryDatabaseRows, getQuery, activeTable, activeNotebook } =
        useDatabase();

    const dispatch = useDispatch();
    const activeQuery = useSelector(getActiveQuery);
    const question = activeQuery.question;
    const query = activeQuery.query;
    const inputHeight = activeQuery.inputHeight;
    const codeEditorRef = useRef<HTMLTextAreaElement>(null);

    const _setQuestion = compose(dispatch, setQuestion);

    const toast = useMyToast();

    const _handleGeneratingQuery = async () => {
        if (!activeDatabase) {
            toast.show({
                status: "error",
                message: "Please select a database",
            });
            return;
        }
        if (!question) {
            toast.show({
                status: "error",
                message: "Please enter a question",
            });
            return;
        }

        try {
            setMode(TextareaMode.SQL);

            await getQuery(activeDatabase.id, question);
        } catch (err) {
            console.log(err);
            toast.show({
                status: "error",
                message: "Error generating query",
            });
        }
    };

    const _runQuery = useCallback(async () => {
        // console.log("querying database");
        // console.log(activeDatabase);
        if (!activeDatabase) return;
        if (!query) return;

        dispatch(setCurrentPage(0));

        await queryDatabaseRows(null, query, true, null);
    }, [query, pageSize, activeDatabase?.id]);

    // for query remove all new lines
    const value = mode === TextareaMode.Question ? question : query;

    const onChangeInput = (value: string) => {
        if (mode === TextareaMode.Question) {
            _setQuestion(value);
        }
        if (mode === TextareaMode.SQL) {
            dispatch(setQuery(value));
        }
    };

    const onPlay = async () => {
        if (mode === TextareaMode.Question) {
            await _handleGeneratingQuery();
        } else {
            await _runQuery();
        }
    };

    const _setMode = (mode: TextareaMode) => {
        codeEditorRef.current?.focus();
        setMode(mode);
    };

    // auto-rerun query on change of certain fields (ex. page size)
    useEffect(() => {
        _runQuery();
    }, [pageSize]);

    const tableName = activeTable?.name ?? null;

    const placeholder =
        mode === TextareaMode.Question
            ? `Find all ${tableName || "rows"} who have made one transaction...`
            : `SELECT * FROM ${tableName || "table"} WHERE id = 1;`;

    return (
        <div style={{ width: "100%" }}>
            <HStack
                w="100%"
                alignItems="center"
                padding="0.5rem 0.5rem 0.5rem 0.5rem"
            >
                <Flex flex={1}>
                    <Touchable
                        label="Ask Question"
                        iconName="fa-solid fa-question"
                        iconPosition="left"
                        iconStyle={{
                            color: colors.black,
                        }}
                        style={{
                            marginRight: "0.5rem",
                            backgroundColor:
                                mode === TextareaMode.Question
                                    ? colors.stone150
                                    : undefined,
                        }}
                        onClick={() => _setMode(TextareaMode.Question)}
                    />
                    <Touchable
                        label="Write SQL"
                        iconPosition="left"
                        iconName="fa-solid fa-code"
                        iconStyle={{
                            color: colors.black,
                        }}
                        style={{
                            marginRight: "0.5rem",
                            backgroundColor:
                                mode === TextareaMode.SQL
                                    ? colors.stone150
                                    : undefined,
                        }}
                        onClick={() => _setMode(TextareaMode.SQL)}
                    />

                    <Box marginRight="0.5rem">
                        <AnalysisPopover />
                    </Box>

                    <Box marginRight="0.5rem">
                        <FixQueryPopover />
                    </Box>

                    <Box marginRight="0.5rem">
                        <TipsPopover />
                    </Box>
                </Flex>
                <Warning mode={mode} />
            </HStack>

            <Divider />

            <HStack w="100%" alignItems="flex-start" marginBottom="1rem">
                <VStack flex={1} alignItems="flex-start">
                    <Box
                        flex={1}
                        width="100%"
                        style={{
                            position: "relative",
                        }}
                    >
                        {/* <i
                            style={{
                                fontSize: 14,
                                position: "absolute",
                                top: 20,
                                left: 15,
                                color: colors.gray30,
                            }}
                            className="fa-solid fa-search"
                        /> */}

                        {/* <AceEditor
                            mode={
                                mode === TextareaMode.Question
                                    ? "text"
                                    : "mysql"
                            }
                            theme="monokai"
                            value={value || ""}
                            height="100px"
                            // ref={codeEditorRef}
                            onChange={(v) => onChangeInput(v)}
                            name="database-editor"
                            placeholder={placeholder}
                            showGutter={false}
                            style={{
                                left: "0",
                                top: "0.25rem",
                                marginBottom: "0.5rem",
                                border: "none",
                                backgroundColor: "transparent",
                                // fontFamily: "Fira Code",
                                fontSize: 16,
                                fontFamily:
                                    "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                            }}
                            focus={true}
                            editorProps={{ $blockScrolling: true }}
                        /> */}

                        <CodeEditor
                            value={value || ""}
                            ref={codeEditorRef}
                            className="simple-code-editor"
                            language={
                                mode === TextareaMode.Question ? "text" : "sql"
                            }
                            onChange={(evn) => onChangeInput(evn.target.value)}
                            autoFocus
                            style={{
                                left: "0",
                                top: "0.25rem",
                                marginBottom: "0.5rem",
                                border: "none",
                                backgroundColor: "transparent",
                                // fontFamily: "Fira Code",
                                fontSize: 14,
                                fontFamily:
                                    "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                            }}
                            placeholder={placeholder}
                        />
                    </Box>
                </VStack>
            </HStack>

            <QueryInformation
                mode={mode}
                onPlay={onPlay}
                activeNotebook={activeNotebook}
            />
        </div>
    );
};

const Warning = ({ mode }: { mode: TextareaMode }) => {
    const { query } = useSelector(getActiveQuery);

    const _getWarning = () => {
        if (!query) return null;
        const hasSelect = query.toLowerCase().includes("select");
        const hasLimit = query.toLowerCase().includes("limit");

        if (hasSelect && !hasLimit) {
            return "By default we will not apply a LIMIT to your query, so we will attempt to fetch all rows that match.";
        }

        return null;
    };

    const warning = _getWarning();

    if (!warning) {
        return null;
    }

    if (mode === TextareaMode.Question) {
        return null;
    }

    return null;

    return (
        <Tooltip label={warning} placement="bottom-end">
            <Box display="flex" alignItems="center">
                <StatusTag
                    type="warning"
                    boxProps={{
                        style: {
                            border: `1px solid ${colors.yellow50}`,
                        },
                    }}
                    iconName="fa-solid fa-exclamation-triangle"
                    label="Query Warning"
                />
            </Box>
        </Tooltip>
    );
};

const AnalysisPopover = () => {
    const query = useSelector(getActiveQuery);
    const hasAnalysis = !!query.analysis;
    const isLoading = query.isLoadingAnalysis;

    if (!hasAnalysis) {
        return (
            <Tooltip
                hasArrow
                label="Once you type a query, we'll pull analysis on the query."
            >
                <div style={{ display: "flex" }}>
                    <Touchable
                        style={{
                            opacity: hasAnalysis ? 1 : 0.4,
                        }}
                        isLoading={isLoading}
                        label="Analysis"
                        iconName="fa-solid fa-cogs"
                        iconPosition="left"
                        iconStyle={{
                            color: colors.black,
                        }}
                    />
                </div>
            </Tooltip>
        );
    }

    return (
        <Popover trigger="hover">
            {/* @ts-ignore */}
            <PopoverTrigger>
                <div style={{ display: "flex" }}>
                    <Touchable
                        label="Analysis"
                        isLoading={isLoading}
                        iconName="fa-solid fa-cogs"
                        iconPosition="left"
                        iconStyle={{
                            color: colors.black,
                        }}
                    />
                </div>
            </PopoverTrigger>
            <PopoverContent
                style={{
                    width: 550,
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                }}
            >
                <Analysis />
            </PopoverContent>
        </Popover>
    );
};

const TipsPopover = () => {
    const { databaseId } = useParams();
    const query = useSelector(getActiveQuery);
    const isLoading = query.isLoadingTips;
    const tips = query.tips;
    const [queryGettingTipFor, setQueryGettingTipFor] = useState("");

    const pageSize = query.pageSize;
    const { getTips } = useDatabase();
    const [isOpen, setOpen] = useState(false);
    const canGetTips = !!query.query && databaseId;

    const isSame = queryGettingTipFor === query.query;

    const _refreshTips = async () => {
        if (!databaseId) return;
        if (!query.query) return;

        setQueryGettingTipFor(query.query);

        await getTips(databaseId, query.query);
    };

    const _getTips = async () => {
        if (!databaseId) return;
        if (!query.query) return;
        // if already tips -> just do this
        if (tips) {
            setOpen(!isOpen);
            return;
        }
        if (isLoading) {
            setOpen(!isOpen);
            return;
        }

        setOpen(true);

        setQueryGettingTipFor(query.query);

        await getTips(databaseId, query.query);
    };

    if (!canGetTips) {
        return (
            <Tooltip
                hasArrow
                label="Get recommendations on how to make this query more efficient."
            >
                <div style={{ display: "flex" }}>
                    <Touchable
                        style={{
                            opacity: canGetTips ? 1 : 0.4,
                        }}
                        label="Tips & Tricks"
                        isLoading={isLoading}
                        iconName="fa-solid fa-wand-magic-sparkles"
                        iconPosition="left"
                        iconStyle={{
                            color: colors.black,
                        }}
                    />
                </div>
            </Tooltip>
        );
    }

    return (
        <Popover trigger="click" isOpen={isOpen} onClose={() => setOpen(false)}>
            <PopoverTrigger>
                <div style={{ display: "flex" }}>
                    <Touchable
                        style={{
                            opacity: canGetTips ? 1 : 0.5,
                        }}
                        label="Tips & Tricks"
                        onClick={_getTips}
                        isLoading={isLoading}
                        iconName="fa-solid fa-wand-magic-sparkles"
                        iconPosition="left"
                        iconStyle={{
                            color: colors.black,
                        }}
                    />
                </div>
            </PopoverTrigger>
            <PopoverContent
                style={{
                    width: 500,
                    maxHeight: 500,
                    overflowY: "scroll",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                }}
            >
                <Tips
                    queryGettingTipFor={queryGettingTipFor}
                    refreshTips={_refreshTips}
                    isSameQuery={isSame}
                />
            </PopoverContent>
        </Popover>
    );
};

const QueryInformation = ({
    mode,
    activeNotebook,
    onPlay,
}: {
    mode: TextareaMode;
    activeNotebook: Maybe<BaseNotebookFields>;
    onPlay: () => Promise<void>;
}) => {
    const { databaseId } = useParams<{ databaseId: string }>();
    const [_, resetState] = useState(0);
    const activeQuery = useSelector(getActiveQuery);
    const [createNotebook] = useMutation<Pick<Mutation, "createNotebook">>(
        api.notebooks.create
    );
    const query = activeQuery.query;
    const lastQueriedAt = activeQuery.lastQueriedAt;
    const totalCounts = activeQuery.totalCount;
    const pageSize = activeQuery.pageSize;
    const isLoadingTotal = activeQuery.isLoadingTotal;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apollo = useApolloClient();

    const _onCreateQuery = async () => {
        if (!databaseId) return;
        if (!activeQuery.query) return;

        const params: MutationCreateNotebookArgs = {
            databaseId: databaseId,
            description: null,
            name: null,
            query: activeQuery.query,
            question: activeQuery.question,
        };

        const response = await createNotebook({
            variables: params,
            refetchQueries: [api.databases.notebooks],
        });

        const notebook = response.data?.createNotebook;

        if (notebook) {
            navigate(`/dashboard/${databaseId}/data/${notebook.id}`);
        }
    };

    const _changePageSize = (num: number) => {
        // SELECT * FROM "assets" limit 5
        // check if this string has a SQL limit statement in it
        const hasLimit = query && query.toLowerCase().includes("limit");
        // remove any limit expression ex limit 50 or limit 100 or "LIMIT" 50 or LIMIT 100
        const newQuery = query ? query.replace(/limit\s+\d+/gi, "") : query;

        dispatch(setPageSize(num));
        dispatch(setQuery(newQuery));

        // refetch the query api.databases.action.query with apollo client
        apollo.refetchQueries({
            include: [api.databases.actions.query],
        });

        console.log("new query", newQuery);
    };

    useEffect(() => {
        // Refresh every minute
        const intervalId = setInterval(() => {
            resetState((prevState) => prevState + 1);
        }, 15 * 1000); // 15 seconds

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    return (
        <HStack padding="0.65rem" alignItems="center">
            <Flex flex={1} margin={0} alignItems="center">
                {lastQueriedAt && (
                    <Text
                        color={colors.gray40}
                        fontSize="xs"
                        marginRight="1rem"
                        fontWeight={500}
                    >
                        <i
                            style={{ marginRight: 5 }}
                            className="fa-solid fa-server"
                        />{" "}
                        Last run: {moment(lastQueriedAt).fromNow()}
                    </Text>
                )}

                <HStack marginRight="1rem">
                    {isLoadingTotal && <Spinner size="sm" />}

                    <Text color={colors.gray40} fontWeight={500} fontSize="xs">
                        {isLoadingTotal
                            ? "Loading"
                            : numbro(totalCounts ?? 0).format("0,0")}{" "}
                        rows
                    </Text>
                </HStack>

                {/* dropdown UI from chakra */}
                <ActionSheet
                    content={{
                        width: 150,
                    }}
                    commands={[
                        {
                            label: "10 rows",
                            iconName: "fa-solid fa-rows",
                            onClick: () => {
                                _changePageSize(10);
                            },
                        },
                        {
                            label: "25 rows",
                            iconName: "fa-solid fa-rows",
                            onClick: () => {
                                _changePageSize(25);
                            },
                        },
                        {
                            label: "50 rows",
                            iconName: "fa-solid fa-rows",
                            onClick: () => {
                                _changePageSize(50);
                            },
                        },
                        {
                            label: "100 rows",
                            iconName: "fa-solid fa-rows",
                            onClick: () => {
                                _changePageSize(100);
                            },
                        },
                        {
                            label: "500 rows",
                            iconName: "fa-solid fa-rows",
                            onClick: () => {
                                _changePageSize(500);
                            },
                        },
                    ]}
                >
                    <Touchable
                        labelStyle={{ color: colors.gray40 }}
                        iconStyle={{ color: colors.gray40 }}
                        style={{
                            border: `1px solid ${colors.stone200}`,
                        }}
                        label={`${pageSize} rows`}
                        iconName="fa-solid fa-chevron-down"
                    />
                </ActionSheet>
            </Flex>

            <Touchable
                label="Save Query"
                // iconName="fa-solid fa-save"
                iconPosition="left"
                onClick={_onCreateQuery}
            />

            <HStack>
                {activeNotebook && (
                    <Text fontSize="xs" color={colors.gray40} marginRight={5}>
                        <i
                            className="fa-solid fa-bookmark"
                            style={{ marginRight: 5 }}
                        />{" "}
                        Saved {moment(activeNotebook.updatedAt).fromNow(false)}
                    </Text>
                )}

                <Button
                    style={{
                        backgroundColor: colors.green50,
                        color: colors.white,
                        borderRadius: 7,
                        padding: "0.35rem 0.5rem",
                        height: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `1px solid ${colors.green30}`,
                    }}
                    onClick={onPlay}
                >
                    <HStack alignItems="center" justifyContent="center">
                        <Text fontSize="xs" marginLeft="5px">
                            {mode === TextareaMode.Question
                                ? "Ask Question"
                                : "Run Query"}
                        </Text>
                        <i
                            style={{
                                fontSize: 10,
                            }}
                            className={
                                mode === TextareaMode.Question
                                    ? "fa-solid fa-comment"
                                    : "fa-solid fa-play"
                            }
                        />
                    </HStack>
                </Button>
            </HStack>
        </HStack>
    );
};
