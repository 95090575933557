import * as colors from "src/theme/colors";

// Borders
export const buttonBorderWidth = "4px";
export const borderRadius = "10px";
export const borderColor = colors.gray2;
export const boxBorder = `1px solid ${borderColor}`;

// Button Group
export const buttonMargin = "1.5rem";
export const buttonSpacing = "4";

// Scroll
export const scrollBuffer = 50;
