import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { api } from 'src/api';
import { Query } from 'src/api/generated/types';
import { HStack, Text } from '@chakra-ui/react';
import { colors } from 'src/theme';
import { BaseDatabaseFields } from 'src/api/fragments';
import { Button } from 'src/components';
import { Link } from 'react-router-dom';
import { useTheme } from 'src/hooks/useTheme';

function Databases() {
    const { data, loading, error } = useQuery<Pick<Query, "getTeamDatabases">>(api.teams.databases);

    const databases = data?.getTeamDatabases ?? [];

    return (
        <div style={{
            padding: "2rem 1rem"
        }}>
            <HStack>
                <Text flex={1} fontSize="xl" color={colors.black}>Databases</Text>
                <Link to="/dashboard/create-db">
                    <Button onClick={() => {
                        // TODO:
                    }} colorScheme="blue">
                        Add Database
                    </Button>
                </Link>
            </HStack>

            {databases.length === 0 ? (
                <p>No databases found.</p>
            ) : (
                <div>
                    {databases.map((db) => (
                        <DatabaseRow key={db.id} db={db} />
                    ))}
                </div>
            )}
        </div>
    );
}

const DatabaseRow = ({ db }: {
    db: BaseDatabaseFields
}) => {
    const theme = useTheme();

    return (
        <HStack spacing="2rem" style={{
            padding: "1rem",
            background: colors.gray90,
            margin: "1rem 0",
            borderRadius: 15,
            cursor: "pointer"
        }}>
            {/* image of postgres */}
            <img
                style={{
                    width: 20, height: "auto",
                }}
                src={require("src/assets/icons/postgres.svg").default}
            />

            <div>
                <Text style={{ marginBottom: 5 }}>{db.name}</Text>
                <Text fontSize="sm" color={theme.text}>Added on {new Date(db.createdAt).toLocaleDateString()}</Text>
            </div>
            <Text>{db.provider}</Text>
            <Text>SSL: {db.isSSL ? 'Enabled' : 'Disabled'}</Text>
        </HStack>
    )

}

export default Databases;
