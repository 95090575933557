import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { colors } from "src/theme";
import { isMobile } from "react-device-detect";

type Props = {
    style?: any;
    message: string;
};

const Info = ({ style = {}, message }: Props) => {
    return (
        <Tooltip
            zIndex={1401}
            // @ts-ignore
            trigger={isMobile ? "click" : "hover"}
            bg={colors.black}
            placement="top"
            borderRadius="0.25rem"
            label={message}
            padding={{ base: "0.5rem 1rem" }}
        >
            <i
                className="fas fa-info-circle"
                style={{
                    position: "relative",
                    top: 0,
                    left: 4,
                    color: colors.gray4,
                    display: "inline-block",
                    ...style,
                }}
            />
        </Tooltip>
    );
};

export { Info };
