export const config = {
    ipAddresses: [
        "52.41.36.82",
        "54.191.253.12",
        "44.226.122.3"
    ],
    env: process.env.NODE_ENV || "local",
    apiUrl: process.env.REACT_APP_API_URL || "http://localhost:8081",
    discordLink:
        process.env.REACT_APP_DISCORD_INVITE_LINK ||
        "https://discord.gg/jbcY5rbr3E",
    twitterLink: "https://twitter.com/accrue_finance",
    sentryDsn: process.env.REACT_APP_SENTRY_DSN || "",

    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
        messagingSenderId:
            process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
        appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
    },
    redux: {
        logger: process.env.REACT_APP_REDUX_LOGGER === "true",
    },
};

export const isProduction = config.env === "production";

export * from "./constants";
