import { useEffect } from "react";
import {
    BrowserRouter,
    HashRouter,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import { useAuth } from "./useAuth";

// screens
import { signOut } from "firebase/auth";
import { isElectron } from "react-device-detect";
import { useSelector } from "react-redux";
import { SideBarPageTemplate } from "src/components";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { auth } from "src/utils/firebase";
import { AuditLogs } from "src/views/AuditLogs";
import { Login, Signup } from "src/views/Authentication";
import Campaigns from "src/views/Campaigns";
import { CreateCampaign } from "src/views/CreateCampaign";
import { CreateDatabase } from "src/views/CreateDatabase";
import Databases from "src/views/Databases";
import Download from "src/views/Download";
import Home from "src/views/Home";
import Loading from "src/views/Loading";
import { Notebook } from "src/views/Notebook";
import { Profile } from "src/views/Profile";
import { TableView } from "src/views/TableView";
import Team from "src/views/Team";

const Router = isElectron ? HashRouter : BrowserRouter;

const RedirectHack = () => {
    const { spaceId } = useParams();
    const navigate = useNavigate();

    // useEffect(() => navigate(`/dashboard`, { replace: true }));

    return null;
};

const DashboardContainer = () => {
    const { clientId } = useParams<{ clientId: string }>();

    return <SideBarPageTemplate />;
};

const DashboardRedirect = () => {
    // this will do a redirect
    return <Loading />;
};

const DashboardRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<DashboardRedirect />} />
            <Route path="create-db" element={<CreateDatabase />} />
            <Route path="profile" element={<Profile />} />
            <Route path="databases" element={<Databases />} />
            <Route path="campaigns" element={<Campaigns />} />
            <Route path="create-campaign">
                <Route path="" element={<CreateCampaign />} />
                <Route path="message" element={<CreateCampaign />} />
                <Route path="send" element={<CreateCampaign />} />
            </Route>
            <Route path="team" element={<Team />} />
            <Route path="audit-logs" element={<AuditLogs />} />
            <Route path="tables">
                <Route path="" element={<TableView />} />
                <Route path=":tableId" element={<TableView />} />
            </Route>
            <Route path="data">
                <Route path=":notebookId" element={<Notebook />} />
            </Route>
        </Routes>
    );
};

export const MainRoutes = () => {
    const { pathname } = useLocation();
    const status = useSelector(getUserAuthStatus);
    const isLoggedIn = status === "LOGGED_IN";

    useAuth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Routes>
            <Route path="" element={<Home />} />
            <Route path="sign-up" element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="home" element={<Home navigateAway={false} />} />
            <Route path="download" element={<Download />} />

            {isLoggedIn && (
                <>
                    <Route path="dashboard" element={<SideBarPageTemplate />}>
                        <Route path="*" element={<DashboardRoutes />} />
                    </Route>
                </>
            )}
            <Route path="*" element={<RedirectHack />} />
        </Routes>
    );
};

const Logout = () => {
    const navigate = useNavigate();
    // const apolloClient = useApolloClient();

    useEffect(() => {
        signOut(auth)
            // .then(() => apolloClient.cache.reset())
            .then(() => navigate("/login"));
    }, []);

    return null;
};

export const Navigation = () => (
    <Router>
        <MainRoutes />
    </Router>
);
