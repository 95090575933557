import {
    Box,
    Button,
    Center,
    Flex,
    FormLabel,
    HStack,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    Text,
    Textarea,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import { Touchable } from "src/components/Touchable";
import StatusTag from "src/components/styled/StatusTag";
import { colors } from "src/theme";
import {
    Component,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveQuery, setQuery } from "src/redux/reducers/activeQuery";
import { useLazyQuery, useQuery } from "@apollo/client";
import { api } from "src/api";
import { Query, QueryFixQueryArgs } from "src/api/generated/types";
import { useParams } from "react-router-dom";
import { useMyToast } from "src/hooks";
import { Copy } from "src/components";
import { format } from "sql-formatter";
import CodeEditor from "@uiw/react-textarea-code-editor";

export const FixQueryPopover = () => {
    const { databaseId } = useParams<{ databaseId: string }>();
    const query = useSelector(getActiveQuery);
    const hasQuery = !!query.query;
    const toast = useMyToast();
    const [input, setInput] = useState<string>("");
    const [fixQuery, { loading, data }] = useLazyQuery<Pick<Query, "fixQuery">>(
        api.databases.fixQuery
    );
    const [isOpen, setOpen] = useState(false);

    const fixedQuery = data?.fixQuery?.fixedQuery;
    const lists = formatMessageToList(fixedQuery || "");

    const onClickUse = () => {
        setOpen(false);
    };

    const _fixQuery = useCallback(async () => {
        if (!databaseId) return;
        if (!query.query) {
            toast.show({
                status: "error",
                message: "No query to fix",
            });
            return;
        }

        const variables: QueryFixQueryArgs = {
            query: query.query,
            problem: input,
            databaseId,
        };

        await fixQuery({
            variables: variables,
            fetchPolicy: "no-cache",
        });
    }, [input, databaseId, query]);

    if (!hasQuery) {
        return (
            <Tooltip
                hasArrow
                label="Once you type a query, we'll help you fix it if it isn't doing what you want."
            >
                <div style={{ display: "flex" }}>
                    <Touchable
                        style={{
                            opacity: hasQuery ? 1 : 0.4,
                        }}
                        label="Fix query"
                        iconName="fa-solid fa-wrench"
                        iconPosition="left"
                        iconStyle={{
                            color: colors.black,
                        }}
                    />
                </div>
            </Tooltip>
        );
    }

    return (
        <Popover
            trigger="click"
            isOpen={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            <PopoverTrigger>
                <div style={{ display: "flex" }}>
                    <Touchable
                        style={{
                            opacity: hasQuery ? 1 : 0.4,
                        }}
                        label="Fix query"
                        iconName="fa-solid fa-wrench"
                        iconPosition="left"
                        iconStyle={{
                            color: colors.black,
                        }}
                    />
                </div>
            </PopoverTrigger>
            <PopoverContent
                style={{
                    width: 500,
                    maxHeight: 500,
                    overflowY: "scroll",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                }}
            >
                <Box
                    style={{
                        padding: "1rem",
                    }}
                >
                    <FormLabel fontSize="sm">
                        What is wrong with this query?
                    </FormLabel>

                    <Textarea
                        value={input}
                        style={{
                            padding: "0.5rem 1rem",
                            fontSize: 12,
                        }}
                        focusBorderColor={colors.primary}
                        placeholder="What is wrong with your query..."
                        onChange={(e) => setInput(e.target.value)}
                    />

                    <HStack justifyContent="flex-end">
                        <Button
                            size="sm"
                            isDisabled={!input}
                            style={{
                                marginTop: "1rem",
                                backgroundColor: colors.primary,
                                color: "white",
                                borderRadius: 7,
                            }}
                            _hover={{
                                backgroundColor: colors.primary,
                                opacity: 0.8,
                            }}
                            onClick={_fixQuery}
                            isLoading={loading}
                        >
                            Fix query{" "}
                            <i
                                style={{ marginLeft: "10px" }}
                                className="fa-solid fa-arrow-right"
                            />
                        </Button>
                    </HStack>

                    <Box marginTop="1rem">
                        {lists.map((list) => {
                            return (
                                // @ts-ignore
                                <ErrorBoundary key={list.text}>
                                    {list.isCode ? (
                                        <CodeBlock
                                            onClickUse={onClickUse}
                                            key={list.code}
                                            code={list.code || ""}
                                        />
                                    ) : (
                                        <Box
                                            key={list.text}
                                            style={{
                                                borderRadius: "0.5rem",
                                                marginTop: "1rem",
                                                fontSize: 16,
                                                width: "100%",
                                                whiteSpace: "pre-wrap",
                                            }}
                                        >
                                            {list.text}
                                        </Box>
                                    )}
                                </ErrorBoundary>
                            );
                        })}
                    </Box>
                </Box>
            </PopoverContent>
        </Popover>
    );
};

const CodeBlock = ({
    code: _code,
    onClickUse,
}: {
    code: string;
    onClickUse: () => void;
}) => {
    const dispatch = useDispatch();

    // remove any 'sql' from the string and newlines
    const code = _code.replace(/sql/gi, " ").replace(/\n/g, " ");
    const codeRef = useRef(null);

    const formattedSql = useMemo(() => {
        const formattedSQL = format(code || "", {
            language: "postgresql",
            tabWidth: 4,
        });
        return formattedSQL;
    }, [code]);

    const _useSql = () => {
        dispatch(setQuery(formattedSql));
        onClickUse();
    };

    return (
        <div
            style={{
                position: "relative",
            }}
        >
            <Box
                style={{
                    backgroundColor: colors.stone100,
                    borderRadius: "0.5rem",
                    fontFamily: "Fira Code",
                    padding: "1.5rem 1rem 1rem 1rem",
                    width: "100%",
                    whiteSpace: "pre-wrap",
                    position: "relative",
                }}
                className="code-block"
            >
                <CodeEditor
                    value={formattedSql}
                    className="simple-code-editor"
                    language="sql"
                    autoFocus
                    disabled
                    style={{
                        border: "none",
                        backgroundColor: "transparent",
                        // fontFamily: "Fira Code",
                        fontSize: 15,
                        fontFamily:
                            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    }}
                />
            </Box>

            <Touchable
                style={{
                    position: "absolute",
                    top: 7,
                    right: 7,
                }}
                label="Click to use"
                iconName="fa-solid fa-check-circle"
                onClick={_useSql}
            />
        </div>
    );
};

function formatMessageToList(message: string) {
    const codeChunks = message.match(/```(.*?)```/gs);
    if (!codeChunks)
        return [
            {
                text: message,
                isCode: false,
            },
        ];

    const list: { isCode: boolean; text?: string; code?: string }[] = [];

    message.split(/```(.*?)```/gs).forEach((chunk, index) => {
        if (index % 2 === 0) {
            // Not a code chunk
            list.push({
                isCode: false,
                text: chunk.trim(),
            });
        } else {
            // Code chunk
            list.push({
                isCode: true,
                code: chunk.trim(),
            });
        }
    });

    return list.filter((item) => item.text || item.isCode);
}

class ErrorBoundary extends Component {
    state = { hasError: false, error: null, errorInfo: null };

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error, errorInfo });
        // You can also log the error to an error reporting service here
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI here
            return <h1>Something went wrong.</h1>;
        }
        return (this.props as any).children;
    }
}
