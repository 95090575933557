import React, { Component, useState } from "react";
import isEmpty from "lodash/isEmpty";

import { api } from "../../api";
import { Link } from "react-router-dom";
import { HStack } from "@chakra-ui/react";
import { colors } from "src/theme";

type Props = {
    user: any;
    history: any;
};

type State = {
    copying: boolean;
    firstName: string;
    lastName: string;
    loadingPage: boolean;
    orders: any[];
    triggers: [];
};

export const Profile = () => {
    const [copying, setCopying] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    // let { firstName, lastName, loadingPage, orders, copying, triggers } =
    //   this.state;
    // let { user } = this.props;
    return (
        <div>
            <HStack
                style={{
                    position: "relative",
                    paddingTop: 75,
                }}
            >
                <Link to="/logout">
                    <span
                        style={{
                            position: "absolute",
                            color: colors.red50,
                            top: 30,
                            fontSize: 18,
                            cursor: "pointer",
                            right: 35,
                        }}
                    >
                        Logout
                    </span>
                </Link>
                {/* <Col md={12} style={{ padding: 20 }}>
          <ProfileInfo
            user={user}
            firstName={firstName}
            lastName={lastName}
            onChange={(field, value) =>
              this.setState({ [field]: value } as any)
            }
          />
          <br />
        </Col> */}

                <div
                    style={{
                        padding: 20,
                        paddingTop: 0,
                        borderRadius: 5,
                        margin: "0 auto 40px auto",
                        textAlign: "center",
                    }}
                >
                    <h1>Rewards</h1>
                    <br />
                </div>
            </HStack>
            <HStack
                style={{
                    padding: "100px 20px",
                    backgroundColor: colors.white,
                    border: "1px solid #efefef",
                    margin: "0 auto 40px auto",
                    textAlign: "center",
                }}
            >
                <h1>Share</h1>
                <h3>
                    Help us spread the word and bring functional and stylish
                    pants to every women{" "}
                    <span role="img" aria-label="party">
                        🎉
                    </span>
                </h3>
                <br />
                <br />
            </HStack>
        </div>
    );
};
