import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { compose } from "lodash/fp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setUserAuthStateChanged } from "src/redux/reducers/user";
import { auth } from "src/utils/firebase";

export const useAuth = () => {
    const dispatch = useDispatch();
    const [search] = useSearchParams();
    const _setAuthStatus = compose(dispatch, setUserAuthStateChanged);

    useEffect(() => {
        _setAuthStatus("NOT_LOADED");

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                _setAuthStatus("LOGGED_IN");
            } else {
                _setAuthStatus("NOT_LOGGED_IN");
            }
        });
    }, []);

    useEffect(() => {
        const token = search.get("authToken");

        if (token) {
            void signInWithCustomToken(auth, token);
        }
    }, [search]);
};
