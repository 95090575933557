import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Text,
} from "@chakra-ui/react";
import { CSSProperties, useCallback, useMemo } from "react";
import { colors } from "src/theme";
import { Controller, Control } from "react-hook-form";
import { isNil } from "lodash";
import { Info } from "../Info";
import ReactSelect, {
    GroupBase,
    OptionsOrGroups,
    PropsValue,
} from "react-select";
import makeAnimated from "react-select/animated";
import { isString } from "lodash/fp";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import { Required } from "./Required";

const animatedComponents = makeAnimated();

type MyInputProps = {
    label?: string;
    name?: string;
    hidden?: boolean;
    infoMessage?: string;
    visible?: boolean;
    control?: Control<any, any>;
    containerStyle?: CSSProperties;
    width?: string;
    isRequired?: boolean;
    value?: PropsValue<boolean>;
    placeholder?: string;
    error?: string;
};

export const Checkbox = (props: MyInputProps) => {
    if (props.hidden) {
        return null;
    }

    if (!isNil(props.visible) && !props.visible) {
        return null;
    }

    return (
        <Box style={{ marginBottom: "1rem", ...props.containerStyle }}>
            <FormControl isInvalid={!!props.error}>
                {props.label && (
                    <FormLabel color={colors.black}>
                        {props.label} {props.isRequired && <Required />}
                        {props.infoMessage && (
                            <Info message={props.infoMessage} />
                        )}
                    </FormLabel>
                )}

                {props.control && props.name && (
                    <Controller
                        control={props.control}
                        name={props.name}
                        render={({
                            field: { value, onChange, onBlur, ref },
                        }) => (
                            <input
                                type="checkbox"
                                onChange={onChange}
                                checked={value ? true : false}
                                ref={ref}
                            />
                        )}
                    />
                )}

                <FormErrorMessage>{props.error}</FormErrorMessage>
            </FormControl>
        </Box>
    );
};
