import { Box, Center, Divider, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { format } from "sql-formatter";
import { Copy, Info } from "src/components";
import StatusTag from "src/components/styled/StatusTag";
import { getActiveQuery } from "src/redux/reducers/activeQuery";
import { colors } from "src/theme";
import { Touchable } from "../Touchable";
import { useDatabase } from "./useDatabase";
import { useParams } from "react-router-dom";

export const Tips = ({
    isSameQuery,
    refreshTips,
    queryGettingTipFor,
}: {
    isSameQuery: boolean;
    queryGettingTipFor: string;
    refreshTips: () => Promise<void>;
}) => {
    const { databaseId } = useParams();
    const { getTips } = useDatabase();
    const query = useSelector(getActiveQuery);
    const tips = query.tips;
    const isLoading = query.isLoadingTips;

    const lists = formatMessageToList(tips ?? "");

    return (
        <Box padding="1.5rem">
            <Box
                flex={1}
                width="100%"
                style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Text fontSize="sm" flex={1} fontWeight="bold">
                    Tips & Tricks
                </Text>

                <Touchable
                    onClick={refreshTips}
                    label="Refresh"
                    iconName="fa-solid fa-sync"
                />
                {/* <StatusTag
                    infoMessage="This feature is only available for premium users."
                    type="warning"
                    iconName="fa-solid fa-lock"
                    label="Premium"
                /> */}
            </Box>

            {!isSameQuery && (
                <Center
                    style={{
                        marginTop: "1rem",
                        backgroundColor: colors.yellow90,
                        border: `1px solid ${colors.yellow50}`,
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                        fontSize: 12,
                        color: colors.yellow20,
                    }}
                >
                    <Text fontSize="sm" fontWeight="bold">
                        <Info
                            style={{
                                color: colors.yellow20,
                                marginRight: "0.5rem",
                            }}
                            message={`Tips are for query: ${queryGettingTipFor}`}
                        />{" "}
                        Query has changed, refresh to get new tips{" "}
                        <i className="fas fa-arrow-up" />
                    </Text>
                </Center>
            )}

            {isLoading && (!lists || !lists[0]) && (
                <Center
                    style={{
                        marginTop: "1rem",
                        backgroundColor: colors.stone100,
                        borderRadius: "0.5rem",
                        padding: "1rem",
                        fontSize: 12,
                    }}
                >
                    <Text fontSize="xs" fontWeight="bold">
                        Generating tips...
                    </Text>
                </Center>
            )}

            {lists.map((list) => {
                if (list.isCode) {
                    return <CodeBlock key={list.code} code={list.code || ""} />;
                }

                return (
                    <Box
                        key={list.text}
                        style={{
                            borderRadius: "0.5rem",
                            marginTop: "1rem",
                            fontSize: 14,
                            width: "100%",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {/* turn any text in **{}** into bold tags */}
                        {list.text?.replace(
                            /\*\*(.*?)\*\*/g,
                            (_, text) => text
                        )}

                        {/* turn any text in *{}* into italic tags */}
                    </Box>
                );
            })}
        </Box>
    );
};

const CodeBlock = ({ code: _code }: { code: string }) => {
    // remove the 'sql' part in the front
    const code = _code.replace(/^sql/, "");

    const formattedSql = useMemo(
        () => format(code || "", { language: "postgresql" }),
        [code]
    );

    return (
        <Box
            style={{
                backgroundColor: colors.stone100,
                borderRadius: "0.5rem",
                fontFamily: "Fira Code",
                padding: "2.5rem 1rem 1rem 1rem",
                marginTop: "1rem",
                width: "100%",
                fontSize: 14,
                whiteSpace: "pre-wrap",
                position: "relative",
            }}
        >
            {formattedSql}

            <Copy
                labelStyle={{
                    position: "absolute",
                    top: 7,
                    right: 7,
                }}
                label="Click to copy"
                value={code}
            />
        </Box>
    );
};

function formatMessageToList(message: string) {
    const codeChunks = message.match(/```(.*?)```/gs);
    if (!codeChunks)
        return [
            {
                text: message,
                isCode: false,
            },
        ];

    const list: { isCode: boolean; text?: string; code?: string }[] = [];

    message.split(/```(.*?)```/gs).forEach((chunk, index) => {
        if (index % 2 === 0) {
            // Not a code chunk
            list.push({
                isCode: false,
                text: chunk.trim(),
            });
        } else {
            // Code chunk
            list.push({
                isCode: true,
                code: chunk.trim(),
            });
        }
    });

    return list.filter((item) => item.text || item.isCode);
}
