import { Divider, HStack, Progress, Text, Tooltip } from "@chakra-ui/react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { noop } from "lodash";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { getCommandIsOpen, setCommandIsOpen } from "src/redux/reducers/global";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { colors, other } from "src/theme";
import { classNames, getOperatingSystem } from "src/utils/helpers";
import { NavigationLink } from "./NavigationLink";
import { useRootUrl } from "./utils";
import { isElectron } from "react-device-detect";
import { DatabaseSelector } from "./DatabaseSelector";
import { useDatabases } from "src/hooks/useDatabases";
import { TableRow } from "./TableRow";
import { DatabaseModal } from "../modals/DatabaseModal";
import { BaseArtifactFields, BaseNotebookFields } from "src/api/fragments";
import { ActionSheet, Button } from "../styled";
import UserProfileIcon from "../UserProfileIcon";
import { useMe, useMyToast } from "src/hooks";
import { Touchable } from "../Touchable";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Mutation } from "src/api/generated/types";
import { NotebookRow } from "./NotebookRow";

export const Sidebar = () => {
    const rootUrl = useRootUrl();
    const { databaseId } = useParams();
    const { tables, notebooks } = useDatabases();

    return (
        <>
            <DatabaseModal />

            <div
                // style={styleSidebar}
                className={`md:fixed md:inset-y-0 md:flex md:flex-col border-r border-stone-200 bg-stone-50 z-20 backdrop-blur-sm backdrop-filter ${isElectron ? "pt-10" : "pt-4"
                    }`}
                style={{
                    width: 225,
                }}
            >
                {/* Sidebar component, swap this element with another sidebar if you like */}

                {/* <div className="">
                    <MyProfile />
                </div> */}

                {/* <Divider style={{ margin: "1rem 0 1rem 0" }} /> */}

                {/* <DatabaseSelector /> */}

                {/* <Divider style={{ margin: "1rem 0 0 0" }} /> */}

                <div className="flex min-h-0 flex-1 flex-col ">
                    <div className="pt-3 flex flex-1 flex-col overflow-scroll pb-4 pl-2 pr-2">


                        <NavigationLink
                            to={`/dashboard/campaigns`}
                            label="Campaigns"
                            icon={<i className="fa-sharp fa-messages" />}
                        />

                        <NavigationLink
                            to={`/dashboard/databases`}
                            label="Data"
                            icon={<i className="fa-solid fa-database" />}
                        />

                        <NavigationLink
                            to={`/dashboard/team`}
                            label="Team"
                            icon={<i className="fa-sharp fa-users" />}
                        />

                        {/* <Tables tables={tables} /> */}
                    </div>

                    <DownloadDesktop />
                </div>
            </div>
        </>
    );
};

const DownloadDesktop = () => {
    const computer = getOperatingSystem();

    if (isElectron) {
        return null;
    }

    return null;

    return (
        <div
            style={{
                width: "100%",
                padding: "0.25rem 0.25rem",
                backgroundColor: colors.stone100,
                borderTop: `1px solid ${colors.stone200}`,
                boxShadow: `0px -10px 10px -10px ${colors.stone200}`,
            }}
        >
            {/* <NavigationLink
                to="mailto:andrew@dbgpt.sh"
                label="Customer Support"
                icon={<i className="fas fa-support" />}
            /> */}

            <a href="#" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                    {/* <p className="text-sm font-medium text-stone-700 group-hover:text-stone-900">
                            Download to Desktop
                        </p> */}
                    {/* <p className="mt-2 text-sm font-normal text-stone-700 group-hover:text-stone-900">
                            You have categorized 27 out of 250 free
                            transactions.
                        </p>
                        <Progress
                            className="my-4 knead__progress_active_color"
                            value={27}
                            height={2}
                            borderRadius={2}
                            bg={colors.stone150}
                        />
                        <p className="text-sm font-medium text-stone-700 group-hover:text-stone-900 underline">
                            Upgrade Now 🙌
                        </p> */}

                    <Link
                        style={{ width: "100%" }}
                        to="mailto:andrew@dbgpt.sh&subject"
                    >
                        <Button
                            style={{
                                backgroundColor: colors.primary,
                                color: colors.white,
                                fontSize: 14,
                                width: "100%",
                                padding: "1.5rem 0.5rem",
                                border: `2px solid ${colors.white}`,
                            }}
                        >
                            Would you like to download this to your computer?
                        </Button>
                    </Link>
                </div>
            </a>
        </div>
    );
};

const Tables = ({ tables }: { tables: BaseArtifactFields[] }) => {
    const { databaseId } = useParams<{ databaseId: string }>();
    const [showTables, setShowTables] = useState(true);
    const [syncDatabase] = useMutation(api.databases.sync);
    const toast = useMyToast();

    const _syncTables = async (e: any) => {
        e.stopPropagation();

        if (!databaseId) return;

        try {
            await syncDatabase({
                variables: {
                    databaseId,
                },
                refetchQueries: [
                    api.databases.artifacts,
                    api.databases.retrieve,
                ],
            });
        } catch (err) {
            toast.show({
                status: "error",
                message: (err as any).message,
            });
        }
    };

    return (
        <nav className="space-y-1 px-2">
            <Touchable
                iconName={
                    showTables
                        ? "fa-solid fa-chevron-down"
                        : "fa-solid fa-chevron-right"
                }
                style={{
                    width: "100%",
                    userSelect: "none",
                    alignItems: "center",
                }}
                labelStyle={{
                    flex: 1,
                    textTransform: "uppercase",
                    fontSize: 12,
                    fontWeight: "bold",
                }}
                iconPosition="left"
                label="Tables"
                onClick={() => setShowTables(!showTables)}
                rightComponent={
                    <Tooltip label="Sync the tables of the database.">
                        <div style={{ display: "flex" }}>
                            <Touchable
                                spinnerSize="xs"
                                onClick={_syncTables}
                                iconName="fa-solid fa-sync"
                                style={{ padding: "2px" }}
                            />
                        </div>
                    </Tooltip>
                }
            />

            {showTables && (
                <div>
                    {tables.map((table) => (
                        <TableRow table={table} key={table.id} />
                    ))}
                </div>
            )}
        </nav>
    );
};

const Notebooks = ({ notebooks }: { notebooks: BaseNotebookFields[] }) => {
    const { databaseId } = useParams();
    const [showQueries, setShowQuery] = useState(true);
    const [createNotebook] = useMutation<Pick<Mutation, "createNotebook">>(
        api.notebooks.create
    );
    const navigate = useNavigate();

    const _createQuery = async (e: any) => {
        e.stopPropagation();

        const response = await createNotebook({
            variables: {
                databaseId,
                query: "",
                description: "",
                name: "New notebook",
            },
            refetchQueries: [api.databases.notebooks],
        });

        const notebook = response?.data?.createNotebook;

        navigate(`/dashboard/${databaseId}/data/${notebook?.id}`);
    };

    return (
        <nav className="space-y-1 px-2">
            <Touchable
                iconName={
                    showQueries
                        ? "fa-solid fa-chevron-down"
                        : "fa-solid fa-chevron-right"
                }
                style={{
                    width: "100%",
                    userSelect: "none",
                }}
                labelStyle={{
                    flex: 1,
                    textTransform: "uppercase",
                    fontSize: 12,
                    fontWeight: "bold",
                }}
                iconPosition="left"
                label="Queries"
                onClick={() => setShowQuery(!showQueries)}
                rightComponent={
                    <Touchable
                        iconName="fa-solid fa-plus"
                        onClick={_createQuery}
                        style={{ padding: "0.25rem" }}
                    />
                }
            />

            {showQueries && (
                <div
                    style={{
                        marginBottom: "1rem",
                    }}
                >
                    {notebooks.map((notebook) => (
                        <NotebookRow notebook={notebook} key={notebook.id} />
                    ))}
                </div>
            )}
        </nav>
    );
};
const Search = () => {
    const dispatch = useDispatch();
    const setCommandOpen = compose(dispatch, setCommandIsOpen);

    return (
        <div
            onClick={() => setCommandOpen(true)}
            style={{
                width: 230,
                margin: "auto",
            }}
            className="cursor-pointer group flex items-center px-1 rounded-md py-2 text-sm font-medium bg-stone-100 border-stone-150 border"
        >
            <div
                className={classNames(
                    "text-stone-400 group-hover:text-stone-500",
                    "mr-3 flex-shrink-0 h-5 w-5 text-center"
                )}
            >
                <i className={"fa-solid fa-search"} aria-hidden="true" />
            </div>
            <div className="flex-1 text-stone-800 text-sm">Search</div>
            <kbd
                className={
                    "inline-flex items-center rounded-lg px-3 py-0.5 font-sans text-xs font-semibold text-stone-500 bg-stone-200 group-hover:bg-stone-300 group-hover:text-stone-800"
                }
            >
                ⌘K
            </kbd>
        </div>
    );
};

const MobileSidebar = () => {
    return (
        <Transition.Root show={true} as={Fragment}>
            <Dialog as="div" className="relative z-40 md:hidden" onClose={noop}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-stone-600 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    // onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">
                                            Close sidebar
                                        </span>
                                        {/* <XMarkIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                /> */}
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                                <div className="flex flex-shrink-0 items-center px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                        alt="Your Company"
                                    />
                                </div>
                                <nav className="space-y-1 px-3"></nav>
                            </div>
                            <div className="flex flex-shrink-0 border-t border-stone-200 p-4">
                                <a
                                    href="#"
                                    className="group block flex-shrink-0"
                                >
                                    <div className="flex items-center">
                                        <div>
                                            <img
                                                className="inline-block h-10 w-10 rounded-full"
                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-base font-medium text-stone-700 group-hover:text-stone-900">
                                                Tom Cook
                                            </p>
                                            <p className="text-sm font-medium text-stone-500 group-hover:text-stone-700">
                                                View profile
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    <div className="w-14 flex-shrink-0">
                        {/* Force sidebar to shrink to fit close icon */}
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
