import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { config } from "src/config";

export const initSentry = () => {
    Sentry.init({
        dsn: config.sentryDsn,
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
};
