import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import {
    BaseArtifactFields,
    BaseDatabaseFields,
    BaseNotebookFields,
} from "src/api/fragments";
import { ArtifactTypeEnum } from "src/api/generated/types";
import { useMe } from "src/hooks";
import { setActiveDatabase } from "src/redux/reducers/activeQuery";

export const useDatabases = () => {
    const { databaseId } = useParams<{ databaseId: string }>();
    const dispatch = useDispatch();

    const [fetchDatabases, { data: databasesData }] = useLazyQuery<{
        getTeamDatabases?: BaseDatabaseFields[];
    }>(api.teams.databases);
    const [fetchDatabase, { data: databaseData }] = useLazyQuery<{
        getDatabase?: BaseDatabaseFields;
    }>(api.databases.retrieve);
    const [fetchDatabaseArtifacts, { data: databaseArtifactsData }] =
        useLazyQuery<{
            getDatabaseArtifacts?: BaseArtifactFields[];
        }>(api.databases.artifacts);

    const [fetchDatabaseNotebooks, { data: databaseNotebooksData }] =
        useLazyQuery<{
            getDatabaseNotebooks?: BaseNotebookFields[];
        }>(api.databases.notebooks);

    const databases = useMemo(
        () => databasesData?.getTeamDatabases ?? [],
        [databasesData]
    );

    const activeDatabase = useMemo(
        () => databaseData?.getDatabase ?? null,
        [databaseData]
    );

    const artifacts = useMemo(
        () => databaseArtifactsData?.getDatabaseArtifacts ?? [],
        [databaseArtifactsData]
    );

    const notebooks = useMemo(
        () => databaseNotebooksData?.getDatabaseNotebooks ?? [],
        [databaseNotebooksData]
    );

    const indexes = useMemo(
        () =>
            artifacts.filter(
                (artifact) => artifact.type === ArtifactTypeEnum.Index
            ),
        [artifacts]
    );

    const tables = useMemo(
        () =>
            artifacts.filter(
                (artifact) => artifact.type === ArtifactTypeEnum.Table
            ),
        [artifacts]
    );

    useEffect(() => {
        fetchDatabases();
    }, [fetchDatabases]);

    useEffect(() => {
        if (!databases.length) return;

        fetchDatabase({ variables: { databaseId } });
        fetchDatabaseArtifacts({ variables: { databaseId } });
        fetchDatabaseNotebooks({ variables: { databaseId } });
    }, [databases, databaseId]);

    useEffect(() => {
        dispatch(setActiveDatabase(activeDatabase));
    }, [activeDatabase]);

    return {
        databases,
        activeDatabase,
        artifacts,
        indexes,
        tables,
        notebooks,
    };
};
