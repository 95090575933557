import React, { createContext, useCallback, useEffect, useMemo } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
    Box,
    Center,
    Divider,
    Flex,
    HStack,
    Text,
    Textarea,
    VStack,
    calc,
} from "@chakra-ui/react";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useMe, useMyToast } from "src/hooks";
import { useLazyQuery } from "@apollo/client";
import { api } from "src/api";
import { BaseArtifactFields, BaseDatabaseFields } from "src/api/fragments";
import { useDatabases } from "src/hooks/useDatabases";
import { ActionSheet, Button } from "src/components";
import { DatabaseInput } from "../../components/database/DatabaseInput";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveQuery,
    setActiveDatabase,
    setQuery,
} from "src/redux/reducers/activeQuery";
import { colors } from "src/theme";
import { Profile } from "../Profile";
import UserProfileIcon from "src/components/UserProfileIcon";
import { Query } from "src/api/generated/types";
import { JSONModal } from "src/components/modals/JSONModal";
import moment from "moment";
import { useDatabase } from "src/components/database/useDatabase";
import { DatabaseTable } from "src/components/database/Table";

export function AuditLogs() {
    const { me } = useMe();
    const { tableId, databaseId } = useParams();
    const dispatch = useDispatch();
    const { headers, rows, inputHeight, currentPage, pageSize } =
        useSelector(getActiveQuery);
    const { activeDatabase } = useDatabases();

    const [getArtifact] = useLazyQuery<Pick<Query, "getArtifact">>(
        api.artifacts.retrieve
    );
    const { queryDatabaseRows } = useDatabase();

    // this isn't a problem YET bc your custom queries cannot paginate. -> should let you tho and prob apply a default page
    const _defaultLoadTable = useCallback(async () => {
        const artifactData = await getArtifact({
            variables: {
                artifactId: tableId,
            },
            fetchPolicy: "network-only",
        });

        const table = artifactData?.data?.getArtifact ?? null;

        if (!table) return;

        const tableName = table?.name ?? "";
        const fullQuery = `SELECT * FROM "${tableName}"`;

        dispatch(setQuery(fullQuery));
        console.log(
            `[TableView] loading table ${tableId}. Query: ${fullQuery}. Current page: ${currentPage}. Page size: ${pageSize}`
        );

        void queryDatabaseRows(table, fullQuery, false, null);
    }, [tableId, currentPage, pageSize]);

    useEffect(() => {
        if (!tableId) return;
        void _defaultLoadTable();
    }, [_defaultLoadTable]);

    return (
        <div>
            <JSONModal />

            <VStack padding="1rem 1rem" paddingBottom="1rem">
                <Box
                    display="flex"
                    flexDir="column"
                    width="100%"
                    margin="auto"
                    borderRadius="0.5rem"
                    bg={colors.white}
                    alignItems="flex-start"
                    className="border border-stone-200"
                >
                    <DatabaseTable />
                </Box>
            </VStack>

            {/* <Box
                margin="0 2rem"
                padding="0.5rem 0"
                backgroundColor={colors.lightBlue50}
                borderRadius={7}
            >
                <Text>Hello</Text>
            </Box> */}
        </div>
    );
}
