import { useLazyQuery, useMutation } from "@apollo/client";
import { debounce, head, isNil } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { BaseArtifactFields } from "src/api/fragments";
import {
    DatabaseRowDelete,
    DatabaseRowInsert,
    DatabaseRowUpdate,
    Mutation,
    MutationDeleteDatabaseRowsArgs,
    MutationInsertDatabaseRowsArgs,
    MutationUpdateDatabaseArgs,
    MutationUpdateDatabaseRowsArgs,
    MutationUpdateNotebookArgs,
    Query,
    QueryGetTotalCountArgs,
} from "src/api/generated/types";
import { config } from "src/config";
import {
    DefaultErrors,
    FailureOrSuccess,
    Maybe,
    UnexpectedError,
    failure,
    success,
} from "src/core";
import { useMyToast } from "src/hooks";
import { useDatabases } from "src/hooks/useDatabases";
import {
    getActiveQuery,
    setAnalysis,
    setIsLoadingRows,
    setIsLoadingTotal,
    setLoadingAnalysis,
    setLoadingTips,
    setQuery,
    setRows,
    setRowsAndOtherInfo,
    setTips,
    setTotalCount,
    setTotalPages,
} from "src/redux/reducers/activeQuery";
import { getAuthToken } from "src/utils/firebase";

export const useDatabase = () => {
    const activeQuery = useSelector(getActiveQuery);
    const { databaseId, notebookId, tableId } = useParams();
    const currentPage = activeQuery.currentPage;
    const pageSize = activeQuery.pageSize;
    const query = activeQuery.query;

    const dispatch = useDispatch();
    const toast = useMyToast();

    // queries
    const [queryDatabaseRows, { data, client: queryDbClient }] = useLazyQuery<
        Pick<Query, "queryDatabaseRows">
    >(api.databases.actions.query);

    const [getTotal] = useLazyQuery<Pick<Query, "getTotalCount">>(
        api.databases.actions.getTotal
    );

    const [getAnalysis] = useLazyQuery<Pick<Query, "getAnalysis">>(
        api.databases.getAnalysis
    );

    const [getNotebook, { data: activeNotebookData }] = useLazyQuery<
        Pick<Query, "getNotebook">
    >(api.notebooks.retrieve);

    const [getArtifact, { data: activeTableData }] = useLazyQuery<
        Pick<Query, "getArtifact">
    >(api.artifacts.retrieve);

    // mutations
    const [updateDatabaseRows] = useMutation<
        Pick<Mutation, "updateDatabaseRows">
    >(api.databases.actions.update);

    const [deleteDatabaseRows] = useMutation<
        Pick<Mutation, "deleteDatabaseRows">
    >(api.databases.actions.delete);

    const [insertDatabaseRows] = useMutation<
        Pick<Mutation, "insertDatabaseRows">
    >(api.databases.actions.insert);

    const [updateNotebook] = useMutation<Pick<Mutation, "updateNotebook">>(
        api.notebooks.update
    );

    const info = data?.queryDatabaseRows ?? null;

    const _getAnalysis = async (databaseId: string, query: string) => {
        try {
            dispatch(setLoadingAnalysis(true));
            dispatch(setAnalysis(null));

            const analysisResponse = await getAnalysis({
                variables: {
                    databaseId: databaseId,
                    query: query,
                },
                fetchPolicy: "no-cache",
            });

            const analysis =
                analysisResponse.data?.getAnalysis?.analysis ?? null;

            dispatch(setAnalysis(analysis ?? null));
        } catch (err) {
        } finally {
            dispatch(setLoadingAnalysis(false));
        }
    };

    const _getTips = async (databaseId: string, query: string) => {
        try {
            console.log(`[useQuery] _getTips: ${query}]`);
            dispatch(setLoadingTips(true));
            dispatch(setTips(null));

            const token = await getAuthToken();

            const response = await fetch(
                `${config.apiUrl}/v1/databases/${databaseId}/tips`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token ? `Bearer ${token}` : "",
                    },
                    body: JSON.stringify({
                        query,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = response.body;

            if (!data) {
                return;
            }

            const reader = data.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let responseData = "";

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunkValue = decoder.decode(value);

                responseData += chunkValue;

                dispatch(setTips(responseData));
            }
        } catch (err) {
        } finally {
            dispatch(setLoadingTips(false));
        }
    };

    const _handleGeneratingQuery = async (
        databaseId: string,
        question: string
    ) => {
        try {
            dispatch(setQuery(null));

            const token = await getAuthToken();

            const response = await fetch(
                `${config.apiUrl}/v1/databases/${databaseId}/query`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token ? `Bearer ${token}` : "",
                    },
                    body: JSON.stringify({
                        question,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = response.body;

            if (!data) {
                return;
            }

            const reader = data.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let responseData = "";

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunkValue = decoder.decode(value);

                responseData += chunkValue;

                dispatch(setQuery(responseData));
            }
        } catch (err) {
            console.log(err);
            toast.show({
                status: "error",
                message: "Error generating query",
            });
        }
    };

    const _getAnalysisAndTips = async (databaseId: string, query: string) => {
        try {
            await Promise.all([
                _getAnalysis(databaseId, query),
                // _getTips(databaseId, query),
            ]);
        } catch (err) {
            console.error(err);
        }
    };

    const _getTotalCount = async (variables: QueryGetTotalCountArgs) => {
        dispatch(setIsLoadingTotal(true));

        try {
            // get the total count as well but do it separately
            const result = await getTotal({
                variables,
            });

            const totalCount = result.data?.getTotalCount?.totalCount ?? 0;
            const totalPages = result.data?.getTotalCount?.totalPages ?? 0;

            dispatch(setTotalCount(totalCount));
            dispatch(setTotalPages(totalPages));
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsLoadingTotal(false));
        }
    };

    const formatRows = (rows: any[]) => {
        // console.log("row update", rows);
        const formattedRows = rows.map((r: any) => {
            const obj: any = {};
            r.values.map((v: any) => (obj[v.key] = v.value));
            return obj;
        });
        return formattedRows;
    };

    const _queryDatabaseRows = async (
        table: Maybe<BaseArtifactFields>,
        query: string,
        shouldFetchMoreInfo: boolean,
        currentPageOverride: Maybe<number>
    ) => {
        if (!databaseId) return;

        const tableName = table?.name;

        if (!query && !tableName) {
            // console.log("no query or table name");
            return;
        }

        const hasLimit = query.toLowerCase().includes("limit");

        const newPageSize = hasLimit ? undefined : pageSize;
        const newCurrentPage =
            currentPageOverride ?? (hasLimit ? 0 : currentPage);

        const variables = {
            databaseId: databaseId,
            query: query,
            currentPage: newCurrentPage,
            pageSize: newPageSize,
        };

        // console.log("VARIABLES: ", variables);

        dispatch(setIsLoadingRows(true));

        try {
            // adjust this to allow aborting
            const response = await queryDatabaseRows({
                variables: variables,
                fetchPolicy: "cache-first",
            });

            const rows = formatRows(
                response.data?.queryDatabaseRows?.rows ?? []
            );
            const headers = Object.keys(rows[0] ?? {});
            const params = {
                headers,
                rows,
                lastQueriedAt: new Date().toISOString(),
            };

            dispatch(setRowsAndOtherInfo(params));

            // if not null -> load the next page into memory
            if (!isNil(newCurrentPage)) {
                const nextPage = await queryDbClient.query({
                    query: api.databases.actions.query,
                    variables: {
                        databaseId: databaseId,
                        query: query,
                        currentPage: newCurrentPage + 1,
                        pageSize: newPageSize,
                    },
                });

                // console.log("=== next page ===");
                // console.log(nextPage);
            }

            if (response.error) {
                toast.show({
                    message: response.error.message,
                    status: "error",
                });
                return;
            }

            const fixedQuery =
                response.data?.queryDatabaseRows?.fixedQuery ?? null;

            if (fixedQuery) {
                toast.show({
                    message: `We are fixing your query to be ${fixedQuery}`,
                    status: "info",
                });

                dispatch(setQuery(fixedQuery));
            }

            const queryToUser = fixedQuery ?? query;

            if (queryToUser && shouldFetchMoreInfo) {
                void _getAnalysisAndTips(databaseId, queryToUser);
            }

            void _getTotalCount(variables);
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoadingRows(false));
        }
    };

    const _updateDatabaseRows = async (
        updates: DatabaseRowUpdate[]
    ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
        if (!databaseId) return success(null);

        try {
            const variables: MutationUpdateDatabaseRowsArgs = {
                databaseId,
                updates,
            };

            const response = await updateDatabaseRows({
                variables,
                refetchQueries: [api.databases.actions.query],
            });
            return success(null);
        } catch (err) {
            return failure(new UnexpectedError(err));
        }
    };

    const _deleteDatabaseRows = async (
        deletions: DatabaseRowDelete[]
    ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
        if (!databaseId) return success(null);

        try {
            const variables: MutationDeleteDatabaseRowsArgs = {
                databaseId,
                deletions,
            };

            await deleteDatabaseRows({
                variables,
                refetchQueries: [api.databases.actions.query],
            });

            return success(null);
        } catch (err) {
            return failure(new UnexpectedError(err));
        }
    };

    const _insertDatabaseRows = async (
        insertions: DatabaseRowInsert[]
    ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
        if (!databaseId) return success(null);

        try {
            const variables: MutationInsertDatabaseRowsArgs = {
                databaseId,
                insertions,
            };

            const response = await insertDatabaseRows({
                variables,
                refetchQueries: [api.databases.actions.query],
            });

            return success(null);
        } catch (err) {
            return failure(new UnexpectedError(err));
        }
    };

    const _updateNotebook = async (
        variables: MutationUpdateNotebookArgs
    ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
        if (!databaseId) return success(null);

        try {
            await updateNotebook({
                variables,
                refetchQueries: [api.databases.actions.query],
            });

            return success(null);
        } catch (err) {
            return failure(new UnexpectedError(err));
        }
    };

    const updateNotebookDebounced = useCallback(
        debounce(_updateNotebook, 500),
        []
    );

    useEffect(() => {
        if (!notebookId) return;
        void getNotebook({
            variables: {
                notebookId: notebookId,
            },
        });
    }, [notebookId]);

    useEffect(() => {
        if (!tableId) return;
        void getArtifact({
            variables: {
                artifactId: tableId,
            },
        });
    }, [tableId]);

    // refetch the query as navigate pages
    useEffect(() => {
        if (!databaseId) return;
        if (!query) return;
        void _queryDatabaseRows(null, query, false, currentPage);
    }, [pageSize, currentPage]);

    return {
        queryDatabaseRows: _queryDatabaseRows,
        updateDatabaseRows: _updateDatabaseRows,
        deleteDatabaseRows: _deleteDatabaseRows,
        insertDatabaseRows: _insertDatabaseRows,
        getQuery: _handleGeneratingQuery,
        getTips: _getTips,
        updateNotebookDebounced,
        activeNotebook: activeNotebookData?.getNotebook ?? null,
        activeTable: activeTableData?.getArtifact ?? null,
    };
};
