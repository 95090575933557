import { Box, Container, HStack } from "@chakra-ui/react";
import React from "react";
import { colors } from "src/theme";

export type MyToastStatus = "info" | "error" | "success" | "warning";

const STATUS_TO_INFO: Record<MyToastStatus, { bg: string; iconName: string }> =
    {
        info: {
            bg: "blue.500",
            iconName: "fad fa-info-circle",
        },
        warning: {
            bg: "yellow.500",
            iconName: "fad fa-exclamation-circle",
        },
        error: {
            bg: "red.500",
            iconName: "fad fa-times-circle",
        },
        success: {
            bg: "green.500",
            iconName: "fad fa-check-circle",
        },
    };

export const MyToast = ({
    message,
    status,
}: {
    message: string | JSX.Element;
    status: MyToastStatus;
}) => {
    const info = STATUS_TO_INFO[status];

    return (
        <Container
            fontSize="md"
            marginTop="0 !important"
            bg={info.bg}
            color={colors.white}
            padding="0.5rem 1rem"
            borderRadius={5}
        >
            <HStack>
                <i
                    className={info.iconName}
                    style={{
                        position: "relative",
                        fontSize: 20,
                        margin: "0 0.25rem",
                    }}
                />
                <Box fontSize="xs">{message}</Box>
            </HStack>
        </Container>
    );
};
