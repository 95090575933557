import { combineReducers } from "@reduxjs/toolkit";
import { reducer as modalReducer } from "redux-modal";

import { userReducer } from "./user";
import { globalReducer } from "./global";
import { activeQueryReducer } from "./activeQuery";

export const reducers = combineReducers({
    user: userReducer,
    global: globalReducer,
    activeQuery: activeQueryReducer,
    modal: modalReducer,
});
