import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { api } from 'src/api';
import { Query } from 'src/api/generated/types';
import { HStack, Text } from '@chakra-ui/react';
import { colors } from 'src/theme';
import { Link } from 'react-router-dom';
import { Button } from 'src/components';

function Campaigns() {
    const { data, loading, error } = useQuery<Pick<Query, "getTeamDatabases">>(api.teams.databases);

    const databases = data?.getTeamDatabases ?? [];

    return (
        <div style={{
            padding: "1rem 2rem"
        }}>
            <HStack>
                <Text flex={1} fontSize="xl" color={colors.black}>
                    Campaigns
                </Text>
                <Link to="/dashboard/create-campaign">
                    <Button onClick={() => { }}>
                        New Campaign
                    </Button>
                </Link>
            </HStack>

            <div>
                HELLO WORLD
            </div>

        </div>
    );
}

export default Campaigns;
