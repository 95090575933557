import {
    Box,
    Button,
    Divider,
    HStack,
    Heading,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMe } from "src/hooks";
import { colors } from "src/theme";
import {
    Arch,
    downloadDesktopAppForArch,
    getOperatingSystem,
} from "src/utils/helpers";

function Download() {
    const { me } = useMe();
    const navigate = useNavigate();

    const [isSmall] = useMediaQuery("(max-width: 600px)");

    const _getDownloadLink = () => {
        return "https://updates.dbgpt.sh/download";
    };

    const downloadLink = _getDownloadLink();
    const computer = getOperatingSystem();

    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                background: colors.bg,
            }}
        >
            <HStack
                style={{
                    width: "100%",
                    paddingTop: isSmall ? "10rem" : "0",
                    flexDirection: isSmall ? "column" : "row",
                }}
            >
                <Box
                    style={{
                        flex: 1,
                        padding: isSmall ? "1.25rem" : "0",
                    }}
                >
                    <Box
                        style={{
                            maxWidth: 500,
                            width: isSmall ? "100%" : "90%",
                            margin: "auto",
                        }}
                    >
                        <Heading
                            fontWeight="bold"
                            style={{
                                color: colors.lightBlue80,
                            }}
                        >
                            {/*   <dbgpt.sh /> but work with react*/}
                            <span style={{ color: colors.white }}>
                                Download dbgpt
                            </span>
                            .sh
                        </Heading>

                        <Text
                            fontSize="md"
                            color={colors.white}
                            fontWeight={600}
                        >
                            Copilot for your Postgres database 👾
                        </Text>
                        <Text
                            fontSize="md"
                            marginTop="1rem"
                            color={colors.white}
                            fontWeight={600}
                        >
                            Only your database table schemas are shared with
                            GPT. None of your data is shared with OpenAI. Talk
                            to your database. Debug queries. Analyze your data.
                        </Text>

                        <HStack marginTop="2rem">
                            <Button
                                onClick={() =>
                                    downloadDesktopAppForArch(Arch.Arm64)
                                }
                                style={{
                                    backgroundColor: colors.white,
                                    color: colors.primary,
                                    border: `2px solid ${colors.white}`,
                                }}
                            >
                                Download for Mac (Apple Silicon){" "}
                                <i
                                    style={{ marginLeft: "10px" }}
                                    className="fas fa-download"
                                />
                            </Button>
                        </HStack>
                        <HStack marginTop="2rem">
                            <Button
                                onClick={() =>
                                    downloadDesktopAppForArch(Arch.x64)
                                }
                                style={{
                                    backgroundColor: colors.white,
                                    color: colors.primary,
                                    border: `2px solid ${colors.white}`,
                                }}
                            >
                                Download for Mac (Intel){" "}
                                <i
                                    style={{ marginLeft: "10px" }}
                                    className="fas fa-download"
                                />
                            </Button>
                        </HStack>
                    </Box>
                </Box>
            </HStack>
        </div>
    );
}

export default Download;
