import {
    EmailAuthProvider,
    fetchSignInMethodsForEmail,
    GithubAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
    UserCredential,
} from "firebase/auth";
import { auth } from "./firebase";

function google(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();

    return new Promise((resolve, reject) => {
        signInWithPopup(auth, provider)
            .then(async (result: UserCredential) => {
                resolve(result);
            })
            .catch(async (error) => {
                let errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                const credential = error.credential;

                if (
                    error.code ===
                    "auth/account-exists-with-different-credential"
                ) {
                    const methods = await fetchSignInMethodsForEmail(
                        auth,
                        error.email
                    );
                    errorMessage = "This user already has an account. ";

                    if (
                        methods.indexOf(
                            EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
                        ) != -1
                    ) {
                        errorMessage +=
                            "Please login with the email and password associated with this account. ";
                    }

                    if (
                        methods.indexOf(
                            GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD
                        ) != -1
                    ) {
                        errorMessage +=
                            "Please login with the Google account associated with this account. ";
                    }
                }
                reject({ error, message: errorMessage });
            });
    });
}
function github(): Promise<UserCredential> {
    const provider = new GithubAuthProvider();

    return new Promise((resolve, reject) => {
        signInWithPopup(auth, provider)
            .then(async (result: UserCredential) => {
                resolve(result);
            })
            .catch(async (error) => {
                let errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                const credential = error.credential;

                if (error.code === "auth/missing-identifier") {
                    errorMessage =
                        "Please enable third party cookies to login with Github.";
                }

                if (
                    error.code ===
                    "auth/account-exists-with-different-credential"
                ) {
                    const methods = await fetchSignInMethodsForEmail(
                        auth,
                        error.email
                    );
                    errorMessage = "This user already has an account. ";

                    if (
                        methods.indexOf(
                            EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
                        ) != -1
                    ) {
                        errorMessage +=
                            "Please login with the email and password associated with this account. ";
                    }

                    if (
                        methods.indexOf(
                            GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD
                        ) != -1
                    ) {
                        errorMessage +=
                            "Please login with the Google account associated with this account. ";
                    }
                }
                reject({ error, message: errorMessage });
            });
    });
}

export const ThirdPartyAuthentication = {
    google,
    github,
};
