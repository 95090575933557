import { configureStore } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    PersistConfig,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";

import { reducers } from "./reducers/reducers";
import { config } from "src/config";
import { hasValue } from "src/core";

const persistConfig: PersistConfig<any> = {
    key: "root",
    version: 1,
    storage,
    whitelist: [],
    blacklist: ["global"], // we don't want global keys persisting (ex. the selected active month)
};

const persistedReducer = persistReducer(persistConfig, reducers);
const middleware = [config.redux.logger ? logger : null].filter(hasValue);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
                ignoredPaths: ["modal"],
            },
        }).concat(...middleware),
});

export const persistor = persistStore(store);
