import { Box, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { getActiveQuery } from "src/redux/reducers/activeQuery";
import { colors } from "src/theme";

export const Analysis = () => {
    const { analysis } = useSelector(getActiveQuery);

    return (
        <Box padding="1.5rem">
            <Box
                flex={1}
                width="100%"
                style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                    paddingBottom: "0.5rem",
                    // borderBottom: `1px solid ${colors.stone200}`,
                }}
            >
                <Text fontSize="sm" flex={1} fontWeight="bold">
                    Analysis
                </Text>
                {/* <StatusTag
                    infoMessage="This feature is only available for premium users."
                    type="warning"
                    iconName="fa-solid fa-lock"
                    label="Premium"
                /> */}
            </Box>

            {analysis && <QueryInfo text={analysis} />}
        </Box>
    );
};

function QueryInfo(props: { text: string }) {
    const { text } = props;

    // Extract relevant information using regular expressions
    const planningTimeMatch = text.match(/(Planning Time: \d+\.\d+ ms)/);
    const executionTimeMatch = text.match(/(Execution Time: \d+\.\d+ ms)/);

    // remove the above from the text
    const indexScanMatch = text
        .replace(planningTimeMatch?.[0] || "", "")
        .replace(executionTimeMatch?.[0] || "", "");

    return (
        <div
            style={{
                fontFamily: "monospace",
                backgroundColor: colors.stone100,
                padding: "10px",
                fontSize: 14,
                borderRadius: 7,
            }}
        >
            <div style={{ marginBottom: "10px", fontSize: 14 }}>
                {indexScanMatch}
            </div>

            <div
                style={{
                    color: colors.green50,
                    marginBottom: "5px",
                    fontWeight: "bold",
                    fontSize: 14,
                }}
            >
                {planningTimeMatch && planningTimeMatch[0]}
            </div>
            <div
                style={{
                    color: colors.lightBlue50,
                    fontWeight: "bold",
                    fontSize: 14,
                }}
            >
                {executionTimeMatch && executionTimeMatch[0]}
            </div>
        </div>
    );
}
