import {
    Box,
    HStack,
    Spinner,
    StackProps,
    Text,
    TextProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { CSSProperties, useState } from "react";
import { colors } from "src/theme";

export function Touchable({
    iconName,
    iconClassName,
    label,
    padding,
    type,
    icon,
    iconStyle,
    labelStyle,
    iconPosition = "right",
    children,
    labelProps,
    showLoading = true,
    rightComponent,
    isLoading: _isLoadingProp,
    spinnerSize = "sm",
    flexContainerStyle,
    ...other
}: {
    iconName?: string;
    iconClassName?: string;
    icon?: JSX.Element;
    label?: string | JSX.Element;
    padding?: any;
    type?: string;
    iconPosition?: "right" | "left";
    iconStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    labelProps?: TextProps;
    isLoading?: boolean;
    rightComponent?: JSX.Element;
    showLoading?: boolean;
    spinnerSize?: string;
    flexContainerStyle?: CSSProperties;
} & StackProps) {
    const [_isLoading, _setLoading] = useState(false);
    const isLeft = iconPosition === "left";

    const _onClick = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (other.onClick) {
            _setLoading(true);
            try {
                await other.onClick(e);
            } catch (err) {
                throw err;
            } finally {
                _setLoading(false);
            }
        }
    };

    const isLoading = _isLoadingProp || _isLoading;

    return (
        <TouchableContainer
            {...other}
            onClick={_onClick}
            overflow="hidden"
            borderRadius="5px"
            padding={padding || !label ? "0.5rem" : "0.25rem 0.5rem"}
            cursor="pointer"
            style={{
                display: "inline-block",
                ...other.style,
            }}
            _hover={{
                // opacity: 0.9,
                ...other._hover,
            }}
            className={"hover:bg-stone-150 " + other.className || ""}
        >
            <HStack
                style={flexContainerStyle}
                alignItems="center"
                className={other.className}
            >
                {(isLeft ? icon : null) ||
                    (iconName && isLeft && (
                        <i
                            className={
                                iconName +
                                    " text-stone-600 text-center " +
                                    iconClassName || ""
                            }
                            style={{ fontSize: 12, width: 15, ...iconStyle }}
                        />
                    ))}

                {label && (
                    <Box flex={1}>
                        <Text
                            fontWeight="600"
                            fontSize="xs"
                            className="text-stone-600"
                            style={{ ...labelStyle, ...labelProps?.style }}
                            {...labelProps}
                        >
                            {label}
                        </Text>
                    </Box>
                )}

                {children}

                {(!isLeft ? icon : null) ||
                    (iconName && !isLeft && (
                        <i
                            className={
                                iconName + " text-stone-600 " + iconClassName ||
                                ""
                            }
                            style={{ fontSize: 12, ...iconStyle }}
                        />
                    ))}

                {rightComponent}

                {showLoading && isLoading && <Spinner size={spinnerSize} />}
            </HStack>
        </TouchableContainer>
    );
}

const TouchableContainer = styled(HStack)``;
