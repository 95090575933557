import React, { useCallback } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
    Box,
    Center,
    Text,
    Image,
    HStack,
    Divider,
    useToast,
    Checkbox,
    Heading,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "src/components";
import { colors } from "src/theme";
import {
    DefaultErrors,
    failure,
    FailureOrSuccess,
    success,
    UnexpectedError,
} from "src/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import { MutationCreateUserArgs } from "src/api/generated/types";
import {
    AuthenticationService,
    AuthenticationType,
} from "src/modules/authentication";
import { auth } from "src/utils/firebase";
import { BaseUserFields } from "src/api/fragments";
import { MyToast } from "src/components/MyToast";
import { signInWithCustomToken } from "firebase/auth";
import { GoogleButton } from "../components/Google";
import { GithubButton } from "../components/Github";
import { useMyToast } from "src/hooks";

const schema = yup.object().shape({
    fullName: yup.string().required("Full name is required.").nullable(),
    email: yup.string().email().required("Email is required.").nullable(),
    password: yup.string().min(6).required("Password is required.").nullable(),
});

type FormValues = {
    email: string;
    password: string;
    fullName: string;
};

const DEFAULT_VALUES: FormValues = {
    email: "",
    password: "",
    fullName: "",
};

export function Signup() {
    const [search] = useSearchParams();
    const toast = useMyToast();
    const navigate = useNavigate();

    // API hooks

    const [createUser] = useMutation<{
        createUser?: { user: BaseUserFields; token: string };
    }>(api.users.create);

    const [getMe] = useLazyQuery<{ me?: BaseUserFields }>(api.users.me);

    // Form hooks
    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: DEFAULT_VALUES,
    });

    // Functions
    const _logAuthError = ({ message }: { message: string }) => {
        toast.show({ message, status: "error" });
    };

    const _navigateOnSuccess = (user?: BaseUserFields) => {
        // const path = getRedirectPath(user);
        const redirect = search.get("redirect") || null;
        return navigate(redirect || "/dashboard/create-db");
    };

    const _createUser = useCallback(
        async (
            values: FormValues
        ): Promise<FailureOrSuccess<DefaultErrors, BaseUserFields>> => {
            try {
                const params: MutationCreateUserArgs = {
                    email: values.email,
                    name: values.fullName,
                    phoneNumber: null,
                    password: values.password,
                };

                const userResponse = await createUser({
                    variables: params,
                });

                if (!userResponse.data?.createUser) {
                    return failure(new Error("No user returned."));
                }

                const { token, user } = userResponse.data.createUser;

                await signInWithCustomToken(auth, token);

                return success(user);
            } catch (err) {
                return failure(new UnexpectedError(err));
            }
        },
        [createUser]
    );

    const onSubmit = useCallback(
        async (values: FormValues) => {
            const userResponse = await _createUser(values);

            if (userResponse.isFailure()) {
                return toast.show({
                    status: "error",
                    message: userResponse.error.message,
                });
            }

            return _navigateOnSuccess(userResponse.value);
        },
        [_createUser]
    );

    return (
        <NoNavBarPageTemplate>
            <HStack
                justifyContent="center"
                height="100%"
                minHeight="100vh"
                width="100vw"
                className="bg-stone-50"
            >
                <form
                    style={{
                        maxWidth: 500,
                        width: "100%",
                        padding: "1rem",
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Center
                        display="flex"
                        flexDir="column"
                        width="100%"
                        margin="auto"
                        padding="2rem"
                        borderRadius="0.5rem"
                        bg={colors.white}
                        alignItems="flex-start"
                        className="shadow-md border border-stone-200"
                    >
                        <Heading fontSize="2xl">
                            Sign up for your account
                        </Heading>
                        <br />
                        {/* <Text textAlign="left">
              So many people called taxes a nightmare that it inspired us to
              name our company Buckets.
            </Text>
            <br /> */}

                        <Box width="100%">
                            <Input
                                label="Full Name"
                                isRequired
                                control={control}
                                autoComplete="name"
                                name="fullName"
                            />
                            <Input
                                label="Email"
                                isRequired
                                autoComplete="email"
                                control={control}
                                name="email"
                            />
                            <Input
                                label="Password"
                                isRequired
                                control={control}
                                type="password"
                                name="password"
                            />

                            {/* <Checkbox>
                <Text fontSize="xs">
                  I opt-in to receiving notification emails regarding my
                  account.
                </Text>
              </Checkbox> */}
                        </Box>
                        <br />

                        <Button
                            isLoading={isSubmitting}
                            width="100%"
                            variant="primary"
                            type="submit"
                            fontSize={14}
                            padding="1rem 2rem"
                        >
                            Sign up
                        </Button>

                        <Divider margin="2rem 0" />

                        <GoogleButton
                            label="Sign up"
                            onSuccess={_navigateOnSuccess}
                            onError={_logAuthError}
                        />

                        <br />

                        <GithubButton
                            label="Sign up"
                            onSuccess={_navigateOnSuccess}
                            onError={_logAuthError}
                        />

                        <Divider margin="2rem 0" />

                        <Text>
                            Already have an account?&nbsp;
                            <Box
                                display="inline-block"
                                textDecor="underline"
                                color="black"
                            >
                                <Link to="/login">
                                    <Text>Sign in</Text>
                                </Link>
                            </Box>
                        </Text>
                    </Center>
                </form>
            </HStack>
        </NoNavBarPageTemplate>
    );
}
