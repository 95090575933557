import {
    Box,
    FormLabel,
    Input as ChakraInput,
    InputGroup,
    InputLeftElement,
    InputProps,
    Text,
} from "@chakra-ui/react";
import React, { CSSProperties } from "react";
import { colors } from "src/theme";
import { Controller, Control, UseFormRegisterReturn } from "react-hook-form";
import { isNil } from "lodash";
import { Info } from "../Info";
import { Required } from "./Required";

type MyInputProps = {
    label?: string;
    name?: string;
    hidden?: boolean;
    infoMessage?: string;
    visible?: boolean;
    control?: Control<any, any>;
    lowerText?: string | JSX.Element;
    containerStyle?: CSSProperties;
    width?: string;
    subtitle?: string | JSX.Element;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
    register?: UseFormRegisterReturn;
    iconLeft?: JSX.Element;
} & Omit<InputProps, "name">;

export const Input = ({
    containerStyle,
    infoMessage,
    register,
    iconLeft,
    ...props
}: MyInputProps) => {
    if (props.hidden) {
        return null;
    }

    if (!isNil(props.visible) && !props.visible) {
        return null;
    }

    return (
        <Box style={{ marginBottom: "1rem", ...containerStyle }}>
            {props.label && (
                <FormLabel color={colors.black} fontSize={12}>
                    {props.label} {props.isRequired && <Required />}
                    {infoMessage && <Info message={infoMessage} />}
                </FormLabel>
            )}

            {props.subtitle && (
                <Text color={colors.black} fontSize={12} marginBottom="1rem">
                    {props.subtitle}
                </Text>
            )}

            {props.control && props.name ? (
                <Controller
                    control={props.control}
                    name={props.name}
                    render={({ field: { value, onChange, onBlur, ref } }) => (
                        <InputGroup>
                            {iconLeft && (
                                <InputLeftElement
                                    style={{ padding: "0 0.1rem" }}
                                    pointerEvents="none"
                                    children={iconLeft}
                                />
                            )}
                            <ChakraInput
                                flex={1}
                                onChange={onChange}
                                onBlur={onBlur}
                                style={{ fontSize: 15, ...props.style }}
                                ref={ref}
                                value={value}
                                bg={colors.white}
                                fontSize="sm"
                                focusBorderColor={colors.primary}
                                borderColor={colors.stone200}
                                borderRadius="7px"
                                autocomplete="off"
                                data-lpignore="true"
                                data-form-type="other"
                                {...props}
                            />
                        </InputGroup>
                    )}
                />
            ) : (
                <InputGroup>
                    {iconLeft && (
                        <InputLeftElement
                            pointerEvents="none"
                            children={iconLeft}
                        />
                    )}
                    <ChakraInput
                        flex={1}
                        value={props.value}
                        focusBorderColor={colors.primary}
                        borderColor={colors.stone200}
                        borderRadius="7px"
                        bg={colors.white}
                        style={{ fontSize: 15, ...props.style }}
                        fontSize="sm"
                        autocomplete="off"
                        data-lpignore="true"
                        data-form-type="other"
                        {...register}
                        {...props}
                    />
                </InputGroup>
            )}

            {props.lowerText && (
                <Text marginTop="0.5rem" color={colors.black} fontSize={12}>
                    {props.lowerText}
                </Text>
            )}
        </Box>
    );
};
