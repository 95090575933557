import {
    Box,
    Heading,
    Modal as ChakraModal,
    ModalBody,
    ModalProps as ChakraModalProps,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    PopoverProps,
    ModalBodyProps,
    ModalContentProps,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { colors } from "src/theme";
import { ActionSheet } from "./styled";
import { CommandProps } from "./styled/ActionSheet/Command";
import { Touchable } from "./Touchable";

type ModalProps = Omit<ChakraModalProps, "isOpen" | "onClose"> & {
    children: JSX.Element | JSX.Element[];
    Footer?: JSX.Element;
    title?: string | JSX.Element;
    isVisible?: boolean;
    minW?: string;
    minH?: string;
    popoverCommands?: CommandProps[];
    modalColor?: string;
    handleHide: () => void;
    modalProps?: ModalBodyProps;
    modalContents?: ModalContentProps;
    hideClose?: boolean;
};

export const Modal = ({
    children,
    isVisible = true,
    title,
    Footer,
    minW = "20rem",
    minH,
    popoverCommands,
    modalColor,
    handleHide,
    modalProps,
    modalContents,
    hideClose = false,
    ...other
}: ModalProps) => {
    return (
        <ChakraModal
            {...other}
            blockScrollOnMount={false}
            size="lg"
            isOpen={isVisible}
            onClose={handleHide}
        >
            <ModalOverlay bgColor="rgba(0,0,0,0.25)" />
            <ModalContent
                minW={minW}
                minH={minH}
                bgColor={modalColor || colors.white}
                marginTop="2rem !important"
                style={{
                    border: "1px solid " + colors.gray2,
                    marginTop: isMobile ? 0 : "6rem",
                    ...modalContents?.style,
                }}
                {...modalContents}
            >
                {popoverCommands && (
                    <Box position="absolute" width="100%" margin="0">
                        <Box
                            width="1rem"
                            display="flex"
                            marginTop="0.55rem"
                            float="right"
                            marginRight="3.5rem"
                        >
                            <ActionSheet
                                popover={{ placement: "bottom-end" }}
                                content={{ width: 200 }}
                                commands={popoverCommands}
                            >
                                <Touchable iconName="fas fa-ellipsis-v" />
                            </ActionSheet>
                        </Box>
                    </Box>
                )}
                {title && (
                    <ModalHeader color={colors.black} fontSize={26}>
                        {title}
                    </ModalHeader>
                )}

                <ModalBody {...modalProps}>{children}</ModalBody>

                {Footer && <ModalFooter>{Footer}</ModalFooter>}
                {!hideClose && <ModalCloseButton color="black" />}
            </ModalContent>
        </ChakraModal>
    );
};
