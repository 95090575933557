import React, { useCallback } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
    Box,
    Center,
    Text,
    Image,
    HStack,
    Divider,
    useToast,
    Heading,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "src/components";
import { colors } from "src/theme";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import {
    AuthenticationService,
    AuthenticationType,
} from "src/modules/authentication";
import { BaseUserFields } from "src/api/fragments";
import { MyToast } from "src/components/MyToast";
import { useMe, useMyToast } from "src/hooks";
import { MutationCreateUserArgs } from "src/api/generated/types";
import { GoogleButton } from "../components/Google";
import { GithubButton } from "../components/Github";
import { UserCredential } from "firebase/auth";

const schema = yup.object().shape({
    email: yup.string().email().required("Email is required.").nullable(),
    password: yup.string().min(6).required("Password is required.").nullable(),
});

type FormValues = {
    email: string;
    password: string;
};

const DEFAULT_VALUES: FormValues = {
    email: "",
    password: "",
};

export function Login() {
    const toast = useMyToast();
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const [getMe] = useLazyQuery<{ me?: BaseUserFields }>(api.users.me, {
        fetchPolicy: "network-only",
    });
    // Form hooks
    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: DEFAULT_VALUES,
    });

    const onSubmit = useCallback(async (values: FormValues) => {
        const authResponse = await AuthenticationService.login({
            type: AuthenticationType.EmailAndPassword,
            email: values.email.trim().toLowerCase(),
            password: values.password,
        });

        if (authResponse.isFailure()) {
            return toast.show({
                status: "error",
                message: authResponse.error.message,
            });
        }

        try {
            const response = await getMe();

            return _navigateOnSuccess();
        } catch (err) {
            return toast.show({
                status: "error",
                message: (err as Error).message,
            });
        }
    }, []);

    const _logAuthError = ({ message }: { message: string }) => {
        toast.show({ message, status: "error" });
    };

    const _navigateOnSuccess = (user?: BaseUserFields) => {
        // const path = getRedirectPath(user);
        const redirect = search.get("redirect") || null;
        return navigate(redirect || "/dashboard");
    };

    const _navOnThirdPartySuccess = (
        user: BaseUserFields,
        firebaseUser: UserCredential,
        isNew: boolean
    ) => {
        if (isNew) {
            return navigate("/dashboard/create-db");
        }
        const redirect = search.get("redirect") || null;
        return navigate(redirect ?? "/dashboard");
    };

    return (
        <NoNavBarPageTemplate>
            <HStack
                justifyContent="center"
                height="100%"
                minHeight="100vh"
                width="100vw"
                className="bg-stone-50"
            >
                <form
                    style={{
                        maxWidth: 500,
                        width: "100%",
                        padding: "1rem",
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Box
                        display="flex"
                        flexDir="column"
                        width="100%"
                        margin="auto"
                        padding="2rem"
                        borderRadius="0.5rem"
                        bg={colors.white}
                        alignItems="flex-start"
                        className="shadow-md border border-stone-200"
                    >
                        <Heading fontSize="2xl">
                            Sign in to your account
                        </Heading>

                        <br />

                        <Box width="100%">
                            <Input
                                label="Email"
                                isRequired
                                control={control}
                                name="email"
                            />
                            <Input
                                label="Password"
                                isRequired
                                control={control}
                                type="password"
                                name="password"
                            />
                        </Box>
                        <br />
                        <Button
                            isLoading={isSubmitting}
                            width="100%"
                            variant="primary"
                            type="submit"
                            fontSize={14}
                            padding="1rem 2rem"
                        >
                            Sign in
                        </Button>

                        <Divider margin="2rem 0" />

                        <GoogleButton
                            label="Sign in"
                            onSuccess={_navOnThirdPartySuccess}
                            onError={_logAuthError}
                        />

                        <br />

                        <GithubButton
                            label="Sign in"
                            onSuccess={_navOnThirdPartySuccess}
                            onError={_logAuthError}
                        />

                        <Divider margin="2rem 0" />

                        <Text>
                            Need an account?&nbsp;
                            <Box
                                display="inline-block"
                                textDecor="underline"
                                color="black"
                            >
                                <Link to="/sign-up">
                                    <Text>Sign up</Text>
                                </Link>
                            </Box>
                        </Text>
                    </Box>
                </form>
            </HStack>
        </NoNavBarPageTemplate>
    );
}
