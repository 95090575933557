import React, {
    useMemo,
    useEffect,
    useState,
    useRef,
    useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
    CellClickedEvent,
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    CellRendererSelectorResult,
    CellValueChangedEvent,
    ColDef,
    GetContextMenuItemsParams,
    GridOptions,
    GroupCellRendererParams,
    MenuItemDef,
    ModuleRegistry,
    ValueSetterFunc,
} from "ag-grid-community";
import moment from "moment";
import { utils, writeFile } from "xlsx";

import "ag-grid-enterprise";
// import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
// import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
// import { RichSelectModule } from "@ag-grid-enterprise/rich-select";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Touchable } from "src/components/Touchable";
import { colors } from "src/theme";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveQuery,
    insertRow,
    deleteRow,
    setAdjustments,
    setCurrentPage,
    setRows,
    upsertAdjustmentsForRow,
    upsertRow,
} from "src/redux/reducers/activeQuery";
import { Box, Flex, HStack, Spinner, Text } from "@chakra-ui/react";
import { JSONModal } from "src/components/modals/JSONModal";
import { show } from "redux-modal";
import { isNil, isObject, keyBy, omit, uniqBy } from "lodash";
import numbro from "numbro";
import { Button } from "../styled";
import { DatabaseAdjustment } from "src/redux/types";
import { Maybe } from "src/core";
import { useDatabase } from "./useDatabase";
import {
    DatabaseRowDelete,
    DatabaseRowInsert,
    DatabaseRowUpdate,
} from "src/api/generated/types";
import { useMyToast } from "src/hooks";
import { v4 as uuidv4 } from "uuid";
import { compose } from "lodash/fp";

const DEFAULT_WIDTH = 300;
const EDITING_WIDTH = 400;

// Register the required feature modules with the Grid
// ModuleRegistry.registerModules([
//     // ClientSideRowModelModule,
//     RangeSelectionModule,
//     RowGroupingModule,
//     RichSelectModule,
// ]);

export function DatabaseTable() {
    const {
        headers: _headers,
        rows: _rows,
        inputHeight,
        currentPage,
        pageSize,
        isLoadingRows,
    } = useSelector(getActiveQuery);

    const [widths, setWidths] = useState<Record<string, number>>({});

    const [isTransposed, setIsTransposed] = useState(false);
    const dispatch = useDispatch();
    const { adjustments } = useSelector(getActiveQuery);
    const {
        updateDatabaseRows,
        insertDatabaseRows,
        deleteDatabaseRows,
        activeTable,
    } = useDatabase();
    const toast = useMyToast();

    const ref = useRef<AgGridReact>(null);
    const _upsertRow = compose(dispatch, upsertRow);
    const _upsertAdjustmentsForRow = compose(dispatch, upsertAdjustmentsForRow);
    const _setAdjustments = compose(dispatch, setAdjustments);
    const _showModal = compose(dispatch, show);
    const _insertRow = compose(dispatch, insertRow);
    const _deleteRow = compose(dispatch, deleteRow);
    const _setRows = compose(dispatch, setRows);

    const tableName = activeTable?.name ?? null;

    const rowPrimaryKeyField = useMemo(() => {
        const primaryKey = activeTable?.primaryKey ?? null;
        return primaryKey;
    }, [activeTable]);

    const hasPrimaryKeyHeader = useMemo(
        () => !!_headers.find((h) => h === rowPrimaryKeyField),
        [_headers, rowPrimaryKeyField]
    );

    const canEdit = !!tableName && hasPrimaryKeyHeader;

    const adjustmentByKey = useMemo(() => {
        return keyBy(
            adjustments,
            (a) => `${a.tableName}:${a.rowPrimaryKeyValue}`
        );
    }, [adjustments]);

    // never changes, so we can use useMemo
    const defaultColDef = useMemo(
        (): ColDef<any> => ({
            resizable: true,
            sortable: true,
            // editable: true,
        }),
        []
    );

    const onCellClicked = (editable: boolean) => (event: CellClickedEvent) => {
        const value = event.value;

        const isJSON = (str: string) => {
            try {
                const res = JSON.parse(str);

                return isObject(res);
            } catch (e) {
                return false;
            }
        };

        if (isJSON(value)) {
            const json = JSON.parse(value);

            _showModal("JSONModal", {
                json,
            });
        }
    };

    const onCellEditingStarted = (event: CellEditingStartedEvent) => {
        console.log(`[start editing... ${canEdit}]`);

        const colId = event.column.getColId();

        const initialWidth = event.column.getActualWidth(); // get the initial width of the column

        const newWidths = {
            ...widths,
            [colId]: initialWidth,
        };

        setWidths(newWidths);

        event.column.setActualWidth(EDITING_WIDTH); // set the width you want
        event.api.refreshHeader();
    };

    const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
        console.log(`[stopped editing... ${canEdit}]`);

        const colId = event.column.getColId();
        const initialWidth = widths[colId];

        if (!event.valueChanged) {
            // not using -> caused issues
            console.log(`initial width: `, initialWidth);
            event.column.setActualWidth(initialWidth);
            event.api.refreshHeader();
        }
    };

    const onValueSet: ValueSetterFunc = (params) => {
        const field = params.colDef.field;
        const value = params.newValue;

        if (!tableName) return false;
        if (!params.node) return false;
        if (!rowPrimaryKeyField) return false;
        if (!field) return false;

        const row = params.node.data;
        const hasPrimaryKey = isTransposed
            ? rows.find((r) => r.header === rowPrimaryKeyField)
            : _headers.find((h) => h === rowPrimaryKeyField);

        if (!hasPrimaryKey) return false;

        const colId = params.colDef.field || "";
        const primaryKeyValue = isTransposed
            ? rows.find((r) => r.header === rowPrimaryKeyField)[colId]
            : params.data[rowPrimaryKeyField];

        console.log(`${rowPrimaryKeyField}: `, primaryKeyValue);

        if (!primaryKeyValue || !primaryKeyValue.length) return false;

        const currentAdjustment: Maybe<DatabaseAdjustment> =
            adjustmentByKey[`${tableName}:${primaryKeyValue}`] ?? null;

        // if the row is inserted, just re-write it in place
        if (row.__isInsertedRow) {
            const newData = {
                ...row,
                [field]: value,
            };

            _upsertRow({
                newData,
                field: rowPrimaryKeyField,
                value: primaryKeyValue,
            });

            return true;
        }

        // console.log("current: ", currentAdjustment);
        // console.log("params: ", params);

        // merge the edits
        const newEdits: DatabaseAdjustment["edits"] = [
            // remove this field from the edits if it is there
            ...(currentAdjustment?.edits ?? []).filter(
                (e) => e.field !== field
            ),
            {
                field: field!,
                value: value,
            },
        ];

        const newAdjustment: DatabaseAdjustment = {
            tableName: tableName,
            rowPrimaryKeyValue: primaryKeyValue,
            rowIndex: params.node.rowIndex ?? 0,
            rowPrimaryKeyField: rowPrimaryKeyField,
            type: "update",
            edits: newEdits,
        };

        // console.log("new adj: ", newAdjustment);

        _upsertAdjustmentsForRow(newAdjustment);

        return true;
    };

    const _getKey = (row: any, rowPrimaryKeyField: Maybe<string>) =>
        `${tableName}:${row[rowPrimaryKeyField || ""]}`;

    const rows = useMemo(() => {
        const startingNumber = (currentPage ?? 0) * pageSize;

        const rows = isTransposed ? transposeData(_rows) : [..._rows];

        return rows.map((r, index) => {
            const newRow = { ...r };

            newRow.index = startingNumber + (index + 1);
            const adjustmentKey = _getKey(r, rowPrimaryKeyField);

            const adjustments = adjustmentByKey[adjustmentKey] ?? null;

            if (adjustments && adjustments.edits?.length) {
                newRow.__hasAdjustments = true;
                adjustments.edits.forEach((e) => {
                    newRow[e.field] = e.value;
                });
            }
            return newRow;
        });
    }, [_rows, isTransposed, adjustmentByKey, pageSize]);

    const columns = useMemo((): ColDef[] => {
        if (isTransposed) {
            const columnDefs: ColDef[] = [
                {
                    field: "header",
                    headerName: "",
                    width: 250,
                    valueFormatter: (params) => _formatHeader(params.value),
                    pinned: "left",
                    cellClass: "header-transposed",
                    cellStyle: {
                        fontWeight: "600",
                    },
                },
                ...rows.map(
                    (row, idx): ColDef => ({
                        field: _getRowIdx(idx),
                        headerName: `Row ${idx + 1}`,
                        width: DEFAULT_WIDTH,
                        editable: canEdit,
                        valueSetter: onValueSet,
                        onCellClicked: onCellClicked(canEdit),
                        cellStyle: {
                            backgroundColor: _getBGColor(row),
                        },
                    })
                ),
            ];

            // console.log(columnDefs);

            return columnDefs;
        }

        return [
            {
                field: "index",
                editable: false,
                headerName: "",
                sortable: false,
                width: 50,
                cellStyle: {
                    textAlign: "center",
                    padding: 0,
                    // backgroundColor: colors.white,
                    fontSize: 12,
                    borderRight: `1px solid ${colors.stone200}`,
                    color: colors.gray50,
                },
            },
            ..._headers.map(
                (h: string): ColDef => ({
                    field: h,
                    editable: canEdit,
                    headerName: _formatHeader(h),
                    valueGetter: (params) => params.data[h],
                    valueSetter: onValueSet,
                    onCellClicked: onCellClicked(canEdit),
                    cellStyle: ({ data: row }) => ({
                        padding: 0,
                        fontSize: 13,
                        backgroundColor: _getBGColor(row),
                        borderRight: `1px solid ${colors.stone200}`,
                    }),
                })
            ),
        ];
    }, [rows, _headers, isTransposed]);

    const scrollToTopOnInsert = () => {
        if (!ref.current?.api) return;

        // const lastRowIndex = ref.current.api.getModel().getRowCount(); // one more than the bottom

        setTimeout(() => {
            ref.current?.api.ensureIndexVisible(0, "top"); // 'bottom' aligns the row to the bottom of the grid viewport
        }, 0);
        // console.log(`[scroll to bottom]`);
    };

    const _download = (rows: any[], headers: string[]) => {
        // Create a workbook object
        const wb = utils.book_new();

        // Convert data to worksheet
        const wsData = [
            headers,
            ...rows.map((row) => headers.map((h) => row[h])),
        ];

        const ws = utils.aoa_to_sheet(wsData);
        const fileName = `${tableName || ""} ${moment().format("YYYY-MM-DD")}`;

        // Add worksheet to workbook
        utils.book_append_sheet(wb, ws, fileName);

        // Write workbook to file
        writeFile(wb, `${fileName.toLowerCase()}.xlsx`);
    };

    const _onSaveUpdates = async () => {
        const updateAdjustments = adjustments.filter(
            (a) => a.type === "update"
        );

        const updates: DatabaseRowUpdate[] = updateAdjustments.map((a) => ({
            edits: a.edits,
            primaryKeyField: a.rowPrimaryKeyField,
            primaryKeyValue: a.rowPrimaryKeyValue,
            table: a.tableName,
        }));

        const insertions: DatabaseRowInsert[] = _rows
            .filter((r) => r.__isInsertedRow)
            .map((r) => {
                const data = omit(r, [
                    "__isInsertedRow",
                    "__isDeletedRow",
                    "index",
                ]);

                return {
                    table: tableName!,
                    data: JSON.stringify(data),
                };
            });

        const deletions: DatabaseRowDelete[] = _rows
            .filter((r) => r.__isDeletedRow)
            .map((r) => ({
                table: tableName!,
                primaryKeyField: rowPrimaryKeyField!,
                primaryKeyValue: r[rowPrimaryKeyField || ""],
            }));

        if (updates.length) {
            const response = await updateDatabaseRows(updates);

            if (response.isFailure()) {
                toast.show({
                    message: response.error?.message || "Error updating rows",
                    status: "error",
                });
                return;
            }
        }

        if (deletions.length) {
            const response = await deleteDatabaseRows(deletions);

            if (response.isFailure()) {
                toast.show({
                    message: response.error?.message || "Error updating rows",
                    status: "error",
                });
                return;
            }
        }

        if (insertions.length) {
            const response = await insertDatabaseRows(insertions);

            if (response.isFailure()) {
                toast.show({
                    message: response.error?.message || "Error updating rows",
                    status: "error",
                });
                return;
            }
        }

        _setAdjustments([]);

        toast.show({
            message: "Successfully saved changes.",
            status: "success",
        });
        // Note: don't need to delete or add any rows bc those will be done on the query refetch
    };

    const _onInsertRow = (_data?: any) => {
        if (!rowPrimaryKeyField) return;

        const id = uuidv4();

        const row = {
            ..._data,
            [rowPrimaryKeyField]: id,
            __isInsertedRow: true,
        };

        _insertRow(row);
        scrollToTopOnInsert();
    };

    const _onDeleteRow = (field: string, value: string) => {
        _deleteRow({ field, value });
    };

    const getContextMenuItems: GridOptions["getContextMenuItems"] = useCallback(
        (params: GetContextMenuItemsParams): MenuItemDef[] => {
            const result: MenuItemDef[] = [
                {
                    // Display name for the option
                    name: "Copy value",
                    // Action to be executed when this option is selected
                    action: function () {
                        const data = params.node?.data;

                        const colId = params.column?.getColId();
                        const value = data[colId || ""];

                        if (!value) return;

                        navigator.clipboard.writeText(value);

                        toast.show({
                            message: "Copied to clipboard",
                            status: "success",
                        });
                    },
                    icon: `<i style="color: ${colors.gray20}" class="fa-solid fa-copy" />`,
                },
                {
                    // Display name for the option
                    name: "Duplicate row",
                    // Action to be executed when this option is selected
                    action: function () {
                        const data = params.node?.data;

                        _onInsertRow(data);
                    },
                    icon: `<i style="color: ${colors.gray20}" class="fa-solid fa-clone" />`,
                },
                {
                    // Display name for the option
                    name: "Insert new row",
                    // Action to be executed when this option is selected
                    action: function () {
                        const data = params.node?.data;

                        // build an empty object from the headers
                        const emptyObj = _headers.reduce<any>((acc, h) => {
                            acc[h] = "";
                            return acc;
                        }, {});

                        _onInsertRow(emptyObj);
                    },
                    icon: `<i style="color: ${colors.gray20}" class="fa-solid fa-plus" />`,
                },
                {
                    name: "Delete row",
                    action: function () {
                        const data = params.node?.data;
                        const primaryKeyValue = data[rowPrimaryKeyField ?? ""];

                        console.log(rowPrimaryKeyField, primaryKeyValue);
                        if (!rowPrimaryKeyField) return;

                        _onDeleteRow(rowPrimaryKeyField, primaryKeyValue);
                    },
                    icon: `<i style="color: ${colors.red50}" class="fa-solid fa-trash" />`,
                },
            ];
            return result;
        },
        [_headers, rowPrimaryKeyField, _onDeleteRow]
    );

    const _clearEdits = () => {
        _setAdjustments([]);

        // remove the inserted ones
        const newRows = _rows
            .filter((r) => {
                return !r.__isInsertedRow;
            })
            .map((r) => omit(r, ["__isInsertedRow", "__isDeletedRow"]));

        _setRows(newRows);
    };

    // useEffect(() => {
    //     console.log("[refresh]");
    //     ref.current?.api?.refreshCells();
    // }, [rows, columns, rowPrimaryKeyField]);

    const possibleEdits = _rows.filter(
        (r) => r.__isInsertedRow || r.__isDeletedRow
    );
    const rowCount = adjustments.length + possibleEdits.length;

    const maxTableHeight = `calc(70vh - ${inputHeight ?? 0}px - 25px)`;
    const tableHeight = `calc(${rows.length * 30}px + 39px)`;
    const isLoading = isLoadingRows;

    console.log("rows: ", rows.length);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <Box
                justifyContent="center"
                // make the height just the size of the rows
                maxH={maxTableHeight}
                height={isLoading ? maxTableHeight : tableHeight}
                className="bg-white"
                borderTop={`1px solid ${colors.stone200}`}
                overflow="hidden"
            >
                {isLoading ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <Spinner marginRight="1rem" size="sm" />{" "}
                        <Text fontSize="sm">
                            Loading{tableName ? " " + tableName + " " : " "}
                            rows...
                        </Text>
                    </div>
                ) : (
                    <AgGridReact
                        className={
                            "ag-theme-alpine " +
                            (isTransposed ? "ag-transposed" : "")
                        }
                        onCellEditingStarted={onCellEditingStarted}
                        onCellEditingStopped={onCellEditingStopped}
                        rowHeight={30}
                        getContextMenuItems={getContextMenuItems}
                        animateRows={true}
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                        enableRangeSelection={true}
                        rowData={rows}
                        resetRowDataOnUpdate
                        getRowId={({ data }) =>
                            data[rowPrimaryKeyField || ""] ||
                            Math.random().toString()
                        }
                        ref={ref}
                        suppressScrollOnNewData
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                    />
                )}
            </Box>

            <HStack
                padding="0.75rem"
                borderTop={`1px solid ${colors.stone200}`}
            >
                <Paginate />

                <Flex flex={1} />
                {/* download touchable */}
                {rowCount > 0 && (
                    <>
                        <Touchable
                            onClick={_clearEdits}
                            label="Clear Edits"
                            iconPosition="right"
                            iconName="fas fa-times"
                        />

                        <Button
                            style={{
                                backgroundColor: colors.primary,
                                color: colors.white,
                                borderRadius: 7,
                                padding: "0.35rem 0.5rem",
                                height: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: `1px solid ${colors.lightBlue30}`,
                            }}
                            onClick={_onSaveUpdates}
                        >
                            <HStack alignItems="center" justifyContent="center">
                                <Text fontSize="xs" marginLeft="5px">
                                    Save Changes ({rowCount})
                                </Text>
                                <i
                                    style={{ fontSize: 12 }}
                                    className="fas fa-save"
                                />
                            </HStack>
                        </Button>
                    </>
                )}

                {/* <Touchable
                    onClick={() => setIsTransposed(!isTransposed)}
                    label={isTransposed ? "Untranspose" : "Transpose"}
                    iconPosition="right"
                    iconName={
                        isTransposed
                            ? "fa-solid fa-undo"
                            : "fa-solid fa-table-pivot"
                    }
                /> */}

                <Touchable
                    onClick={() => _download(_rows, _headers)}
                    label="Download"
                    iconPosition="right"
                    iconName="fas fa-download"
                />
            </HStack>
        </div>
    );
}

const _formatHeader = (h: string) => {
    return h?.toLowerCase();
    // return (h || "")
    //     .split("_")
    //     .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    //     .join(" ");
};

const _getRowIdx = (rIdx: number) => `value_${rIdx}`;

const transposeData = (data: any[]) => {
    if (!data || !data.length) return [];

    const transposed: any[] = [];
    const keys = Object.keys(data[0]);

    keys.forEach((key, idx) => {
        const newRow: any = { header: key };
        data.forEach((row, rIdx) => {
            newRow[_getRowIdx(rIdx)] = row[key];
        });
        transposed.push(newRow);
    });

    return transposed;
};

const Paginate = () => {
    const dispatch = useDispatch();
    const activeQuery = useSelector(getActiveQuery);
    const totalPages = activeQuery?.totalPages ?? 0;
    const currentPage = activeQuery?.currentPage ?? 0;

    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === totalPages - 1;

    const _setPage = (newPage: number) => {
        dispatch(setCurrentPage(newPage));
    };

    const current = Math.min(currentPage + 1, totalPages);

    return (
        <HStack>
            <Touchable
                style={{
                    opacity: isFirstPage ? 0.5 : 1,
                    cursor: isFirstPage ? "not-allowed" : "pointer",
                }}
                margin="0 !important"
                iconName="fas fa-chevrons-left"
                onClick={() => _setPage(0)}
            />
            <Touchable
                style={{
                    opacity: isFirstPage ? 0.5 : 1,
                    cursor: isFirstPage ? "not-allowed" : "pointer",
                }}
                onClick={() => _setPage(Math.max(0, currentPage - 1))}
                margin="0 !important"
                iconName="fas fa-chevron-left"
            />
            <Text
                margin="0 0.5rem !important"
                fontSize="xs"
                color={colors.stone400}
            >
                {numbro(current).format("0,0")} of{" "}
                {numbro(totalPages).format("0,0")}
            </Text>
            <Touchable
                style={{
                    opacity: isLastPage ? 0.5 : 1,
                    cursor: isLastPage ? "not-allowed" : "pointer",
                }}
                margin="0 !important"
                onClick={() =>
                    _setPage(Math.min(totalPages - 1, currentPage + 1))
                }
                iconName="fas fa-chevron-right"
            />
            <Touchable
                style={{
                    opacity: isLastPage ? 0.5 : 1,
                    cursor: isLastPage ? "not-allowed" : "pointer",
                }}
                onClick={() => _setPage(totalPages - 1)}
                margin="0 !important"
                iconName="fas fa-chevrons-right"
            />
        </HStack>
    );
};

const _getBGColor = (row: any) =>
    row.__isInsertedRow
        ? colors.green200
        : row.__isDeletedRow
        ? colors.red200
        : row.__hasAdjustments
        ? colors.lightBlue100
        : colors.white;
