import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { AuthStatus, ReduxState, GlobalState } from "../types";

// initial state
const initialState: GlobalState = {
    theme: "light",
    commandIsOpen: false,
    activeMonth: new Date().getMonth(),
    activeYear: new Date().getFullYear(),
};

// actions
export const setCommandIsOpen = createAction<boolean>("SET_COMMAND_IS_OPEN");
export const setActiveMonth = createAction<number>("SET_ACTIVE_MONTH"); // indexed at 0
export const setActiveYear = createAction<number>("SET_ACTIVE_YEAR");
export const setTheme = createAction<"light" | "dark">("SET_THEME");

// reducer
export const globalReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setCommandIsOpen, (state, action) => {
            state.commandIsOpen = action.payload;
        })
        .addCase(setActiveMonth, (state, action) => {
            state.activeMonth = action.payload;
        })
        .addCase(setActiveYear, (state, action) => {
            state.activeYear = action.payload;
        })
        .addCase(setTheme, (state, action) => {
            state.theme = action.payload;
        });
});

// selectors
export const getCommandIsOpen = (state: ReduxState): boolean =>
    state.global.commandIsOpen;
export const getActiveYear = (state: ReduxState): number =>
    state.global.activeYear;
export const getActiveMonth = (state: ReduxState): number =>
    state.global.activeMonth;
export const getActiveDate = createSelector(
    [getActiveMonth, getActiveYear],
    // have to add one bc we store at 0 index
    (m, y) =>
        DateTime.now()
            .set({ year: y, month: m + 1 })
            .startOf("month")
);
export const getTheme = (state: ReduxState): "light" | "dark" =>
    state.global.theme;
