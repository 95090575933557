import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    DefaultOptions,
} from "@apollo/client";
import { config } from "src/config";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { getAuthToken } from "src/utils/firebase";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";

const uri = `${config.apiUrl}/graphql`;

const defaultOptions: DefaultOptions = {
    watchQuery: {
        errorPolicy: "all",
    },
    query: {},
};

const cache = new InMemoryCache();

persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
});

const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 3,
        retryIf: (error) => !!error,
    },
});

const httpLink = createHttpLink({
    uri,
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getAuthToken();

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

export const apolloClient = new ApolloClient({
    // does auth link and then after does http request
    link: retryLink.concat(authLink).concat(httpLink),
    cache,
    defaultOptions,
});
