import { gql } from "@apollo/client";
import {
    BaseArtifactFields,
    BaseDatabaseFields,
    BaseNotebookFields,
    BaseTeamMemberFields,
    BaseUserFields,
} from "./fragments";

// Cache stuff: https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies

// ==== users ==== //
const CreateUser = gql`
    ${BaseUserFields}
    mutation CreateUser(
        $name: String!
        $email: String!
        $password: String
        $phoneNumber: String
    ) {
        createUser(
            name: $name
            email: $email
            password: $password
            phoneNumber: $phoneNumber
        ) {
            user {
                ...BaseUserFields
            }
            token
        }
    }
`;

const GetMe = gql`
    ${BaseUserFields}
    query GetMe {
        me {
            ...BaseUserFields
        }
    }
`;

const CreateDatabase = gql`
    ${BaseDatabaseFields}
    mutation CreateDatabase(
        $provider: DatabaseProviderEnum!
        $name: String
        $connectionUri: String!
        $isSSL: Boolean
    ) {
        createDatabase(
            provider: $provider
            name: $name
            connectionUri: $connectionUri
            isSSL: $isSSL
        ) {
            ...BaseDatabaseFields
        }
    }
`;

const UpdateDatabase = gql`
    ${BaseDatabaseFields}
    mutation UpdateDatabase(
        $databaseId: ID!
        $name: String
        $connectionUri: String
        $isSSL: Boolean
    ) {
        updateDatabase(
            databaseId: $databaseId
            name: $name
            connectionUri: $connectionUri
            isSSL: $isSSL
        ) {
            ...BaseDatabaseFields
        }
    }
`;

const SetActiveDatabase = gql`
    ${BaseDatabaseFields}
    mutation SetActiveDatabase($databaseId: ID!) {
        setActiveDatabase(databaseId: $databaseId) {
            ...BaseDatabaseFields
        }
    }
`;

const syncDatabase = gql`
    ${BaseDatabaseFields}
    mutation syncDatabase($databaseId: ID!) {
        syncDatabase(databaseId: $databaseId) {
            ...BaseDatabaseFields
        }
    }
`;

// moved to the normal REST API
// const GenerateQuery = gql`
//     ${BaseArtifactFields}
//     mutation GenerateQuery($databaseId: ID!, $question: String!) {
//         generateQuery(databaseId: $databaseId, question: $question) {
//             query
//             artifacts {
//                 ...BaseArtifactFields
//             }
//         }
//     }
// `;

const QueryDatabaseRows = gql`
    query QueryDatabaseRows(
        $databaseId: ID!
        $query: String!
        $pageSize: Int
        $currentPage: Int
    ) {
        queryDatabaseRows(
            databaseId: $databaseId
            query: $query
            pageSize: $pageSize
            currentPage: $currentPage
        ) {
            fixedQuery
            rows {
                index
                values {
                    key
                    value
                }
            }
            count
            page
        }
    }
`;

const GetTotalCount = gql`
    query GetTotalCount(
        $databaseId: ID!
        $query: String!
        $pageSize: Int
        $currentPage: Int
    ) {
        getTotalCount(
            databaseId: $databaseId
            query: $query
            pageSize: $pageSize
            currentPage: $currentPage
        ) {
            totalCount
            totalPages
        }
    }
`;

const UpdateDatabaseRows = gql`
    mutation UpdateDatabaseRows(
        $databaseId: ID!
        $updates: [DatabaseRowUpdate!]!
    ) {
        updateDatabaseRows(databaseId: $databaseId, updates: $updates) {
            message
            audit
        }
    }
`;

const DeleteDatabaseRows = gql`
    mutation DeleteDatabaseRows(
        $databaseId: ID!
        $deletions: [DatabaseRowDelete!]!
    ) {
        deleteDatabaseRows(databaseId: $databaseId, deletions: $deletions) {
            message
            audit
        }
    }
`;

const InsertDatabaseRows = gql`
    mutation InsertDatabaseRows(
        $databaseId: ID!
        $insertions: [DatabaseRowInsert!]!
    ) {
        insertDatabaseRows(databaseId: $databaseId, insertions: $insertions) {
            message
            audit
        }
    }
`;

const FixQuery = gql`
    query FixQuery($databaseId: ID!, $query: String!, $problem: String!) {
        fixQuery(databaseId: $databaseId, query: $query, problem: $problem) {
            fixedQuery
        }
    }
`;

const GetAnalysis = gql`
    query GetAnalysis($databaseId: ID!, $query: String!) {
        getAnalysis(databaseId: $databaseId, query: $query) {
            analysis
        }
    }
`;

const GetTips = gql`
    query GetTips($databaseId: ID!, $query: String!) {
        getTips(databaseId: $databaseId, query: $query) {
            tips
        }
    }
`;

const GetConnectionUri = gql`
    query GetConnectionUri($databaseId: ID!) {
        getConnectionUri(databaseId: $databaseId)
    }
`;

const GetArtifact = gql`
    ${BaseArtifactFields}
    query GetArtifact($artifactId: ID!) {
        getArtifact(artifactId: $artifactId) {
            ...BaseArtifactFields
        }
    }
`;

const GetNotebook = gql`
    ${BaseNotebookFields}
    query GetNotebook($notebookId: ID!) {
        getNotebook(notebookId: $notebookId) {
            ...BaseNotebookFields
        }
    }
`;

const GetTeamDatabases = gql`
    ${BaseDatabaseFields}
    query GetTeamDatabases {
        getTeamDatabases {
            ...BaseDatabaseFields
        }
    }
`;


const GetActiveTeamMembers = gql`
    ${BaseTeamMemberFields}
    query GetActiveTeamMembers {
        getActiveTeamMembers {
            ...BaseTeamMemberFields
        }
    }
`;


const GetDatabase = gql`
    ${BaseDatabaseFields}
    query GetDatabase($databaseId: ID!) {
        getDatabase(databaseId: $databaseId) {
            ...BaseDatabaseFields
        }
    }
`;

const GetDatabaseArtifacts = gql`
    ${BaseArtifactFields}
    query GetDatabaseArtifacts($databaseId: ID!) {
        getDatabaseArtifacts(databaseId: $databaseId) {
            ...BaseArtifactFields
        }
    }
`;

const GetDatabaseNotebooks = gql`
    ${BaseNotebookFields}
    query GetDatabaseNotebooks($databaseId: ID!) {
        getDatabaseNotebooks(databaseId: $databaseId) {
            ...BaseNotebookFields
        }
    }
`;

const CreateNotebook = gql`
    ${BaseNotebookFields}
    mutation CreateNotebook(
        $name: String
        $query: String!
        $description: String
        $databaseId: ID!
    ) {
        createNotebook(
            name: $name
            query: $query
            description: $description
            databaseId: $databaseId
        ) {
            ...BaseNotebookFields
        }
    }
`;

const UpdateNotebook = gql`
    ${BaseNotebookFields}
    mutation UpdateNotebook(
        $notebookId: ID!
        $name: String
        $query: String
        $question: String
        $isHidden: Boolean
        $annotations: String
        $title: String
    ) {
        updateNotebook(
            name: $name
            query: $query
            notebookId: $notebookId
            question: $question
            isHidden: $isHidden
            annotations: $annotations
            title: $title
        ) {
            ...BaseNotebookFields
        }
    }
`;

export const api = {
    users: {
        create: CreateUser,
        me: GetMe,
    },
    teams: {
        members: GetActiveTeamMembers,
        databases: GetTeamDatabases,
    },
    databases: {
        create: CreateDatabase,
        update: UpdateDatabase,
        retrieve: GetDatabase,
        artifacts: GetDatabaseArtifacts,
        setActive: SetActiveDatabase,
        getAnalysis: GetAnalysis,
        getTips: GetTips,
        fixQuery: FixQuery,
        getConnectionUri: GetConnectionUri,
        notebooks: GetDatabaseNotebooks,
        sync: syncDatabase,
        actions: {
            query: QueryDatabaseRows,
            getTotal: GetTotalCount,
            delete: DeleteDatabaseRows,
            update: UpdateDatabaseRows,
            insert: InsertDatabaseRows,
        },
    },
    artifacts: {
        retrieve: GetArtifact,
    },
    notebooks: {
        create: CreateNotebook,
        update: UpdateNotebook,
        retrieve: GetNotebook,
    },
};
