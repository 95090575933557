import { createAction, createReducer } from "@reduxjs/toolkit";
import { ReduxState, ActiveQuery, DatabaseAdjustment } from "../types";
import { Maybe, hasValue } from "src/core";
import { BaseArtifactFields, BaseDatabaseFields } from "src/api/fragments";
import { fork } from "radash";

// initial state
const initialState: ActiveQuery = {
    question: "",
    analysis: null,
    tips: null,
    query: null,
    rows: [],
    headers: [],
    inputHeight: null,
    isLoadingAnalysis: false,
    isLoadingTips: false,
    isLoadingRows: false,
    isLoadingTotal: false,
    activeDatabase: null,
    lastQueriedAt: null,
    totalCount: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: 25,
    adjustments: [],
};

// actions
export const setQuestion = createAction<string>("SET_QUESTION");
export const setAnalysis = createAction<string | null>("SET_ANALYSIS");
export const setTips = createAction<string | null>("SET_TIPS");
export const setQuery = createAction<string | null>("SET_QUERY");
export const setRowsAndOtherInfo = createAction<
    Pick<ActiveQuery, "rows" | "lastQueriedAt" | "headers">
>("SET_ROWS_AND_OTHER_INFO");
export const setRows = createAction<any[]>("SET_ROWS");
export const insertRow = createAction<any[]>("INSERT_ROW");
export const upsertRow = createAction<{
    newData: any;
    field: string;
    value: string;
}>("UPSERT_ROW");
export const deleteRow = createAction<{ field: string; value: string }>(
    "MARK_ROW_AS_DELETED"
);
export const setInputHeight = createAction<Maybe<number>>("SET_INPUT_HEIGHT");
export const setLoadingAnalysis = createAction<boolean>("SET_LOADING_ANALYSIS");
export const setLoadingTips = createAction<boolean>("SET_LOADING_TIPS");
export const setActiveDatabase = createAction<Maybe<BaseDatabaseFields>>(
    "SET_ACTIVE_DATABASE"
);
export const setCurrentPage = createAction<Maybe<number>>("SET_CURRENT_PAGE");
export const upsertAdjustmentsForRow = createAction<DatabaseAdjustment>(
    "UPSERT_ADJUSTMENTS_FOR_ROW"
);
export const setAdjustments =
    createAction<DatabaseAdjustment[]>("SET_ADJUSTMENTS");

export const setPageSize = createAction<number>("SET_PAGE_SIZE");
export const setIsLoadingRows = createAction<boolean>("SET_IS_LOADING_ROWS");
export const setTotalCount = createAction<number>("SET_TOTAL_COUNT");
export const setTotalPages = createAction<number>("SET_TOTAL_PAGES");
export const setIsLoadingTotal = createAction<boolean>("SET_IS_LOADING_TOTAL");

// reducer
export const activeQueryReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setQuestion, (state, action) => {
            state.question = action.payload;
        })
        .addCase(setAnalysis, (state, action) => {
            state.analysis = action.payload;
        })
        .addCase(setTips, (state, action) => {
            state.tips = action.payload;
        })
        .addCase(setQuery, (state, action) => {
            state.query = action.payload;
        })
        .addCase(setRows, (state, action) => {
            state.rows = action.payload;
        })
        .addCase(setPageSize, (state, action) => {
            state.pageSize = action.payload;
        })
        .addCase(setRowsAndOtherInfo, (state, action) => {
            state.rows = action.payload.rows;
            state.headers = action.payload.headers;
            state.lastQueriedAt = action.payload.lastQueriedAt ?? null;
        })
        .addCase(insertRow, (state, action) => {
            state.rows = [
                {
                    ...action.payload,
                    __isInsertedRow: true,
                },
                ...state.rows,
            ];
        })
        .addCase(upsertRow, (state, action) => {
            if (!action.payload.newData) return;

            const exists = state.rows.find((row) => {
                return row[action.payload.field] === action.payload.value;
            });

            if (!exists) {
                state.rows = [...state.rows, action.payload.newData];

                return;
            }

            state.rows = state.rows.map((row) => {
                if (row[action.payload.field] === action.payload.value) {
                    return {
                        ...action.payload.newData,
                    };
                }

                return row;
            });
        })
        .addCase(deleteRow, (state, action) => {
            state.rows = state.rows
                .map((row) => {
                    if (row[action.payload.field] === action.payload.value) {
                        // if it is an inserted row -> we want to prune it out
                        if (row.__isInsertedRow) {
                            return null;
                        }

                        return {
                            ...row,
                            __isDeletedRow: true,
                        };
                    }

                    return row;
                })
                .filter(hasValue);
        })
        .addCase(setIsLoadingRows, (state, action) => {
            state.isLoadingRows = action.payload;
        })
        .addCase(setInputHeight, (state, action) => {
            state.inputHeight = action.payload;
        })
        .addCase(setLoadingAnalysis, (state, action) => {
            state.isLoadingAnalysis = action.payload;
        })
        .addCase(setLoadingTips, (state, action) => {
            state.isLoadingTips = action.payload;
        })
        .addCase(setActiveDatabase, (state, action) => {
            state.activeDatabase = action.payload;
        })
        .addCase(setCurrentPage, (state, action) => {
            state.currentPage = action.payload;
        })
        .addCase(setAdjustments, (state, action) => {
            state.adjustments = action.payload;
        })
        .addCase(upsertAdjustmentsForRow, (state, action) => {
            const [adjustment, other] = fork(state.adjustments, (a) => {
                return (
                    a.rowPrimaryKeyValue ===
                        action.payload.rowPrimaryKeyValue &&
                    a.tableName === action.payload.tableName
                );
            });

            if (adjustment) {
                state.adjustments = [...other, action.payload];

                return;
            }

            state.adjustments = [...state.adjustments, action.payload];
        })
        .addCase(setTotalCount, (state, action) => {
            state.totalCount = action.payload;
        })
        .addCase(setTotalPages, (state, action) => {
            state.totalPages = action.payload;
        })
        .addCase(setIsLoadingTotal, (state, action) => {
            state.isLoadingTotal = action.payload;
        });
});

// selectors
export const getActiveQuery = (state: ReduxState): ActiveQuery =>
    state.activeQuery;
