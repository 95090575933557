import styled from "@emotion/styled";
import { colors } from "src/theme";

export const PageContainer = styled.div`
    background: ${colors.white};
`;

export const PageBody = styled.div`
    margin: auto;
    padding-bottom: 150px;
    padding: 0;
    padding-bottom: 100px;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    height: 100vh;
    width: 100%;
    @media (max-width: 800px) {
        margin-top: 2rem;
    }
`;
