import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from "@chakra-ui/react";
import React, { CSSProperties, Fragment, useCallback, useMemo } from "react";
import { colors } from "src/theme";
import { Controller, Control } from "react-hook-form";
import { isArray, isEqual, isNil, noop } from "lodash";
import { Info } from "../Info";
import ReactSelect, {
    GroupBase,
    MultiValue,
    OptionsOrGroups,
    PropsValue,
} from "react-select";
import makeAnimated from "react-select/animated";
import { isString } from "lodash/fp";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import { Required } from "./Required";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/utils/helpers";
import { CheckIcon } from "@heroicons/react/20/solid";

const animatedComponents = makeAnimated();

export type Option = { value: string; label: string };

type MyInputProps = {
    label?: string;
    name?: string;
    hidden?: boolean;
    infoMessage?: string;
    visible?: boolean;
    control?: Control<any, any>;
    containerStyle?: CSSProperties;
    width?: string;
    isRequired?: boolean;
    onSelect?: (o: Option | MultiValue<Option> | null) => void;
    fetchOptions?: (
        input: string
    ) => Promise<OptionsOrGroups<any, GroupBase<any>>>;
    value?: PropsValue<Option>;
    placeholder?: string;
    options?: OptionsOrGroups<Option, any>;
    selectProps?: StateManagerProps;
    error?: string;
};

export const Select = (props: MyInputProps) => {
    const { infoMessage } = props;

    if (props.hidden) {
        return null;
    }

    if (!isNil(props.visible) && !props.visible) {
        return null;
    }

    return (
        <Box style={{ marginBottom: "1rem", ...props.containerStyle }}>
            <FormControl isInvalid={!!props.error}>
                {props.label && (
                    <FormLabel color={colors.black} fontSize={14}>
                        {props.label} {props.isRequired && <Required />}
                        {infoMessage && <Info message={infoMessage} />}
                    </FormLabel>
                )}

                {props.control && props.name ? (
                    <Controller
                        control={props.control}
                        name={props.name}
                        render={({
                            field: { value, onChange, onBlur, ref },
                        }) => {
                            return (
                                <ReactSelect
                                    placeholder={props.placeholder}
                                    components={animatedComponents}
                                    onChange={onChange}
                                    onBlur={onBlur as any}
                                    menuPortalTarget={document.body}
                                    menuPosition={"fixed"}
                                    styles={{
                                        container: (base) => ({
                                            ...base,
                                            backgroundColor: "white",
                                            borderRadius: "7px",
                                            fontSize: 15,
                                        }),
                                        indicatorSeparator: (b) => ({
                                            display: "none",
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                        control: (base, state) => ({
                                            ...base,
                                            borderRadius: "7px",
                                            padding: "0.1rem",
                                            border: state.isFocused
                                                ? `1px solid ${colors.primary} !important`
                                                : `1px solid ${colors.stone200} !important`,
                                            outline: state.isFocused
                                                ? `1px solid ${colors.primary} !important`
                                                : `none`,
                                            boxShadow: "0 !important",
                                        }),
                                        multiValue: (base) => ({
                                            ...base,
                                            width: "max-content",
                                            minWidth: "100%",
                                        }),
                                        option: (base, state) => ({
                                            ...base,
                                            color: state.isSelected
                                                ? colors.stone800
                                                : colors.stone800,
                                        }),
                                    }}
                                    value={value}
                                    options={props.options}
                                    ref={ref}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            text: colors.stone800,
                                            primary: colors.stone150,
                                            primary25: colors.stone50,
                                            primary75: colors.stone300,
                                            primary50: colors.stone200,
                                        },
                                    })}
                                    {...props.selectProps}
                                />
                            );
                        }}
                    />
                ) : (
                    <ReactSelect
                        placeholder={props.placeholder}
                        components={animatedComponents}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        onChange={(o) => {
                            if (props.onSelect) props.onSelect(o as any);
                        }}
                        styles={{
                            container: (base) => ({
                                ...base,
                                backgroundColor: "white",
                                borderRadius: "7px",
                                fontSize: 15,
                            }),
                            indicatorSeparator: (b) => ({ display: "none" }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (base, state) => ({
                                ...base,
                                borderRadius: "7px",
                                padding: "0.1rem",
                                border: state.isFocused
                                    ? `1px solid ${colors.primary} !important`
                                    : `1px solid ${colors.stone200} !important`,
                                outline: state.isFocused
                                    ? `1px solid ${colors.primary} !important`
                                    : `none`,
                                boxShadow: "0 !important",
                            }),
                            multiValue: (base) => ({
                                ...base,
                                width: "max-content",
                                minWidth: "100%",
                            }),
                            option: (base, state) => ({
                                ...base,
                                color: state.isSelected
                                    ? colors.stone800
                                    : colors.stone800,
                            }),
                        }}
                        value={props.value}
                        options={props.options}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                text: colors.stone800,
                                primary: colors.stone150,
                                primary25: colors.stone50,
                                primary75: colors.stone300,
                                primary50: colors.stone200,
                            },
                        })}
                        {...props.selectProps}
                    />
                )}

                <FormErrorMessage>{props.error}</FormErrorMessage>
            </FormControl>
        </Box>
    );
};

const OptionComponent = ({ option }: { option: Option }) => {
    return (
        <Listbox.Option
            className={({ active }) =>
                classNames(
                    active ? "text-white bg-indigo-600" : "text-gray-900",
                    "relative cursor-default select-none py-2 pl-3 pr-9"
                )
            }
            value={option}
        >
            {({ selected, active }) => (
                <>
                    <span
                        className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                        )}
                    >
                        {option.label}
                    </span>

                    {selected ? (
                        <span
                            className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                        >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                    ) : null}
                </>
            )}
        </Listbox.Option>
    );
};
