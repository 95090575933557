import { compose } from "lodash/fp";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { show } from "redux-modal";
import {
    getCommandIsOpen,
    setActiveMonth,
    setActiveYear,
    setCommandIsOpen,
} from "src/redux/reducers/global";

export const toggleCurrentMonth = (dispatch: Dispatch) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    dispatch(setActiveMonth(currentMonth));
    dispatch(setActiveYear(currentYear));
};

export const useCommands = (spaceId?: string) => {
    const dispatch = useDispatch();
    const _showModal = compose(dispatch, show);
    const _setCommandOpen = compose(dispatch, setCommandIsOpen);
    const isOpen = useSelector(getCommandIsOpen);
    const navigate = useNavigate();

    useHotkeys("cmd+k", () => {
        dispatch(setCommandIsOpen(!isOpen));
    });

    useHotkeys("cmd+x", () => {
        _showModal("TransactionModal");
    });

    useHotkeys("cmd+a", () => {
        _showModal("AccountModal");
    });

    useHotkeys("cmd+t", () => {
        toggleCurrentMonth(dispatch);
        if (spaceId) {
            navigate(`/spaces/${spaceId}/budget`);
            _setCommandOpen(false);
        }
    });
};
