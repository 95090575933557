import React, { useCallback, useState } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
    Box,
    Center,
    Text,
    Image,
    HStack,
    Divider,
    useToast,
    Checkbox,
    Heading,
    Switch,
    FormLabel,
    VStack,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Copy, Info, Input } from "src/components";
import { colors } from "src/theme";
import {
    DefaultErrors,
    failure,
    FailureOrSuccess,
    success,
    UnexpectedError,
} from "src/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import {
    DatabaseProviderEnum,
    MutationCreateDatabaseArgs,
    MutationCreateUserArgs,
} from "src/api/generated/types";
import {
    AuthenticationService,
    AuthenticationType,
} from "src/modules/authentication";
import { auth } from "src/utils/firebase";
import { BaseDatabaseFields, BaseUserFields } from "src/api/fragments";
import { MyToast } from "src/components/MyToast";
import { signInWithCustomToken } from "firebase/auth";
import { useMyToast } from "src/hooks";
import { Touchable } from "src/components/Touchable";
import { config } from "src/config";

const IP_ADDRESSES = config.ipAddresses;

const schema = yup.object().shape({
    name: yup.string().required("Name is required.").nullable(),
    connectionUri: yup
        .string()
        .required("Connection URI is required.")
        .nullable(),
    isSSL: yup.boolean().nullable(),
});

type FormValues = {
    name: string;
    connectionUri: string;
    isSSL: boolean;
};

const DEFAULT_VALUES: FormValues = {
    name: "",
    connectionUri: "",
    isSSL: false,
};

export function CreateDatabase() {
    const [search] = useSearchParams();
    const toast = useMyToast();
    const navigate = useNavigate();
    const [databaseProvider, setDatabaseProvider] =
        useState<DatabaseProviderEnum>(DatabaseProviderEnum.Postgres);
    const [isBehindFirewall, setIsBehindFirewall] = useState(false);

    // API hooks

    const [createDatabase, { data }] = useMutation<{
        createDatabase?: BaseDatabaseFields;
    }>(api.databases.create);

    // Form hooks
    const {
        control,
        handleSubmit,
        setValue,
        formState: { isSubmitting, errors },
        watch,
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: DEFAULT_VALUES,
    });

    // Functions

    const _createDatabase = useCallback(
        async (
            values: FormValues
        ): Promise<FailureOrSuccess<DefaultErrors, BaseDatabaseFields>> => {
            try {
                const isPostgres = values.connectionUri.includes("postgres://");

                if (!isPostgres) {
                    return failure(
                        new Error("Only Postgres is supported at this time.")
                    );
                }

                const params: MutationCreateDatabaseArgs = {
                    name: values.name,
                    connectionUri: values.connectionUri,
                    provider: DatabaseProviderEnum.Postgres,
                    isSSL: values.isSSL,
                };

                const dbResponse = await createDatabase({
                    variables: params,
                });

                if (!dbResponse.data?.createDatabase) {
                    return failure(new Error("No database returned."));
                }

                const db = dbResponse.data?.createDatabase;

                return success(db);
            } catch (err) {
                return failure(new UnexpectedError(err));
            }
        },
        [createDatabase]
    );

    const onSubmit = useCallback(
        async (values: FormValues) => {
            const dbResponse = await _createDatabase(values);

            if (dbResponse.isFailure()) {
                return toast.show({
                    status: "error",
                    message: dbResponse.error.message,
                });
            }

            const path = `/dashboard/${dbResponse.value.id}/tables`;

            return navigate(path);
        },
        [_createDatabase]
    );

    const isSSL = watch("isSSL");

    return (
        <NoNavBarPageTemplate>
            <Touchable
                style={{
                    position: "absolute",
                    top: 25,
                    left: 25,
                }}
                label="Go back"
                iconName="fa-solid fa-arrow-left"
                iconPosition="left"
                onClick={() => navigate(-1)}
            />

            <HStack
                justifyContent="center"
                height="100%"
                minHeight="100vh"
                width="100vw"
                className="bg-stone-50"
            >
                <form
                    style={{
                        maxWidth: 650,
                        width: "100%",
                        padding: "1rem",
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Center
                        display="flex"
                        flexDir="column"
                        width="100%"
                        margin="auto"
                        padding="3rem 1.5rem"
                        borderRadius="0.5rem"
                        bg={colors.white}
                        alignItems="flex-start"
                        className="shadow-md border border-stone-200"
                    >
                        <HStack>
                            <Box
                                style={{
                                    width: 35,
                                    height: 35,
                                    borderRadius: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: colors.black,
                                }}
                            >
                                <i
                                    style={{
                                        color: colors.white,
                                        fontSize: 18,
                                    }}
                                    className="fa-duotone fa-server"
                                />
                            </Box>
                            <Heading margin="0" fontSize="2xl">
                                Add a database{" "}
                            </Heading>
                        </HStack>
                        <br />
                        {/* <Text textAlign="left">
              So many people called taxes a nightmare that it inspired us to
              name our company Buckets.
            </Text>
            <br /> */}

                        <Box width="100%">
                            <DatabaseOptions
                                databaseProvider={databaseProvider}
                                setDatabaseProvider={setDatabaseProvider}
                            />

                            <Input
                                label="Name"
                                isRequired
                                control={control}
                                name="name"
                            />

                            <Input
                                label="Connection String"
                                lowerText="You should make this read-only access"
                                isRequired
                                placeholder="postgres://"
                                control={control}
                                name="connectionUri"
                            />

                            <br />

                            <Divider />

                            <br />

                            <HStack>
                                <Switch
                                    onChange={(e) =>
                                        setValue("isSSL", e.target.checked)
                                    }
                                    checked={isSSL}
                                />
                                <Text fontSize="sm">SSL connection</Text>
                            </HStack>

                            <HStack marginTop="1rem" alignItems="flex-start">
                                <Switch
                                    onChange={(e) =>
                                        setIsBehindFirewall(e.target.checked)
                                    }
                                    isChecked={isBehindFirewall}
                                />
                                <VStack alignItems="flex-start">
                                    <Text fontSize="sm">
                                        Is your database behind a firewall?{" "}
                                        <Info message="If your database only allows specific IP addresses to connect to it (very common), please whitelist our IP addresses below so we can connect to it." />
                                    </Text>

                                    {isBehindFirewall && (
                                        // make a component that shows multiple IPs separated by newline and has a copy button to copy them all
                                        <div>
                                            <Text
                                                fontSize="xs"
                                                marginBottom="10px"
                                            >
                                                Whitelist the following IP
                                                addresses:
                                            </Text>
                                            {IP_ADDRESSES.map((ip) => (
                                                <Touchable
                                                    style={{
                                                        marginRight: 10,
                                                        marginBottom: 10,
                                                        backgroundColor:
                                                            colors.stone150,
                                                        borderRadius: 5,
                                                        fontSize: 12,
                                                    }}
                                                    label={ip}
                                                    onClick={() => {
                                                        // write ip to clipboard
                                                        window.navigator.clipboard.writeText(
                                                            ip
                                                        );

                                                        toast.show({
                                                            status: "success",
                                                            message: "Copied!",
                                                        });
                                                    }}
                                                    iconName="fa-solid fa-copy"
                                                />
                                            ))}
                                        </div>
                                    )}
                                </VStack>
                            </HStack>
                        </Box>
                        <br />

                        <Divider />
                        <br />
                        <br />

                        <Button
                            isLoading={isSubmitting}
                            width="100%"
                            variant="primary"
                            type="submit"
                            style={{ position: "relative" }}
                        >
                            Add database
                            <Box
                                style={{
                                    fontSize: 18,
                                    position: "absolute",
                                    right: 20,
                                    top: 10,
                                }}
                            >
                                <i className="fa-solid fa-arrow-right" />
                            </Box>
                        </Button>
                    </Center>
                </form>
            </HStack>
        </NoNavBarPageTemplate>
    );
}

const DatabaseOptions = ({
    databaseProvider,
    setDatabaseProvider,
}: {
    databaseProvider: DatabaseProviderEnum;
    setDatabaseProvider: (provider: DatabaseProviderEnum) => void;
}) => {
    const isPostgres = databaseProvider === DatabaseProviderEnum.Postgres;

    return (
        <>
            <FormLabel color={colors.black} fontSize={14}>
                Database
            </FormLabel>
            <HStack>
                <Touchable
                    style={{
                        backgroundColor: isPostgres
                            ? colors.green100
                            : colors.stone150,
                        border: isPostgres
                            ? `2px solid ${colors.green50}`
                            : `2px solid ${colors.stone200}`,
                    }}
                    label="Postgres"
                    icon={
                        <img
                            src={
                                require("src/assets/logos/postgres.svg").default
                            }
                            style={{
                                width: 15,
                                height: 15,
                                objectFit: "contain",
                            }}
                        />
                    }
                />
            </HStack>
            <br />
        </>
    );
};
