import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Box, BoxProps } from "@chakra-ui/react";
import { colors } from "src/theme";
import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Commands } from "../styled/Commands";
import { Sidebar } from "../Sidebar";
import { useCommands } from "src/hooks/useCommands";
import { isElectron } from "react-device-detect";
import { Outlet, useParams } from "react-router-dom";

const CONTENT_MARGIN_LEFT = 2.5;
const CONTENT_MARGIN_TOP = "2rem";

const Content = styled.div`
    width: ${100 - CONTENT_MARGIN_LEFT - CONTENT_MARGIN_LEFT}%;
    margin-left: ${CONTENT_MARGIN_LEFT}%;
    margin-top: ${CONTENT_MARGIN_TOP}%;
    overflow-y: scroll;
`;

function PageTemplateContainer(props: BoxProps) {
    return (
        <Box>
            {isElectron && <div className="titlebar" />}
            <Box w="100%" bgColor={colors.gray1} {...props}>
                {props.children}
            </Box>
        </Box>
    );
}

type NavBarPageTemplateProps = BoxProps & {
    children: ReactNode;
    position?: string;
    button?: string;
};

function NavBarPageTemplate({ button, ...props }: NavBarPageTemplateProps) {
    return (
        <PageTemplateContainer {...props}>
            {isElectron && <div className="titlebar" />}
            <Content>{props.children}</Content>
        </PageTemplateContainer>
    );
}

function SideBarPageTemplate() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { spaceId } = useParams<{ spaceId: string }>();

    useCommands(spaceId);

    return (
        <>
            {isElectron && <div className="titlebar" />}
            <div>
                {/* Shared modals */}
                <Commands />

                <Sidebar />

                <div
                    className={`flex flex-1 flex-col`}
                    style={{
                        width: `calc(100vw - 225px)`,
                        marginLeft: 225,
                    }}
                >
                    <Outlet />
                </div>
            </div>
        </>
    );
}

function NoNavBarPageTemplate(props: BoxProps) {
    return (
        <PageTemplateContainer {...props}>
            {props.children}
        </PageTemplateContainer>
    );
}

export {
    NavBarPageTemplate,
    NoNavBarPageTemplate,
    SideBarPageTemplate,
    PageTemplateContainer,
    Content,
};
