import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTheme, setTheme } from "src/redux/reducers/global";
import { colors } from "src/theme";

export const useTheme = () => {
    const theme = useSelector(getTheme);
    const dispatch = useDispatch();

    const _toggleDarkMode = useCallback(async () => {
        try {
            const newTheme = theme === "light" ? "dark" : "light";
            await window.localStorage.setItem("theme", newTheme);
            dispatch(setTheme(newTheme));
        } catch (error) {
            console.log(error);
        }
    }, [theme]);

    const data = useMemo(
        () => ({
            background: theme === "light" ? colors.white : colors.black,
            medBackground: theme === "light" ? colors.gray100 : colors.gray10,
            secondaryBackground: theme === "light" ? colors.gray90 : colors.gray10,
            ternaryBackground: theme === "light" ? colors.gray80 : colors.gray20,
            header: theme === "light" ? colors.black : colors.white,
            theme,
            text: theme === "light" ? colors.gray30 : colors.gray60,
            placeholder: theme === "light" ? colors.gray60 : colors.gray30,
            border: theme === "light" ? colors.gray90 : colors.gray10,
            greenBg: theme === "light" ? colors.green90 : colors.green20,
            blueBg: theme === "light" ? colors.lightBlue90 : colors.lightBlue20,
            toggleDarkMode: _toggleDarkMode,
        }),
        [theme, _toggleDarkMode]
    );

    return data;
};
