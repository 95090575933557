import {
    Box,
    Button,
    HStack,
    Heading,
    Text,
    VStack,
    useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMe } from "src/hooks";
import { colors } from "src/theme";

function Home({ navigateAway = true }: { navigateAway?: boolean }) {
    const { me } = useMe();
    const navigate = useNavigate();

    useEffect(() => {
        if (!navigateAway) return;
        if (!me) return;
        navigate("/dashboard");
    }, [me]);

    const [isSmall] = useMediaQuery("(max-width: 600px)");

    return (
        <div
            style={{
                width: "100vw",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // black background radius, colors.bg and dark grey
                background: `radial-gradient(circle, ${colors.bg} 0%, ${colors.secondary} 100%)`,
            }}
        >
            <HStack
                style={{
                    width: "100%",
                    paddingTop: isSmall ? "5rem" : "0",
                    flexDirection: isSmall ? "column" : "row",
                }}
            >
                <Box
                    style={{
                        flex: 1,
                        padding: isSmall ? "1.25rem" : "0",
                    }}
                >
                    <Box
                        style={{
                            maxWidth: 500,
                            width: isSmall ? "100%" : "90%",
                            margin: "auto",
                        }}
                    >
                        <img
                            src={require("src/assets/logos/icon-2.png")}
                            style={{
                                width: 50,
                                height: 50,
                                objectFit: "cover",
                                border: `2px solid ${colors.white}`,
                                borderRadius: 10,
                            }}
                        />

                        <Heading
                            fontWeight="bold"
                            style={{
                                color: colors.lightBlue80,
                            }}
                        >
                            {/*   <dbgpt.sh /> but work with react*/}
                            <span style={{ color: colors.white }}>dbgpt</span>
                            .sh
                        </Heading>

                        <Text
                            fontSize="md"
                            color={colors.white}
                            fontWeight={600}
                        >
                            Copilot for your Postgres database 👾
                        </Text>
                        <Text
                            fontSize="md"
                            marginTop="1rem"
                            color={colors.white}
                            fontWeight={600}
                        >
                            Talk to your db. Debug queries. Analyze your data.
                        </Text>

                        <Text
                            fontSize="md"
                            marginTop="1rem"
                            color={colors.white}
                            fontWeight={600}
                        >
                            Securely connect to your database in 30 seconds.
                            Only your database data schemas are shared & stored.
                            The rows in your database are never stored nor
                            shared with GPT.
                        </Text>

                        <HStack marginTop="2rem">
                            {/* signup and login buttons */}
                            <Link
                                to="/sign-up"
                                style={{
                                    marginRight: "0.25rem",
                                }}
                            >
                                <Button
                                    style={{
                                        backgroundColor: colors.white,
                                        color: colors.black,
                                        border: `2px solid ${colors.white}`,
                                    }}
                                >
                                    Get Started{" "}
                                    <i
                                        style={{ marginLeft: "10px" }}
                                        className="fas fa-arrow-right"
                                    />
                                </Button>
                            </Link>

                            <Link to="/login">
                                <Button
                                    style={{
                                        backgroundColor: "transparent",
                                        color: colors.white,
                                        border: `2px solid ${colors.white}`,
                                    }}
                                >
                                    Login
                                </Button>
                            </Link>
                        </HStack>

                        {/* <Download /> */}

                        <Text
                            marginTop="3rem"
                            fontSize="sm"
                            color={colors.white}
                        >
                            Made with ❤️ by{" "}
                            <a
                                target="_blank"
                                href="https://twitter.com/big_duca"
                                style={{
                                    color: colors.lightBlue80,
                                    fontWeight: "bold",
                                }}
                            >
                                Andrew Duca
                            </a>
                            .
                        </Text>
                    </Box>
                </Box>

                <Box
                    flex={1}
                    style={{
                        textAlign: "center",
                        margin: 0,
                        marginTop: isSmall ? "1rem" : "0",
                        padding: isSmall ? "1rem" : "0",
                        width: "100%",
                    }}
                >
                    <Box
                        style={{
                            maxWidth: 600,
                            width: isSmall ? "100%" : "95%",
                            margin: "auto",
                        }}
                    >
                        <iframe
                            src="https://www.loom.com/embed/e7a5c1dde9e34d7da987dd4145155edc?sid=4f312630-2f2a-454e-933c-bf28585a1e6b&hideEmbedTopBar=true"
                            frameBorder="0"
                            allowFullScreen
                            style={{
                                borderRadius: 15,
                                width: "100%",
                                height: 500,
                            }}
                        />
                    </Box>
                </Box>
            </HStack>
        </div>
    );
}

const Download = () => {
    return (
        <VStack marginTop="2rem" alignItems="flex-start">
            {/* signup and login buttons */}
            <Text
                fontSize="md"
                marginTop="1rem"
                color={colors.white}
                fontWeight={600}
            >
                Want to get dbgpt.sh on desktop?
            </Text>
            <Link to="/download">
                <Button
                    style={{
                        backgroundColor: colors.white,
                        color: colors.primary,
                        border: `2px solid ${colors.white}`,
                    }}
                >
                    Desktop Download{" "}
                    <i
                        style={{ marginLeft: "10px" }}
                        className="fas fa-cloud-download-alt"
                    />
                </Button>
            </Link>
        </VStack>
    );
};

export default Home;
