import { color, extendTheme, Input, NumberInput } from "@chakra-ui/react";
import * as colors from "src/theme/colors";
import { borderRadius } from "src/theme/other";
import { createBreakpoints } from "@chakra-ui/theme-tools";

// Override the focus color
// https://github.com/chakra-ui/chakra-ui/discussions/2459#discussioncomment-2412881
NumberInput.defaultProps = {
    ...NumberInput.defaultProps,
    // focusBorderColor: colors.gray80,
    // color: colors.darkGray,
};

const breakpoints = createBreakpoints({
    sm: "320px",
    md: "800px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
});

const theme = extendTheme({
    breakpoints,
    colors: {
        primary: colors.primary,
        // these are used by toast so just overriding for now
        "green.500": colors.green50,
        "blue.500": colors.lightBlue50,
        "red.500": colors.red50,
        "gray.100": colors.gray90,
    },
    styles: {
        global: {
            html: {},
        },
    },
    components: {
        Box: {
            baseStyle: {
                fontSize: 18,
            },
            variants: {
                shadow: {
                    boxShadow: "0px 0px 25px rgba(255, 255, 255, 0.3)",
                },
            },
        },
        Popover: {
            variants: {
                main: {
                    backgroundColor: "blue",
                    boxShadow: "0px 0px 25px rgba(255, 255, 255, 0.3)",
                },
            },
        },
        Link: {
            baseStyle: {
                color: colors.primary,
                fontWeight: "bold",
                _hover: {
                    textDecoration: "underline",
                },
            },
        },
        Button: {
            baseStyle: {
                fontWeight: "semibold",
                borderRadius: borderRadius,
                backgroundColor: colors.primary,
                // color: colors.white,
            },
            variants: {
                primary: {
                    background: colors.primary,
                    color: colors.white,
                    padding: "0.75rem 1.5rem",
                    height: "auto",
                    minWidth: "10rem",
                    _hover: {
                        background: colors.primary + " !important",
                    },
                },
                secondary: {
                    backgroundColor: colors.gray2,
                    padding: "0.9rem",
                    display: "block",
                    height: "auto",
                    width: "10rem",
                },
                white: {
                    color: colors.black,
                    textAlign: "center",
                    height: "100%",
                },
                action: {
                    fontSize: 14,
                    borderRadius: 7,
                    padding: "0.5rem 0.5rem",
                    height: "auto",
                },
                transparent: {
                    background: "transparent",
                    color: colors.black,
                    padding: "0.9rem",
                    height: "auto",
                    width: "10rem",
                    _hover: {
                        background: "transparent",
                    },
                },
            },
        },
        Container: {
            baseStyle: {
                marginTop: 5,
                maxWidth: "auto",
                fontSize: 16,
            },
            variants: {
                primary: {
                    borderRadius: 10,
                    padding: "30px 10px",
                    backgroundColor: colors.white,
                    position: "relative",
                    boxShadow: "0px 0px 25px rgba(255, 255, 255, 0.3)",
                    border: "1px solid " + colors.gray2,
                },
                border: {
                    borderRadius: 7,
                    padding: "1rem",
                    position: "relative",
                },
                box: { fontSize: 20, maxWidth: "60ch" },
                info: {
                    padding: "0.5rem 1rem",
                    borderRadius: "0.25rem",
                    textAlign: "center",
                },
                main: {
                    padding: "0.25rem 0.5rem",
                    borderRadius: "0.25rem",
                    marginTop: 0,
                    textAlign: "center",
                    boxShadow: "0px 0px 5px rgb(143 143 143 / 25%)",
                },
            },
        },
        Modal: {
            baseStyle: {
                boxShadow: "none",
            },
        },
        Textarea: {
            baseStyle: {
                padding: "9px 0px",
                fontSize: 16,
                paddingLeft: 0, // Some reason have to override like this
            },
        },
        Heading: {
            baseStyle: {
                fontWeight: "semibold",
                marginTop: "2rem",
                marginBottom: "1rem",
                fontFamily:
                    "ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace",
            },
            variants: {
                xl: {
                    fontWeight: "bold",
                    fontSize: 60,
                },
                lg: {
                    fontWeight: "bold",
                    fontSize: 40,
                },
                md: {
                    fontWeight: "semibold",
                    fontSize: 30,
                },
            },
        },
        Text: {
            baseStyle: {
                fontSize: 16,
            },
        },
    },
    shadows: {
        outline: "none",
    },
});

export { theme };
