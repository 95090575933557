import { useQuery } from '@apollo/client'
import { HStack, Text } from '@chakra-ui/react';
import React from 'react'
import { Link } from 'react-router-dom';
import { api } from 'src/api'
import { BaseTeamMemberFields } from 'src/api/fragments';
import { Query } from 'src/api/generated/types';
import { Button } from 'src/components';
import { useTheme } from 'src/hooks/useTheme';
import { colors } from 'src/theme';

function Team() {
    const { data } = useQuery<Pick<Query, "getActiveTeamMembers">>(api.teams.members);

    const theme = useTheme();
    const members = data?.getActiveTeamMembers ?? [];

    return (
        <div style={{
            padding: "1rem 2rem"
        }}>
            <HStack>
                <Text flex={1} fontSize="xl" color={colors.black}>
                    Team
                </Text>
                <Link to="/dashboard/create-campaign">
                    <Button onClick={() => { }}>
                        Invite Member
                    </Button>
                </Link>
            </HStack>

            {members.map((member) => (
                <TeamMemberRow member={member} key={member.id} />
            ))}

        </div>
    )
}

const TeamMemberRow = ({ member }: {
    member: BaseTeamMemberFields
}) => {
    const theme = useTheme();

    return (
        <HStack spacing="2rem" style={{
            padding: "1rem",
            background: theme.background,
            border: `1px solid ${theme.border}`,
            margin: "1rem 0",
            borderRadius: 15,
            cursor: "pointer"
        }}>
            {/* image of postgres */}
            <img
                style={{
                    width: 20, height: "auto",
                }}
                src={require("src/assets/icons/postgres.svg").default}
            />

            <div>
                <Text style={{ marginBottom: 5 }}>{member.name}</Text>

                <Text fontSize="sm" color={theme.text}>
                    Added on {new Date(member.createdAt).toLocaleDateString()}
                </Text>
            </div>

        </HStack>
    )
}

export default Team