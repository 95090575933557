import { useMutation } from "@apollo/client";
import { Flex, HStack, Text, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import { BaseNotebookFields } from "src/api/fragments";
import { Mutation } from "src/api/generated/types";
import { ActionSheet } from "src/components";
import { Touchable } from "src/components/Touchable";
import UserProfileIcon from "src/components/UserProfileIcon";
import { useMe, useMyToast } from "src/hooks";
import { useDatabases } from "src/hooks/useDatabases";
import {
    setAnalysis,
    setQuery,
    setQuestion,
    setTips,
} from "src/redux/reducers/activeQuery";
import { colors } from "src/theme";

export const Tabs = () => {
    const { notebooks } = useDatabases();

    const { databaseId } = useParams();
    const [showQueries, setShowQuery] = useState(true);
    const [createNotebook] = useMutation<Pick<Mutation, "createNotebook">>(
        api.notebooks.create
    );
    const navigate = useNavigate();

    const _createQuery = async (e: any) => {
        e.stopPropagation();

        const response = await createNotebook({
            variables: {
                databaseId,
                query: "",
                description: "",
            },
            refetchQueries: [api.databases.notebooks],
        });

        const notebook = response?.data?.createNotebook;

        navigate(`/dashboard/${databaseId}/data/${notebook?.id}`);
    };

    return (
        <HStack w="100%" padding="0.75rem 1rem 0 1rem">
            {/* make it so don't bunch up */}
            <Flex
                flex={1}
                alignItems="center"
                style={{
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    // hide scroll bar
                    scrollbarGutter: "none",
                }}
            >
                {notebooks.map((notebook) => (
                    <Tab
                        key={notebook.id}
                        notebook={notebook}
                        databaseId={databaseId || ""}
                    />
                ))}
            </Flex>

            <div
                style={{
                    // shadow just on the left side of the box
                    borderLeft: "1px solid " + colors.gray70,
                    padding: "0 0.5rem",
                }}
            >
                <Touchable
                    onClick={_createQuery}
                    label="New Tab"
                    iconName="fa-solid fa-plus"
                    style={{ display: "flex" }}
                />
            </div>

            <div
                style={{
                    width: 200,
                }}
            >
                <MyProfile />
            </div>
        </HStack>
    );
};

const Tab = ({
    notebook,
    databaseId,
}: {
    notebook: BaseNotebookFields;
    databaseId: string;
}) => {
    const { notebookId } = useParams();
    const isActive = notebookId === notebook.id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updateNotebook] = useMutation(api.notebooks.update);
    const toast = useMyToast();

    const _onSelectNotebook = () => {
        dispatch(setTips(null));
        dispatch(setAnalysis(null));
        dispatch(setQuery(notebook.query || ""));
        dispatch(setQuestion(notebook.question || ""));
        navigate(`/dashboard/${databaseId}/data/${notebook.id}`);
    };

    const _onClose = async (e: any) => {
        // confirm UI
        if (!window.confirm("Are you sure you want to close this tab?")) {
            return;
        }

        // don't want to await it for now
        void updateNotebook({
            variables: {
                notebookId: notebook.id,
                isHidden: true,
            },
            refetchQueries: [api.databases.notebooks],
        }).catch((err) => {
            toast.show({
                message: err.message || "Something went wrong!",
                status: "error",
            });
        });
    };

    const _onEdit = async (e: any) => {
        // confirm UI

        const newName = window.prompt("Enter a new name for this tab");

        if (!newName) {
            return;
        }

        // don't want to await it for now
        void updateNotebook({
            variables: {
                notebookId: notebook.id,
                title: newName,
            },
            refetchQueries: [api.databases.notebooks],
        }).catch((err) => {
            toast.show({
                message: err.message || "Something went wrong!",
                status: "error",
            });
        });
    };

    return (
        <Tooltip
            openDelay={500}
            label={notebook.question || notebook.query || ""}
        >
            <div style={{ display: "flex" }}>
                <Touchable
                    labelStyle={{
                        fontWeight: "normal",
                        // color: isActive ? colors.white : undefined,
                    }}
                    onClick={_onSelectNotebook}
                    style={{
                        flexShrink: 0,
                        marginRight: 5,
                        borderWidth: 1,
                        backgroundColor: isActive ? colors.stone150 : undefined,
                        borderColor: isActive ? colors.black : undefined,
                        padding: "2px 2px 2px 15px",
                    }}
                    flexContainerStyle={{ gap: 0 }}
                    label={notebook.title || "Notebook"}
                    labelProps={{
                        style: {
                            marginRight: 10,
                        },
                    }}
                    rightComponent={
                        // an action sheet
                        <div>
                            <ActionSheet
                                commands={[
                                    {
                                        label: "Hide tab",
                                        // font awesome hidden icon: https://fontawesome.com/v5.15/icons?d=gallery&p=2&q=hide
                                        iconName: "fa-solid fa-eye-slash",
                                        onClick: _onClose,
                                    },
                                    {
                                        label: "Edit name",
                                        iconName: "fa-solid fa-pen",
                                        onClick: _onEdit,
                                    },
                                ]}
                                content={{ width: 175 }}
                            >
                                <Touchable
                                    iconName="fa-solid fa-chevron-down"
                                    style={{
                                        margin: 0,
                                        marginRight: "0.5rem",
                                        backgroundColor: "transparent",
                                    }}
                                />
                            </ActionSheet>
                        </div>
                    }
                />
            </div>
        </Tooltip>
    );
};

const MyProfile = () => {
    const { me } = useMe();

    return (
        <div style={{ width: "100%" }}>
            <ActionSheet
                content={{
                    width: 230,
                }}
                popover={{
                    placement: "bottom-end",
                }}
                commands={[
                    // contact customer support
                    {
                        label: "Leave feedback",
                        iconName: "fa-solid fa-heart",
                        link: "mailto:andrew@dbgpt.sh&subject=Feedback on dbgpt 🙏",
                    },
                    {
                        label: "Contact support",
                        iconName: "fa-solid fa-comments",
                        link: "mailto:andrew@dbgpt.sh",
                    },
                    {
                        hasDivider: true,
                        label: "Logout",
                        iconName: "fa-solid fa-sign-out",
                        link: "/logout",
                        iconColor: colors.red50,
                        color: colors.red50,
                    },
                ]}
            >
                <HStack
                    style={{
                        cursor: "pointer",
                        padding: "0.25rem 1rem 0.25rem 0.25rem",
                        borderRadius: 7,
                        width: "100%",
                        backgroundColor: colors.stone100,
                    }}
                    border={`1px solid ${colors.stone200}`}
                >
                    <UserProfileIcon user={me} />
                    <Text flex={1} fontSize="xs">
                        {me?.name}
                    </Text>
                    <div
                        style={{
                            fill: colors.stone400,
                            width: 12,
                            height: 12,
                            flexShrink: 0,
                            flexGrow: 0,
                        }}
                    >
                        {/* */}
                        <svg
                            viewBox="-1 -1 9 11"
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                fill: "inherit",
                                flexShrink: 0,
                                backfaceVisibility: "hidden",
                            }}
                        >
                            <path
                                id="path0_stroke"
                                d="M 3.5 0L 3.98809 -0.569442L 3.5 -0.987808L 3.01191 -0.569442L 3.5 0ZM 3.5 9L 3.01191 9.56944L 3.5 9.98781L 3.98809 9.56944L 3.5 9ZM 0.488094 3.56944L 3.98809 0.569442L 3.01191 -0.569442L -0.488094 2.43056L 0.488094 3.56944ZM 3.01191 0.569442L 6.51191 3.56944L 7.48809 2.43056L 3.98809 -0.569442L 3.01191 0.569442ZM -0.488094 6.56944L 3.01191 9.56944L 3.98809 8.43056L 0.488094 5.43056L -0.488094 6.56944ZM 3.98809 9.56944L 7.48809 6.56944L 6.51191 5.43056L 3.01191 8.43056L 3.98809 9.56944Z"
                            ></path>
                        </svg>
                    </div>
                    {/* <i className="fa-solid fa-caret-down" /> */}
                </HStack>
            </ActionSheet>
        </div>
    );
};
