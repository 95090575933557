import { Box, BoxProps } from "@chakra-ui/react";
import { colors, other } from "src/theme";

type VerifiedProps = BoxProps & {
    isChecked?: boolean;
};

function Verified({ isChecked, ...props }: VerifiedProps) {
    return (
        <Box
            display="inline-block"
            border={other.boxBorder}
            borderRadius="50%"
            width="1.7rem"
            height="1.7rem"
            bgColor={isChecked ? colors.primaryGreen : colors.white}
            {...props}
        />
    );
}

export default Verified;
