import {
    ApolloQueryResult,
    OperationVariables,
    useQuery,
} from "@apollo/client";
import { useSelector } from "react-redux";
import { api } from "src/api";
import { BaseUserFields } from "src/api/fragments";
import { Maybe } from "src/core";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { AuthStatus } from "src/redux/types";

export type UseMeReturn = {
    me: Maybe<BaseUserFields>;
    authStatus: AuthStatus;
    loadingMe: boolean;
    refetchMe: (
        variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<{ me: BaseUserFields }>>;
};

export const useMe = (): UseMeReturn => {
    const authStatus = useSelector(getUserAuthStatus);

    const {
        data: myData,
        refetch: refetchMe,
        loading: loadingMe,
    } = useQuery<{
        me: BaseUserFields;
    }>(api.users.me, {
        skip: authStatus !== "LOGGED_IN",
    });

    // const [_updateMe] = useMutation<{
    //     updateMe: BaseUserFields;
    // }>(api.users.update);

    // const updateMe = async (
    //     options?: MutationFunctionOptions<
    //         { updateMe: BaseUserFields },
    //         OperationVariables,
    //         DefaultContext,
    //         ApolloCache<any>
    //     >
    // ) => {
    //     await _updateMe({
    //         ...options,
    //         refetchQueries: [graphqlApi.users.me],
    //     });
    // };

    const me = myData?.me || null;

    return {
        me,
        authStatus,
        refetchMe,
        loadingMe,
    };
};
