import {
    Box,
    BoxProps,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverContentProps,
    PopoverProps,
    PopoverTrigger,
    Portal,
    VStack,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Command, CommandProps } from "./Command";

type ActionSheetProps = {
    commands: CommandProps[];
    popover?: PopoverProps;
    content?: PopoverContentProps;
    children?: JSX.Element | JSX.Element[];
    closeAfterClick?: boolean;
};

export const ActionSheet = ({
    popover,
    content,
    children,
    commands,
    closeAfterClick: _closeAfterClick,
}: ActionSheetProps) => {
    const [isOpen, setOpen] = useState(false);
    const closeAfterClick = _closeAfterClick ?? false;

    // this is a curried function for the command click, and the purpose of it
    // is so that we can automatically close the popover on success of the click
    //
    // code looks a bit confusing, but all it does is close the popover on success clicks
    const _onClick = (c: Pick<CommandProps, "onClick">) => async () => {
        if (!closeAfterClick) setOpen(false);
        if (c && c.onClick) await c.onClick();
        if (closeAfterClick) setOpen(false);
    };

    return (
        <Popover
            placement="bottom"
            isOpen={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            {...popover}
        >
            {/* @ts-ignore */}
            <PopoverTrigger>
                <div style={{ display: "flex" }}>{children}</div>
            </PopoverTrigger>
            <PopoverContent {...content}>
                <PopoverArrow />
                <VStack w="100%" gap={0} padding="0.25rem 0">
                    {commands.map((c, i) => (
                        <Command {...c} onClick={_onClick(c)} key={i} />
                    ))}
                </VStack>
            </PopoverContent>
        </Popover>
    );
};
