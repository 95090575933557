import { useToast } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { MyToast, MyToastStatus } from "src/components/MyToast";

export type ToastType = {
    show: ({ message, status }: ShowToastFunctionType) => void;
};

export type ShowToastFunctionType = {
    message: string;
    status: MyToastStatus;
};

export const useMyToast = () => {
    const toast = useToast();

    const showToast = ({ message, status }: ShowToastFunctionType) => {
        toast({
            position: "top",
            containerStyle: {
                maxWidth: isMobile ? "100%" : "75%",
            },
            render: () => <MyToast status={status} message={message} />,
        });
    };

    return {
        show: showToast,
    };
};
