import { Box, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavBarPageTemplate, SideBarPageTemplate } from "src/components";
import { useMe } from "src/hooks";
import { getUserAuthStatus } from "src/redux/reducers/user";

function Loading() {
    const status = useSelector(getUserAuthStatus);
    const navigate = useNavigate();
    const { me } = useMe();

    // if not logged in, go to login
    useEffect(() => {
        if (status === "NOT_LOGGED_IN") {
            navigate("/login");
        }
    }, [status]);

    // if there is a user, navigate to their active space
    useEffect(() => {
        if (!me) return;

        const databaseId = me.activeDatabaseId;

        if (databaseId) {
            navigate(`/dashboard/${databaseId}`);
        } else {
            navigate(`/dashboard/create-db`);
        }
    }, [me]);

    return (
        <NavBarPageTemplate overflow="scroll">
            <Box
                w="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                className="bg-stone-50"
            >
                <Spinner />
            </Box>
        </NavBarPageTemplate>
    );
}

export default Loading;
