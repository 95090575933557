import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { theme } from "src/theme";
import { apolloClient } from "./api/apollo";
import { Navigation } from "./navigation";
import { persistor, store } from "./redux";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import { initSentry } from "./utils/sentry";

const queryClient = new QueryClient();

initSentry();

const App = () => {
    useEffect(() => {
        window.location.href = "https://benchmarkthing.com";
    }, []);

    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <ApolloProvider client={apolloClient}>
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <Navigation />
                        </PersistGate>
                    </Provider>
                </ApolloProvider>
            </QueryClientProvider>
        </ChakraProvider>
    );
};

export default App;
