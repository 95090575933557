/*
  This example requires Tailwind CSS v3.0+
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
    DocumentPlusIcon,
    BuildingLibraryIcon,
    FolderIcon,
    HashtagIcon,
    TagIcon,
    BanknotesIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { getCommandIsOpen, setCommandIsOpen } from "src/redux/reducers/global";
import { compose } from "lodash/fp";
import { show } from "redux-modal";
import { toggleCurrentMonth } from "src/hooks/useCommands";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// const projects = [
//   { id: 1, name: "Workflow Inc. / Website Redesign", url: "#" },
//   // More projects...
// ];
// const recent = [projects[0]];

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

export function Commands() {
    const dispatch = useDispatch();
    const [query, setQuery] = useState("");
    const isOpen = useSelector(getCommandIsOpen);
    const setOpen = compose(dispatch, setCommandIsOpen);
    const navigate = useNavigate();
    const location = useLocation();
    const { spaceId } = useParams<{ spaceId: string }>();

    const filteredProjects: any[] = [];
    // query === ""
    //   ? []
    //   : projects.filter((project) => {
    //       return project.name.toLowerCase().includes(query.toLowerCase());
    //     });

    const quickActions = [
        {
            name: "Add new transaction...",
            icon: <i className="fa-solid fa-receipt" />,
            shortcut: "X",
            onClick: () => dispatch(show("TransactionModal")),
        },
        {
            name: "Add new account...",
            icon: <i className="fa-solid fa-university" />,
            shortcut: "A",
            onClick: () => dispatch(show("AccountModal")),
        },
        {
            name: "Move to the current month...",
            icon: <i className="fa-solid fa-calendar" />,
            shortcut: "T",
            onClick: () => {
                toggleCurrentMonth(dispatch);
                navigate(`/spaces/${spaceId}/budget`);
                setOpen(false);
            },
        },
    ];

    return (
        <Transition.Root
            show={isOpen}
            as={Fragment}
            afterLeave={() => setQuery("")}
            appear
        >
            <Dialog as="div" className="relative z-20" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="fixed inset-0 transition-opacity"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.25)",
                        }}
                    />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-stone-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                            <Combobox
                                onChange={(item) => {
                                    // TODO:
                                }}
                            >
                                <div className="relative items-center">
                                    <i
                                        className="fa-solid fa-search text-md pointer-events-none absolute top-4 left-4 h-6 w-5 text-stone-400"
                                        aria-hidden="true"
                                    />
                                    <Combobox.Input
                                        className="h-11 w-full border-0 bg-transparent pl-11 pr-4 text-stone-800 placeholder-stone-400 focus:ring-0 sm:text-sm"
                                        placeholder="Search..."
                                        onChange={(event) =>
                                            setQuery(event.target.value)
                                        }
                                    />
                                </div>

                                {(query === "" ||
                                    filteredProjects.length > 0) && (
                                    <Combobox.Options
                                        static
                                        className="max-h-80 scroll-py-2 divide-y divide-stone-100 overflow-y-auto"
                                    >
                                        {/* <li className="p-2">
                      {query === "" && (
                        <h2 className="mt-4 mb-2 px-3 text-xs font-semibold text-stone-500">
                          Recent searches
                        </h2>
                      )}
                      <ul className="text-sm text-stone-700">
                        {(query === "" ? recent : filteredProjects).map(
                          (project) => (
                            <Combobox.Option
                              key={project.id}
                              value={project}
                              className={({ active }) =>
                                classNames(
                                  "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                  active && "bg-indigo-600 text-white"
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <FolderIcon
                                    className={classNames(
                                      "h-6 w-6 flex-none",
                                      active ? "text-white" : "text-stone-400"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 flex-auto truncate">
                                    {project.name}
                                  </span>
                                  {active && (
                                    <span className="ml-3 flex-none text-indigo-100">
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          )
                        )}
                      </ul>
                    </li> */}
                                        {query === "" && (
                                            <li className="p-2">
                                                <h2 className="sr-only">
                                                    Quick actions
                                                </h2>
                                                <ul className="text-sm text-stone-700">
                                                    {quickActions.map(
                                                        (action) => (
                                                            <Combobox.Option
                                                                key={
                                                                    action.shortcut
                                                                }
                                                                value={action}
                                                                onClick={
                                                                    action.onClick
                                                                }
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        "flex cursor-pointer select-none items-center rounded-md px-3 py-2",
                                                                        active &&
                                                                            "bg-stone-100 "
                                                                    )
                                                                }
                                                            >
                                                                {({
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <div
                                                                            className={classNames(
                                                                                "h-6 w-6 flex-none",
                                                                                "text-stone-400 flex items-center justify-center"
                                                                            )}
                                                                            aria-hidden="true"
                                                                        >
                                                                            {
                                                                                action.icon
                                                                            }
                                                                        </div>
                                                                        <span className="ml-3 flex-auto truncate">
                                                                            {
                                                                                action.name
                                                                            }
                                                                        </span>
                                                                        <span
                                                                            className={classNames(
                                                                                "ml-3 flex-none text-xs font-semibold",
                                                                                active
                                                                                    ? "text-indigo-100"
                                                                                    : "text-stone-400"
                                                                            )}
                                                                        >
                                                                            <kbd
                                                                                className={
                                                                                    "-mr-1 inline-flex items-center rounded-lg px-2 py-0.5 font-sans text-xs font-semibold text-stone-500 bg-stone-200"
                                                                                }
                                                                            >
                                                                                ⌘
                                                                                {
                                                                                    action.shortcut
                                                                                }
                                                                            </kbd>
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        )
                                                    )}
                                                </ul>
                                            </li>
                                        )}
                                    </Combobox.Options>
                                )}

                                {query !== "" && filteredProjects.length === 0 && (
                                    <div className="py-14 px-6 text-center sm:px-14">
                                        <FolderIcon
                                            className="mx-auto h-6 w-6 text-stone-400"
                                            aria-hidden="true"
                                        />
                                        <p className="mt-4 text-sm text-stone-900">
                                            We couldn't find any projects with
                                            that term. Please try again.
                                        </p>
                                    </div>
                                )}
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
