import { BoxProps, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "src/theme";

type Props = BoxProps & {
    text: string;
};

const StyledSecondaryText = styled.p`
    color: ${colors.gray4};
    font-size: 14px !important;
    margin: 0;
    padding: 0.2rem 0;
`;

function SecondaryText({ text, ...props }: Props) {
    return (
        <Text
            color={colors.gray4}
            fontSize="14px !important"
            margin="0"
            padding="0.2rem 0"
            {...props}
        >
            {text}
        </Text>
    );
}

export default SecondaryText;
